import { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { Trash } from '@/shared/assets/svgs';
import { Form } from '@/shared/common/Form';
import type { ConfiguredForm } from '@/shared/common/Form/FormContainer';
import { Modal } from '@/shared/common/Modal';
import { tiledRadioLabel } from '@/shared/jsStyle/ui.css';
import { Button } from '@/shared/tempo/atom/Button';
import { EndEncounterType } from '@/shared/types/note.types';

import { useNoteEditorContext } from '../../../NoteEditorContext';
import { EditableNoteType, type TypeOfEncounter } from '../../../Notes.types';
import type { EndFormValues } from '../../noteFormState';
import { DeleteNoteInEndEarly } from './DeleteNoteInEndEarly';
import { deleteButton, deleteIcon } from './EndEarlyConfirmationDialog.css';
import { EndEarlyReasons } from './EndEarlyReasons';
import { NoShowReasons } from './NoShowReasons';
import { type EndEncounterFormFields } from './formConfig';

type Props = {
  form: ConfiguredForm<EndEncounterFormFields>;
  isOpen: boolean;
  onClose: () => void;
  onSave: (endEncounter: EndFormValues) => void;
  onResetAddNote: () => void;
  encounterType: Maybe<TypeOfEncounter>;
};

export function EndEarlyConfirmationDialog({
  form,
  isOpen,
  onClose,
  onSave,
  onResetAddNote,
  encounterType,
}: Props) {
  const intl = useIntl();
  const { editingNote } = useNoteEditorContext();

  const {
    noShow,
    endEarly,
    endEncounterType,
    endEarlyReason,
    noShowReason,
    endCallNotes,
  } = useSectionVisibility(form);
  const endEncounterReason = endEarlyReason || noShowReason;
  const [deleteForm, setDeleteForm] = useState(false);

  return (
    <Modal
      open={isOpen}
      onClose={() => {
        onClose();
        setDeleteForm(false);
      }}
    >
      {deleteForm ? (
        <DeleteNoteInEndEarly
          onClose={() => {
            onClose();
            setDeleteForm(false);
          }}
          type={
            editingNote?.type === EditableNoteType.Autosaved
              ? 'autosave'
              : 'draft'
          }
          onResetAddNote={onResetAddNote}
        />
      ) : (
        <Form form={form}>
          {({ canSubmit }) => (
            <>
              <Modal.Header
                title={<FormattedMessage defaultMessage="End Early" />}
              >
                <Button
                  id="end-early-clear-form"
                  className={deleteButton}
                  variant="tertiary"
                  onPress={() => setDeleteForm(true)}
                >
                  <Button.Icon>
                    <Trash className={deleteIcon} />
                  </Button.Icon>
                  <FormattedMessage defaultMessage="Delete" />
                </Button>
              </Modal.Header>
              <Modal.Body>
                <Form.RadioGroup
                  size={12}
                  name="endEncounterType"
                  label={intl.formatMessage({
                    defaultMessage: 'Did you speak with the patient?',
                  })}
                  orientation="horizontal"
                >
                  <Form.Radio
                    value={EndEncounterType.EndEarly}
                    classes={{ label: tiledRadioLabel.horizontal }}
                  >
                    <FormattedMessage defaultMessage="Yes" />
                  </Form.Radio>
                  <Form.Radio
                    value={EndEncounterType.NoShow}
                    classes={{ label: tiledRadioLabel.horizontal }}
                  >
                    <FormattedMessage defaultMessage="No" />
                  </Form.Radio>
                </Form.RadioGroup>
                {noShow && <NoShowReasons encounterType={encounterType} />}
                {endEarly && <EndEarlyReasons />}
              </Modal.Body>
              <Modal.Footer>
                <Button
                  id="end-early-cancel"
                  variant="secondary"
                  onPress={onClose}
                >
                  <FormattedMessage defaultMessage="Cancel" />
                </Button>
                <Button
                  id="end-early-submit"
                  variant="primary"
                  onPress={() => {
                    onSave({
                      endType: endEncounterType,
                      endReason: endEncounterReason,
                      endNote: endCallNotes,
                    });
                    onClose();
                  }}
                  isDisabled={!canSubmit}
                >
                  <FormattedMessage defaultMessage="Save" />
                </Button>
              </Modal.Footer>
            </>
          )}
        </Form>
      )}
    </Modal>
  );
}

function useSectionVisibility(form: ConfiguredForm<EndEncounterFormFields>) {
  const [endEarlyReason, noShowReason] = form.getValues([
    'endEarlyReason',
    'noShowReason',
  ]);

  const [endEncounterType, endCallNotes] = form.watch([
    'endEncounterType',
    'endCallNotes',
  ]);

  return {
    noShow: endEncounterType === EndEncounterType.NoShow,
    endEarly: endEncounterType === EndEncounterType.EndEarly,
    endEncounterType,
    endEarlyReason,
    noShowReason,
    endCallNotes,
  };
}
