import { useObjectRef } from '@react-aria/utils';
import cx from 'classnames';
import type { ReactNode } from 'react';
import { forwardRef } from 'react';
import type { AriaButtonProps } from 'react-aria';
import { FocusRing, useButton } from 'react-aria';
import type { SelectState } from 'react-stately';

import Chevron from '@/shared/assets/svgs/chevron.svg?react';
import { Button } from '@/shared/tempo/atom/Button';
import { input } from '@/shared/tempo/atom/Input/Input.css';
import { focusRingCss } from '@/shared/tempo/util/FocusRing/FocusRing.css';

import { chevron, selectButton } from './SelectButton.css';

type Props = {
  className?: string;
  children: ReactNode;
  hasError?: boolean;
  state: SelectState<unknown>;
  variant?: 'prominent' | 'subtle';
  classes?: {
    chevron?: string;
  };
} & AriaButtonProps<'button'>;

export const SelectButton = forwardRef<HTMLButtonElement, Props>(
  (
    {
      className,
      children,
      hasError,
      state,
      variant = 'prominent',
      classes,
      ...props
    }: Props,
    forwardedRef,
  ) => {
    const ref = useObjectRef(forwardedRef);
    const { buttonProps } = useButton(props, ref);

    return (
      <FocusRing
        focusRingClass={cx(
          hasError ? focusRingCss.keyboardWithError : focusRingCss.keyboard,
        )}
      >
        <button
          type="button"
          {...buttonProps}
          // required aria props are provided by react-aria and spread into
          // the component on the lines just above
          // eslint-disable-next-line jsx-a11y/role-has-required-aria-props
          role="combobox"
          ref={ref}
          className={cx(
            {
              // Style the button as though it is an input
              [input.default]: !hasError,
              [input.error]: hasError,
            },
            selectButton[variant],
            className,
          )}
        >
          {children}
          <Button.Icon>
            <Chevron
              className={cx(
                {
                  [chevron.open]: state.isOpen,
                  [chevron.default]: !state.isOpen,
                },
                classes?.chevron,
              )}
            />
          </Button.Icon>
        </button>
      </FocusRing>
    );
  },
);
