/* eslint-disable */
// @ts-nocheck
/*
* This file is a generated Typescript file for GRPC Gateway, DO NOT MODIFY
*/

import * as GoogleProtobufField_mask from "./google/protobuf/field_mask.pb"
import * as GoogleProtobufTimestamp from "./google/protobuf/timestamp.pb"
import * as GoogleProtobufWrappers from "./google/protobuf/wrappers.pb"
import * as GoPmsPkgPatientPms from "./patient/pms.pb"
import * as fm from "./fetch.pb"

export enum AppointmentState {
  STATE_UNSPECIFIED = "STATE_UNSPECIFIED",
  PENDING = "PENDING",
  RESCHEDULE_REQUESTED = "RESCHEDULE_REQUESTED",
  CANCELLED = "CANCELLED",
  COMPLETED = "COMPLETED",
  CONFIRMED = "CONFIRMED",
  RESCHEDULED = "RESCHEDULED",
}

export enum AppointmentAcuityLevel {
  ACUITY_LEVEL_UNSPECIFIED = "ACUITY_LEVEL_UNSPECIFIED",
  LEVEL_1 = "LEVEL_1",
  LEVEL_2 = "LEVEL_2",
  LEVEL_3 = "LEVEL_3",
  LEVEL_4 = "LEVEL_4",
  LEVEL_5 = "LEVEL_5",
  NOT_CALCULATED = "NOT_CALCULATED",
  NOT_NEEDED = "NOT_NEEDED",
  CHF_STABLE = "CHF_STABLE",
  CHF_NOT_STABLE = "CHF_NOT_STABLE",
}

export enum RescheduleAppointmentRequestType {
  TYPE_UNSPECIFIED = "TYPE_UNSPECIFIED",
  MANUAL = "MANUAL",
  SAME_SLOT_MANUAL = "SAME_SLOT_MANUAL",
  SAME_SLOT_AUTOMATIC = "SAME_SLOT_AUTOMATIC",
}

export enum RescheduleAppointmentRequestSameSlotFailureMode {
  SAME_SLOT_FAILURE_MODE_UNSPECIFIED = "SAME_SLOT_FAILURE_MODE_UNSPECIFIED",
  MARK_TO_RESCHEDULE = "MARK_TO_RESCHEDULE",
  KEEP_EXISTING = "KEEP_EXISTING",
}

export type GetNextAppointmentRequest = {
  parent?: string
}

export type GetNextAppointmentResponse = {
  nextAppointment?: AppointmentDetails
}

export type RecommendNextAppointmentRequest = {
  parent?: string
}

export type RecommendNextCHFAppointmentRequest = {
  parent?: string
}

export type NextAppointmentRecommendation = {
  startDate?: GoogleProtobufTimestamp.Timestamp
  appointmentTypeAcuityId?: GoogleProtobufWrappers.Int32Value
  appointmentTypeName?: GoogleProtobufWrappers.StringValue
  sourceAppointmentId?: string
  sourceAppointmentTypeName?: string
  careProviderId?: string
  careProviderFirstName?: string
  careProviderLastName?: string
  careProviderRole?: string
  careProviderAcuityCalendarId?: string
}

export type AppointmentDetails = {
  name?: string
  startTime?: GoogleProtobufTimestamp.Timestamp
  endTime?: GoogleProtobufTimestamp.Timestamp
  state?: AppointmentState
  careProviderId?: string
  careProviderFirstName?: string
  careProviderLastName?: string
  careProviderRole?: string
  careProviderEmail?: string
  patientId?: string
  patientTimezone?: string
  phoneNumber?: GoogleProtobufWrappers.StringValue
  patientFirstName?: string
  appointmentCategory?: string
  appointmentCanonicalName?: string
  noteId?: GoogleProtobufWrappers.Int32Value
  noteStatus?: GoPmsPkgPatientPms.NoteStatus
  patientNoShow?: GoogleProtobufWrappers.BoolValue
  duration?: number
  hasCalls?: boolean
  noShowAttemptNotes?: NoShowAttemptNote[]
  patientLastName?: string
  appointmentTypeAcuityId?: number
  appointmentAcuityId?: number
}

export type NoShowAttemptNote = {
  noteId?: number
  noteStatus?: GoPmsPkgPatientPms.NoteStatus
  updatedAt?: GoogleProtobufTimestamp.Timestamp
  patientNoShow?: GoogleProtobufWrappers.BoolValue
}

export type Appointment = {
  name?: string
  startTime?: GoogleProtobufTimestamp.Timestamp
  endTime?: GoogleProtobufTimestamp.Timestamp
  createTime?: GoogleProtobufTimestamp.Timestamp
  updateTime?: GoogleProtobufTimestamp.Timestamp
  type?: string
  careProvider?: string
  patient?: string
  state?: AppointmentState
  appointmentType?: string
  careProviderEmail?: string
  appointmentCategory?: GoogleProtobufWrappers.StringValue
  acuityLevel?: AppointmentAcuityLevel
  patientJourneyVersion?: GoogleProtobufWrappers.StringValue
  recommendedNextApptTypeId?: GoogleProtobufWrappers.StringValue
  recommendedNextStartDate?: GoogleProtobufTimestamp.Timestamp
  duration?: number
}

export type ListAllAppointmentsRequest = {
  filter?: string
  pageSize?: number
  pageToken?: string
  readMask?: GoogleProtobufField_mask.FieldMask
  orderBy?: string
}

export type ListAllAppointmentsResponse = {
  appointments?: AppointmentDetails[]
  nextPageToken?: string
  totalSize?: number
}

export type ListNextUpcomingAppointmentsRequest = {
  filter?: string
  pageSize?: number
  pageToken?: string
  readMask?: GoogleProtobufField_mask.FieldMask
  orderBy?: string
}

export type ListNextUpcomingAppointmentsResponse = {
  appointments?: AppointmentDetails[]
  nextPageToken?: string
  totalSize?: number
}

export type ListNextUpcomingProspectivePatientsAppointmentsRequest = {
  filter?: string
  pageSize?: number
  pageToken?: string
  readMask?: GoogleProtobufField_mask.FieldMask
  orderBy?: string
}

export type ListNextUpcomingProspectivePatientsAppointmentsResponse = {
  appointments?: AppointmentDetails[]
  nextPageToken?: string
  totalSize?: number
}

export type ListPatientAppointmentsRequest = {
  parent?: string
  filter?: string
  pageSize?: number
  pageToken?: string
  readMask?: GoogleProtobufField_mask.FieldMask
  orderBy?: string
}

export type ListPatientAppointmentsResponse = {
  appointments?: Appointment[]
  nextPageToken?: string
  totalSize?: number
}

export type CompletePatientEnrollmentAppointmentsRequest = {
  parent?: string
}

export type CompletePatientEnrollmentAppointmentsResponse = {
  appointmentIds?: string[]
}

export type CreateAppointmentRequest = {
  parent?: string
  appointment?: Appointment
}

export type GetAppointmentRequest = {
  name?: string
  readMask?: GoogleProtobufField_mask.FieldMask
}

export type ConfirmAppointmentRequest = {
  name?: string
}

export type RescheduleAppointmentRequest = {
  name?: string
  type?: RescheduleAppointmentRequestType
  acuityCalendarId?: number
  acuityAppointmentId?: number
  apptDate?: GoogleProtobufTimestamp.Timestamp
  sameSlotFailureMode?: RescheduleAppointmentRequestSameSlotFailureMode
}

export type CancelAppointmentRequest = {
  name?: string
}

export type AssignableCareProvider = {
  npProviderId?: string
  npFirstName?: string
  npLastName?: string
  npRole?: string
  npAcuityCalendarId?: string
  cnProviderId?: string
  cnFirstName?: string
  cnLastName?: string
  cnRole?: string
  cnAcuityCalendarId?: string
}

export type AppointmentType = {
  name?: string
  acuityId?: number
}

export type FindPatientAppointmentTypesRequest = {
  parent?: string
}

export type FindPatientAppointmentTypesResponse = {
  appointmentTypes?: AppointmentType[]
}

export type AppointmentAvailability = {
  datetime?: GoogleProtobufTimestamp.Timestamp
  acuityAppointmentTypeId?: number
  acuityCalendarId?: number
  timezone?: string
}

export type CheckPatientAppointmentAvailabilityRequest = {
  parent?: string
  acuityAppointmentTypeId?: number
  apptDate?: GoogleProtobufTimestamp.Timestamp
  recommendedApptDate?: GoogleProtobufTimestamp.Timestamp
  appointmentId?: string
}

export type CareProvider = {
  careProviderId?: string
  careProviderFirstName?: string
  careProviderLastName?: string
  careProviderRole?: string
  acuityCalendarId?: number
}

export type CheckPatientAppointmentAvailabilityResponse = {
  availabilities?: AppointmentAvailability[]
  careProvider?: CareProvider
}

export type CheckSameSlotAppointmentAvailabilityRequest = {
  name?: string
}

export type CheckSameSlotAppointmentAvailabilityResponse = {
  availableCareProviders?: CareProvider[]
}

export class SchedulingService {
  static ListAllAppointments(req: ListAllAppointmentsRequest, initReq?: fm.InitReq): Promise<ListAllAppointmentsResponse> {
    return fm.fetchReq<ListAllAppointmentsRequest, ListAllAppointmentsResponse>(`/scheduling/api/v1/scheduling/appointments:all?${fm.renderURLSearchParams(req, [])}`, {...initReq, method: "GET"})
  }
  static ListNextUpcomingAppointments(req: ListNextUpcomingAppointmentsRequest, initReq?: fm.InitReq): Promise<ListNextUpcomingAppointmentsResponse> {
    return fm.fetchReq<ListNextUpcomingAppointmentsRequest, ListNextUpcomingAppointmentsResponse>(`/scheduling/api/v1/scheduling/appointments:nextupcoming?${fm.renderURLSearchParams(req, [])}`, {...initReq, method: "GET"})
  }
  static ListNextUpcomingProspectivePatientsAppointments(req: ListNextUpcomingProspectivePatientsAppointmentsRequest, initReq?: fm.InitReq): Promise<ListNextUpcomingProspectivePatientsAppointmentsResponse> {
    return fm.fetchReq<ListNextUpcomingProspectivePatientsAppointmentsRequest, ListNextUpcomingProspectivePatientsAppointmentsResponse>(`/scheduling/api/v1/scheduling/appointments/prospective_patients:nextupcoming?${fm.renderURLSearchParams(req, [])}`, {...initReq, method: "GET"})
  }
  static ListPatientAppointments(req: ListPatientAppointmentsRequest, initReq?: fm.InitReq): Promise<ListPatientAppointmentsResponse> {
    return fm.fetchReq<ListPatientAppointmentsRequest, ListPatientAppointmentsResponse>(`/scheduling/api/v1/scheduling/patients/${req["parent"]}/appointments?${fm.renderURLSearchParams(req, ["parent"])}`, {...initReq, method: "GET"})
  }
  static CompletePatientEnrollmentAppointments(req: CompletePatientEnrollmentAppointmentsRequest, initReq?: fm.InitReq): Promise<CompletePatientEnrollmentAppointmentsResponse> {
    return fm.fetchReq<CompletePatientEnrollmentAppointmentsRequest, CompletePatientEnrollmentAppointmentsResponse>(`/scheduling/api/v1/scheduling/patients/${req["parent"]}/appointments:completePatientEnrollment`, {...initReq, method: "POST"})
  }
  static GetAppointment(req: GetAppointmentRequest, initReq?: fm.InitReq): Promise<Appointment> {
    return fm.fetchReq<GetAppointmentRequest, Appointment>(`/scheduling/api/v1/scheduling/${req["name"]}?${fm.renderURLSearchParams(req, ["name"])}`, {...initReq, method: "GET"})
  }
  static CreateAppointment(req: CreateAppointmentRequest, initReq?: fm.InitReq): Promise<Appointment> {
    return fm.fetchReq<CreateAppointmentRequest, Appointment>(`/scheduling/api/v1/scheduling/patients/${req["parent"]}/appointments`, {...initReq, method: "POST", body: JSON.stringify(req["appointment"], fm.replacer)})
  }
  static ConfirmAppointment(req: ConfirmAppointmentRequest, initReq?: fm.InitReq): Promise<AppointmentDetails> {
    return fm.fetchReq<ConfirmAppointmentRequest, AppointmentDetails>(`/scheduling/api/v1/scheduling/appointments/${req["name"]}:confirm`, {...initReq, method: "POST", body: JSON.stringify(req, fm.replacer)})
  }
  static RescheduleAppointment(req: RescheduleAppointmentRequest, initReq?: fm.InitReq): Promise<AppointmentDetails> {
    return fm.fetchReq<RescheduleAppointmentRequest, AppointmentDetails>(`/scheduling/api/v1/scheduling/appointments/${req["name"]}:reschedule`, {...initReq, method: "POST", body: JSON.stringify(req, fm.replacer)})
  }
  static CancelAppointment(req: CancelAppointmentRequest, initReq?: fm.InitReq): Promise<AppointmentDetails> {
    return fm.fetchReq<CancelAppointmentRequest, AppointmentDetails>(`/scheduling/api/v1/scheduling/appointments/${req["name"]}:cancel`, {...initReq, method: "POST", body: JSON.stringify(req, fm.replacer)})
  }
  static RecommendNextAppointment(req: RecommendNextAppointmentRequest, initReq?: fm.InitReq): Promise<NextAppointmentRecommendation> {
    return fm.fetchReq<RecommendNextAppointmentRequest, NextAppointmentRecommendation>(`/scheduling/api/v1/scheduling/patients/${req["parent"]}:recommendation?${fm.renderURLSearchParams(req, ["parent"])}`, {...initReq, method: "GET"})
  }
  static RecommendNextCHFAppointment(req: RecommendNextCHFAppointmentRequest, initReq?: fm.InitReq): Promise<NextAppointmentRecommendation> {
    return fm.fetchReq<RecommendNextCHFAppointmentRequest, NextAppointmentRecommendation>(`/scheduling/api/v1/scheduling/patients/${req["parent"]}:chfRecommendation`, {...initReq, method: "POST"})
  }
  static GetNextAppointment(req: GetNextAppointmentRequest, initReq?: fm.InitReq): Promise<GetNextAppointmentResponse> {
    return fm.fetchReq<GetNextAppointmentRequest, GetNextAppointmentResponse>(`/scheduling/api/v1/scheduling/patients/${req["parent"]}:nextAppointment?${fm.renderURLSearchParams(req, ["parent"])}`, {...initReq, method: "GET"})
  }
  static FindPatientAppointmentTypes(req: FindPatientAppointmentTypesRequest, initReq?: fm.InitReq): Promise<FindPatientAppointmentTypesResponse> {
    return fm.fetchReq<FindPatientAppointmentTypesRequest, FindPatientAppointmentTypesResponse>(`/scheduling/api/v1/scheduling/patients/${req["parent"]}:appointmentTypes?${fm.renderURLSearchParams(req, ["parent"])}`, {...initReq, method: "GET"})
  }
  static CheckPatientAppointmentAvailability(req: CheckPatientAppointmentAvailabilityRequest, initReq?: fm.InitReq): Promise<CheckPatientAppointmentAvailabilityResponse> {
    return fm.fetchReq<CheckPatientAppointmentAvailabilityRequest, CheckPatientAppointmentAvailabilityResponse>(`/scheduling/api/v1/scheduling/patients/${req["parent"]}:appointmentAvailability`, {...initReq, method: "POST", body: JSON.stringify(req, fm.replacer)})
  }
  static CheckSameSlotAppointmentAvailability(req: CheckSameSlotAppointmentAvailabilityRequest, initReq?: fm.InitReq): Promise<CheckSameSlotAppointmentAvailabilityResponse> {
    return fm.fetchReq<CheckSameSlotAppointmentAvailabilityRequest, CheckSameSlotAppointmentAvailabilityResponse>(`/scheduling/api/v1/scheduling/patients/${req["name"]}:sameSlotAppointmentAvailability`, {...initReq, method: "POST", body: JSON.stringify(req, fm.replacer)})
  }
}