import { useIntl } from 'react-intl';

import { useNotTitratingReasons } from '@/components/AsyncTitration/hooks/useNotTitratingReasons';
import { AsyncTitrationMedicationWasNotTitratedReason } from '@/shared/generated/grpcGateway/medication.pb';

enum ClinicalGoalSpecificReason {
  PATIENT_REJECTED = 'PATIENT_REJECTED',
  MAX_TOLERATED_DOSE_REACHED = 'MAX_TOLERATED_DOSE_REACHED',
}

export type TReasonForNotTitrating = keyof typeof ReasonForNotTitrating;

export const ReasonForNotTitrating = {
  ...ClinicalGoalSpecificReason,
  ...AsyncTitrationMedicationWasNotTitratedReason,
};

export function useReasonForNotTitratingI18n(): Record<
  TReasonForNotTitrating,
  string
> {
  const intl = useIntl();
  const reasonOptions = useNotTitratingReasons();
  const asyncTitrationReasons = reasonOptions.reduce(
    (acc, option) => ({
      [option.key]: option.label,
      ...acc,
    }),
    {} as Record<AsyncTitrationMedicationWasNotTitratedReason, string>,
  );

  return {
    ...asyncTitrationReasons,
    [ClinicalGoalSpecificReason.PATIENT_REJECTED]: intl.formatMessage({
      defaultMessage: 'Patient rejected',
    }),
    [ClinicalGoalSpecificReason.MAX_TOLERATED_DOSE_REACHED]: intl.formatMessage(
      { defaultMessage: 'Maximally tolerated dose reached ' },
    ),
  };
}
