**Cadence Encounter Type:**

Clinical visit

{{ chfVisitIntro data }}

{{#if (isChfVisitPatientNoShow data) }}
{{ chfVisitPatientNoShow data intl }}
{{else}}
### HPI
{{> patient_summary }}

#### Recent Vital Trends:
{{> vitals_list }}

{{ chfVisitActionPlan data intl }}

{{ chfVisitSymptoms data rpmConditions intl }}

{{ chfVisitEmergencyVisits data intl }}

{{ chfVisitProductSupport data vitalsEngagement }}

{{ chfVisitMedicationReview data noteId patientMedications }}

{{ chfVisitGeneralAssessment data }}

{{ chfVisitEarlyEndCall data intl }}
{{/if}}
