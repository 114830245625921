import { FormattedMessage } from 'react-intl';

import { Form } from '@/shared/common/Form';
import { tiledRadioLabel } from '@/shared/jsStyle/ui.css';

import { HfType } from '../../../../Notes.types';
import { Goal } from './Goal';

export function HfTypeRadio() {
  return (
    <Form.RadioGroup
      size={12}
      name="hfType"
      label={
        <FormattedMessage defaultMessage="Does the patient have HFrEF (reduced) or HFpEF (preserved) *" />
      }
      orientation="horizontal"
    >
      <Form.Radio
        value={HfType.Hfref}
        classes={{ label: tiledRadioLabel.horizontal }}
      >
        <FormattedMessage defaultMessage="HFrEF (reduced)" />
      </Form.Radio>
      <Form.Radio
        value={HfType.Hfpef}
        classes={{ label: tiledRadioLabel.horizontal }}
      >
        <FormattedMessage defaultMessage="HFpEF (preserved)" />
      </Form.Radio>
    </Form.RadioGroup>
  );
}

type GdmtGoalProps = {
  label: string;
  isGdmtGoalMet?: boolean;
};

export function GdmtGoal({ label, isGdmtGoalMet }: GdmtGoalProps) {
  return (
    <Goal
      isGoalMetFieldName="isGdmtGoalMet"
      goalDetailsFieldName="gdmtGoalDetails"
      isGoalMetFieldValue={isGdmtGoalMet}
      description={label}
    />
  );
}
