import cx from 'classnames';
import type { ReactChild, ReactElement } from 'react';

import { Tooltip } from '@/deprecated/mui';
import {
  customArrow,
  customTooltip,
} from '@/shared/common/Tooltip/Tooltip.css';

import { commonInfoLabel, commonInfoText } from './CommonInfoField.css';

type Props = {
  label?: ReactElement;
  text: ReactChild;
  toolTipMsg?: ReactChild;
  className?: string;
};

export const PLACEHOLDER = '-';

export function CommonInfoField({ label, text, toolTipMsg, className }: Props) {
  const content = (
    <span>
      {label ? (
        <>
          <span className={commonInfoLabel}>{label}</span>:&nbsp;
          <span className={cx(commonInfoText, className)}>{text}</span>
        </>
      ) : (
        <span className={cx(commonInfoText, className)}>{text}</span>
      )}
    </span>
  );

  return toolTipMsg ? (
    <Tooltip
      classes={{
        tooltip: customTooltip,
        arrow: customArrow,
      }}
      arrow
      title={toolTipMsg}
    >
      {content}
    </Tooltip>
  ) : (
    content
  );
}
