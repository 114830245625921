import {
  EncounterModuleId,
  TypeOfEncounter,
} from '@/pages/patients/patientDetails/ui/Notes/Notes.types';
import { ConditionProgram } from '@/shared/types/condition.types';

import { draftJsFieldTemplate } from '../../../../template.utils';
import carePlanNoteBody from './care_plan_note_body.hbs';

const MODULES = {
  [EncounterModuleId.CarePlan]: {
    care_plan_body: draftJsFieldTemplate(carePlanNoteBody),
  },
};

export const apcmCarePlanVisit = {
  [TypeOfEncounter.APCM_CARE_PLAN]: {
    [ConditionProgram.APCM]: { ...MODULES },
  },
};
