import { useEffect } from 'react';

import { logger } from '@/logger';

type UsePropGuardReturn<T> =
  | { isValid: true; value: T }
  | { isValid: false; value?: never };

export function usePropGuard<T>(
  prop: Maybe<T>,
  name: string,
): UsePropGuardReturn<T> {
  const hasProp = prop !== undefined && prop !== null;

  useEffect(() => {
    if (!hasProp) {
      logger.error(`usePropGuard: Missing value for "${name}"`, new Error(), {
        prop,
        name,
      });
    }
  }, [prop, hasProp, name]);

  if (!hasProp) {
    return { isValid: false };
  }

  return { isValid: true, value: prop as T };
}
