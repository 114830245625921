import { useState } from 'react';

import { SuggestedTitration } from '@/components/AsyncTitration/SuggestedTitration';
import {
  useShouldDisplayAsyncTitration,
  useShouldDisplayReadOnlySuggestedTitration,
  useTitrationRecommendation,
} from '@/components/AsyncTitration/hooks';
import {
  type Medication,
  type MedicationChange,
} from '@/shared/generated/grpcGateway/medication.pb';

import type { TypeOfEncounter } from '../patientDetails/ui/Notes/Notes.types';
import { MedicationRow } from './MedicationRow';
import { medRow, medRowTitle } from './MedicationRow.css';
import type { MedPermissions } from './PatientMedicationsList';
import { FormType } from './forms/formTypeEnum';
import { lastStructuredChange } from './utils/medChangeUtils';

type Props = {
  med: Medication;
  medPermissions?: MedPermissions;
  existingReferencedMedIds?: Maybe<string>[];
  withBorder?: boolean;
  typeOfEncounter?: TypeOfEncounter;
  noteId?: Maybe<number>;
};

export function ReferencedMedicationRow({
  med,
  medPermissions,
  existingReferencedMedIds,
  withBorder = true,
  typeOfEncounter,
  noteId,
}: Props) {
  const [openFormType, setOpenFormType] = useState<Nullable<FormType>>(null);
  const [proposedChange, setProposedChange] = useState<
    MedicationChange | undefined
  >(undefined);

  // Referenced meds are different from unreferencedMedications in that each med has a list of med changes, and we should only display the last updated med change
  const latestMedChange = lastStructuredChange(med);
  const titrationRecommendation = useTitrationRecommendation(
    latestMedChange,
    med.referenceMedicationId as string,
  );
  const shouldDisplayAsyncTitration = useShouldDisplayAsyncTitration(
    titrationRecommendation,
    medPermissions,
    noteId,
    typeOfEncounter,
  );
  const shouldDisplayReadOnlySuggestedTitration =
    useShouldDisplayReadOnlySuggestedTitration(medPermissions, typeOfEncounter);

  if (!latestMedChange) {
    return (
      <div className={medRow}>
        <div className={medRowTitle}>{med.referenceMedicationName}</div>
      </div>
    );
  }

  const onFormTypeChange = (type: Nullable<FormType>) => {
    if (type === null) {
      setProposedChange(undefined);
    }

    setOpenFormType(type);
  };

  const medRowComponent = (
    <MedicationRow
      medChange={latestMedChange}
      proposedChange={proposedChange}
      medication={med}
      medPermissions={medPermissions}
      existingReferencedMedIds={existingReferencedMedIds}
      withBorder={!shouldDisplayAsyncTitration.shouldDisplay && withBorder}
      openFormType={openFormType}
      onFormTypeChange={onFormTypeChange}
      titrationRecommendation={titrationRecommendation}
    />
  );

  if (shouldDisplayAsyncTitration.shouldDisplay) {
    return (
      <SuggestedTitration
        titrationRecommendation={titrationRecommendation}
        noteId={noteId}
        readOnly={shouldDisplayAsyncTitration.readOnly ?? true}
        onEdit={(proposal) => {
          setProposedChange(proposal);
          onFormTypeChange(FormType.ManageRx);
        }}
        onCancelEdit={() => {
          onFormTypeChange(null);
        }}
        isEditing={openFormType !== null}
        referenceMedicationId={med.referenceMedicationId}
        typeOfEncounter={typeOfEncounter}
        currentMed={latestMedChange}
      >
        {medRowComponent}
      </SuggestedTitration>
    );
  }

  if (shouldDisplayReadOnlySuggestedTitration) {
    return (
      <SuggestedTitration
        titrationRecommendation={titrationRecommendation}
        currentMed={latestMedChange}
        readOnly
      >
        {medRowComponent}
      </SuggestedTitration>
    );
  }

  return medRowComponent;
}
