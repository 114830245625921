import cx from 'classnames';
import { FormattedMessage } from 'react-intl';

import CheckIcon from '@/shared/assets/svgs/check.svg?react';
import ZendeskIcon from '@/shared/assets/svgs/zendesk.svg?react';
import { useFlags } from '@/shared/hooks';
import { flexContainer } from '@/shared/jsStyle/flex.css';
import { gap } from '@/shared/jsStyle/gap.css';
import { Button } from '@/shared/tempo/atom/Button';
import { Tag } from '@/shared/tempo/atom/Tag';
import { Tooltip } from '@/shared/tempo/atom/Tooltip';

import { ParentView } from '../TaskList';
import { useTaskListContext } from '../TaskList/taskListContext';
import type { Task } from '../types';
import { isScheduledTask } from '../utils';
import {
  EditAction,
  PatientProfileButton,
  ResolvedActions,
  UnassignedActions,
} from './AdditionalActions';
import { CardHoverAction } from './CardHoverAction';
import {
  detailHeaderRow,
  headerRow,
  resolvedTaskType,
  zendeskIndicatorContainer,
} from './TaskCard.css';
import { UrgentIndicator } from './indicators';
import { ResolvedIndicator } from './indicators/ResolvedIndicator';
import { ScheduledIndicator } from './indicators/ScheduledIndicator';
import {
  isResolved,
  isUnassigned,
  isUrgent,
  isZendeskTask,
  useCanAssignTaskType,
} from './utils';

type Props = {
  task: Task;
  onResolve: () => void;
  onReopen: () => void;
  onSelfAssignTask?: () => void;
};

export function TaskHeader({
  task,
  onResolve,
  onReopen,
  onSelfAssignTask,
}: Props) {
  const { enableScheduledTasks } = useFlags();
  const canAssignTask = useCanAssignTaskType(task);
  const isZd = isZendeskTask(task);
  const hoverActionSpacing = cx(flexContainer.row, gap.S);
  const { parentView } = useTaskListContext();
  const isProfilePage = parentView === ParentView.PatientProfile;

  return (
    <div className={detailHeaderRow}>
      <span className={headerRow.left}>
        <Tag
          variant="default"
          className={cx({ [resolvedTaskType]: isResolved })}
        >
          {task.taskType.typeName}
        </Tag>
        {isUrgent(task) && <UrgentIndicator resolved={isResolved(task)} />}
        {enableScheduledTasks && isScheduledTask(task) && (
          <ScheduledIndicator scheduledTime={task.scheduledTime} />
        )}
      </span>
      <div className={headerRow.right}>
        {!isResolved(task) && !isUnassigned(task) && (
          <>
            <CardHoverAction className={hoverActionSpacing}>
              {({ isDisabled }) => (
                <>
                  <EditAction task={task} isDisabled={isDisabled} />
                  {!isProfilePage && <PatientProfileButton task={task} />}
                  <Tooltip
                    isDisabled={isDisabled}
                    placement="bottom"
                    content={<FormattedMessage defaultMessage="Resolve Task" />}
                  >
                    <Button
                      variant="tertiary"
                      size="small"
                      onPress={onResolve}
                      isDisabled={isDisabled}
                    >
                      <Button.Icon>
                        <CheckIcon />
                      </Button.Icon>
                    </Button>
                  </Tooltip>
                </>
              )}
            </CardHoverAction>
          </>
        )}
        {isResolved(task) && !isZd && (
          <>
            <CardHoverAction className={hoverActionSpacing}>
              {({ isDisabled }) => (
                <>
                  <ResolvedActions
                    onReopen={onReopen}
                    isDisabled={isDisabled}
                  />
                  {!isProfilePage && <PatientProfileButton task={task} />}
                </>
              )}
            </CardHoverAction>
            <ResolvedIndicator />
          </>
        )}
        {isZd && (
          <div className={zendeskIndicatorContainer}>
            <ZendeskIcon />
          </div>
        )}
        {!isResolved(task) && isUnassigned(task) && canAssignTask && (
          <CardHoverAction className={hoverActionSpacing}>
            {({ isDisabled }) => (
              <>
                <EditAction task={task} isDisabled={isDisabled} />
                {!isProfilePage && <PatientProfileButton task={task} />}
                <UnassignedActions
                  task={task}
                  isDisabled={isDisabled}
                  onSelfAssignTask={onSelfAssignTask}
                />
              </>
            )}
          </CardHoverAction>
        )}
      </div>
    </div>
  );
}
