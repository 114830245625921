import type { IntlShape } from 'react-intl';
import { useIntl } from 'react-intl';

import { AsyncTitrationMedicationWasNotTitratedReason as Reason } from '@/shared/generated/grpcGateway/medication.pb';

export function useRejectionReasonI18n(): Record<Reason, string> {
  const intl = useIntl();
  return getRejectionReasonI18n(intl);
}

export function getRejectionReasonI18n(
  intl: IntlShape,
): Record<Reason, string> {
  return {
    [Reason.PROVIDER_PREFERENCE]: intl.formatMessage({
      defaultMessage: 'Provider Preference',
    }),
    [Reason.PATIENT_PREFERENCES]: intl.formatMessage({
      defaultMessage: 'Patient preference',
    }),
    [Reason.MEDICAL_REASON]: intl.formatMessage({
      defaultMessage: 'Medical reason',
    }),
    [Reason.FINANCIAL_REASON]: intl.formatMessage({
      defaultMessage: 'Financial reason',
    }),
    [Reason.NEW_PRESCRIPTION_REQUIRED]: intl.formatMessage({
      defaultMessage: 'New prescription required',
    }),
    [Reason.MEDICATION_NOT_ACCURATE]: intl.formatMessage({
      defaultMessage: 'Medication not accurate',
    }),
    [Reason.MEDICATION_WAS_NOT_TITRATED_REASON_UNSPECIFIED]: intl.formatMessage(
      { defaultMessage: 'Unspecified' },
    ),
    [Reason.AGE_OR_CONDITION]: intl.formatMessage({
      defaultMessage: 'Patient condition (e.g., current symptoms)',
    }),
    [Reason.CALCIUM_TOO_HIGH]: intl.formatMessage({
      defaultMessage: 'Calcium too high',
    }),
    [Reason.CALCIUM_TOO_LOW]: intl.formatMessage({
      defaultMessage: 'Calcium too low',
    }),
    [Reason.HEART_RATE_TOO_LOW]: intl.formatMessage({
      defaultMessage: 'Heart rate too low',
    }),
    [Reason.KIDNEY_FUNCTION_TOO_LOW]: intl.formatMessage({
      defaultMessage: 'Kidney function too low',
    }),
    [Reason.MANAGED_BY_EXTERNAL_PROVIDER]: intl.formatMessage({
      defaultMessage: 'Managed by external provider',
    }),
    [Reason.POTASSIUM_TOO_LOW]: intl.formatMessage({
      defaultMessage: 'Potassium too low',
    }),
    [Reason.POTASSIUM_TOO_HIGH]: intl.formatMessage({
      defaultMessage: 'Potassium too high',
    }),
    [Reason.RECENT_MEDICATION_CHANGE_BY_EXTERNAL_PROVIDER]: intl.formatMessage({
      defaultMessage: 'Recent medication change made by external provider',
    }),
    [Reason.SIDE_EFFECT_AT_HIGHER_DOSE]: intl.formatMessage({
      defaultMessage: 'Side effect at higher dose',
    }),
    [Reason.SODIUM_TOO_LOW]: intl.formatMessage({
      defaultMessage: 'Sodium too low',
    }),
    [Reason.ADJUSTED_ANOTHER_MEDICATION]: intl.formatMessage({
      defaultMessage: 'Adjusted another medication',
    }),
    [Reason.ELECTROLYTE_ABNORMALITY]: intl.formatMessage({
      defaultMessage: 'Electrolyte abnormality',
    }),
    [Reason.NEED_LABS]: intl.formatMessage({
      defaultMessage: 'Need labs before additional titration',
    }),
    [Reason.ACUTE_MEDICAL_ISSUE]: intl.formatMessage({
      defaultMessage: 'Acute medical issue/hospitalization',
    }),
    [Reason.PROVIDER_GOAL_DIFFERS]: intl.formatMessage({
      defaultMessage: 'Provider goal > 140/90',
    }),
    [Reason.MEDICATION_VERIFICATION]: intl.formatMessage({
      defaultMessage: 'Medication verification',
    }),
    [Reason.HYPOGLYCEMIC_EPISODES]: intl.formatMessage({
      defaultMessage: 'Hypoglycemic episodes',
    }),
    [Reason.HYPOTENSION]: intl.formatMessage({ defaultMessage: 'Hypotension' }),
    [Reason.LABILE_BLOOD_GLUCOSE]: intl.formatMessage({
      defaultMessage: 'Labile blood glucose',
    }),
    [Reason.ABNORMAL_LABS]: intl.formatMessage({
      defaultMessage: 'Abnormal labs',
    }),
  };
}
