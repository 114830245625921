import type { IntlShape } from 'react-intl';

import { getTitrationRecommendation } from '@/components/AsyncTitration/hooks';
import { MarkdownBuilder } from '@/pages/patients/PatientProfile/CNNotesSidebarPanel/templates/engine/MarkdownBuilder';
import type {
  MedicationChange,
  AsyncTitrationMedicationWasNotTitratedReason as Reason,
  ReferenceMedication,
} from '@/shared/generated/grpcGateway/medication.pb';
import { AsyncTitrationAsyncTitrationStatus as AsyncTitrationStatus } from '@/shared/generated/grpcGateway/medication.pb';
import {
  MedManagementDelegationUiMapping,
  type MedManagementDelegationUiMappingValue,
} from '@/shared/hooks/queries/med-management.queries';

import { getMedicationStrings } from './getMedicationStrings';
import type { MedicationInfo, VitalStrings } from './types';

export function getAsyncReviewNoteBody(
  intl: IntlShape,
  changesWithAsyncTitration: MedicationInfo[],
  vitals: VitalStrings,
  medReviewBody: string,
  rejectionReasonsI18n: Record<Reason, string>,
  medManagementDelegation?: MedManagementDelegationUiMappingValue,
) {
  const md = new MarkdownBuilder();

  md.p(
    `Chart reviewed and 30 day BP average is ${vitals.bp}. Patient currently on the following medication(s):`,
  );
  md.newline();
  md.concat(medReviewBody);
  md.newline();

  changesWithAsyncTitration.forEach(([referenceMed, change]) => {
    const status = change.asyncTitration?.status;

    if (status === AsyncTitrationStatus.INITIALLY_REVIEWED) {
      appendInitiallyApprovedTitrationBody(
        md,
        intl,
        change,
        referenceMed,
        medManagementDelegation,
      );
    }

    if (status === AsyncTitrationStatus.REJECTED_ON_INITIAL_REVIEW) {
      appendInitiallyRejectedTitrationBody(
        md,
        change,
        referenceMed,
        rejectionReasonsI18n,
      );
    }
  });

  return md;
}

function appendInitiallyApprovedTitrationBody(
  md: MarkdownBuilder,
  intl: IntlShape,
  med: MedicationChange,
  referenceMed: ReferenceMedication,
  medManagementDelegation?: MedManagementDelegationUiMappingValue,
) {
  const { isValid, medicationName, dosage, frequencyText } =
    getMedicationStrings(intl, med, referenceMed);

  if (!isValid) {
    return;
  }

  md.p(
    `Review of chart shows patient has tolerated ${medicationName} well and recent laboratory ` +
      'test results, as indicated by Cadence Protocols, reveal no contraindications. Recommend ' +
      `increasing ${medicationName} to ${dosage} ${frequencyText}. `,
  );

  if (
    medManagementDelegation !==
    MedManagementDelegationUiMapping.OptOutRecommendMeds
  ) {
    md.p(
      'Patient will be notified of recommended titration and possible side effects.',
    );

    if (referenceMed.requiredLabs) {
      md.p(
        `This medication needs labs to be drawn ${referenceMed.requiredLabs}.`,
      );
    }
  }
}

function appendInitiallyRejectedTitrationBody(
  md: MarkdownBuilder,
  med: MedicationChange,
  referenceMed: ReferenceMedication,
  rejectionReasonI18n: Record<Reason, string>,
) {
  const { rejectionReason } = getTitrationRecommendation(
    med,
    referenceMed,
    true,
    false,
  );

  md.p(
    `We do not recommend titrating ${referenceMed.name?.toUpperCase()}. Reason: ${
      rejectionReasonI18n[rejectionReason as Reason]
    }.`,
  );
}
