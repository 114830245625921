import { FormattedMessage, useIntl } from 'react-intl';

import { Form } from '@/shared/common/Form';
import { EndEncounterReason } from '@/shared/types/note.types';

import { useEndEncounterReasonI18nMapping } from './i18n';

export function EndEarlyReasons() {
  const intl = useIntl();
  const endEarlyReasonI18n = useEndEncounterReasonI18nMapping();
  return (
    <div>
      <Form.RadioGroup
        required
        size={12}
        name="endEarlyReason"
        label={
          <FormattedMessage defaultMessage="Select the reason this visit was not completed" />
        }
        orientation="vertical"
      >
        <Form.Radio value={EndEncounterReason.PatientUnableToSpeak}>
          {endEarlyReasonI18n[EndEncounterReason.PatientUnableToSpeak]}
        </Form.Radio>
        <Form.Radio value={EndEncounterReason.SchedulingErrorDisenrollment}>
          {endEarlyReasonI18n[EndEncounterReason.SchedulingErrorDisenrollment]}
        </Form.Radio>
        <Form.Radio value={EndEncounterReason.PatientInNonCadenceState}>
          {endEarlyReasonI18n[EndEncounterReason.PatientInNonCadenceState]}
        </Form.Radio>
        <Form.Radio value={EndEncounterReason.PatientHospitalized}>
          {endEarlyReasonI18n[EndEncounterReason.PatientHospitalized]}
        </Form.Radio>
        <Form.Radio value={EndEncounterReason.PatientRefusedAppointment}>
          {endEarlyReasonI18n[EndEncounterReason.PatientRefusedAppointment]}
        </Form.Radio>
        <Form.Radio value={EndEncounterReason.PatientDissatisfied}>
          {endEarlyReasonI18n[EndEncounterReason.PatientDissatisfied]}
        </Form.Radio>
        <Form.Radio value={EndEncounterReason.TimeConstraints}>
          {endEarlyReasonI18n[EndEncounterReason.TimeConstraints]}
        </Form.Radio>
        <Form.Radio value={EndEncounterReason.DeviceDelayTroubleshooting}>
          {endEarlyReasonI18n[EndEncounterReason.DeviceDelayTroubleshooting]}
        </Form.Radio>
        <Form.Radio value={EndEncounterReason.Other}>
          {endEarlyReasonI18n[EndEncounterReason.Other]}
        </Form.Radio>
      </Form.RadioGroup>
      <Form.TextArea
        size={12}
        rows={4}
        label={intl.formatMessage({ defaultMessage: 'Notes' })}
        name="endCallNotes"
        placeholder="Enter optional notes"
      />
    </div>
  );
}
