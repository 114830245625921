import { endOfDay, formatISO, startOfDay } from 'date-fns';
import { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { useQueryClient } from 'react-query';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import { useNoteAsyncTitrationUpdates } from '@/components/AsyncTitration/hooks';
import { appointmentKeys } from '@/pages/patients/PatientProfile/PatientScheduling/appointments.queries';
import { useNoteEditorContext } from '@/pages/patients/patientDetails/ui/Notes/NoteEditorContext';
import { useDeleteDraftNoteByPatientId } from '@/pages/patients/patientDetails/ui/Notes/note.queries';
import { LoadingPlaceholder } from '@/shared/common/LoadingPlaceholder';
import { Modal } from '@/shared/common/Modal';
import { useDeleteAutosavedNoteByPatientId } from '@/shared/hooks/queries/autosave-notes.queries';
import {
  CARE_PLAN_QUERY_KEY_BASE,
  useCarePlanDraft,
} from '@/shared/hooks/queries/carePlan.queries';
import { useCurrentUser } from '@/shared/hooks/useCurrentUser';
import { Button } from '@/shared/tempo/atom/Button';
import { useToaster } from '@/shared/tempo/molecule/Toast';
import type { RouteParam } from '@/shared/types/route.types';
import { grpcNameToId } from '@/shared/utils/grpc';
import { getErrorMsg } from '@/shared/utils/helpers';
import { parentPath } from '@/shared/utils/route-helpers';

import { body } from './DeleteNoteInEndEarly.css';

type Props = {
  onClose: () => void;
  type: 'autosave' | 'draft';
  onResetAddNote: () => void;
};

export function DeleteNoteInEndEarly({ onClose, onResetAddNote, type }: Props) {
  const { patientId }: RouteParam = useParams();
  const { pathname } = useLocation();
  const queryClient = useQueryClient();
  const history = useHistory();
  const { editingNote } = useNoteEditorContext();
  const { currentUserId: providerId } = useCurrentUser();
  const { data: draftCarePlan, isLoading: isLoadingDraft } =
    useCarePlanDraft(patientId);
  const { titrationUpdates, isLoading: isLoadingAssociatedTitrationUpdates } =
    useNoteAsyncTitrationUpdates(editingNote?.note?.id, patientId);

  const hasAssociatedCarePlan =
    draftCarePlan?.noteId && draftCarePlan.noteId === editingNote?.note?.id;

  const { toaster } = useToaster();
  const onDeleteSuccess = useCallback(async () => {
    onClose();
    onResetAddNote();

    await queryClient.invalidateQueries(
      appointmentKeys.list({
        apptStartTimeFrom: formatISO(startOfDay(new Date())),
        apptStartTimeTo: formatISO(endOfDay(new Date())),
        careProviderId: providerId,
      }),
    );
    await queryClient.invalidateQueries(
      appointmentKeys.nextScheduled(patientId),
    );
    await queryClient.invalidateQueries(CARE_PLAN_QUERY_KEY_BASE);

    // Navigate to top-level care-plan route
    if (
      hasAssociatedCarePlan &&
      (pathname.endsWith('/care-plan/draft') ||
        pathname.endsWith(
          `/care-plan/${grpcNameToId(draftCarePlan.name || '')}`,
        ))
    ) {
      history.replace(parentPath(pathname));
    }

    toaster.success(
      <FormattedMessage
        defaultMessage="{noteType} note has been deleted"
        values={{
          noteType:
            type === 'draft' ? (
              <FormattedMessage defaultMessage="Draft" />
            ) : (
              <FormattedMessage defaultMessage="Autosaved" />
            ),
        }}
      />,
    );
    // Want to exlude history from the hook deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    pathname,
    onClose,
    onResetAddNote,
    patientId,
    providerId,
    queryClient,
    toaster,
    type,
  ]);

  const onError = useCallback(
    (error: unknown) => {
      toaster.error(getErrorMsg(error));
    },
    [toaster],
  );

  const deleteParams = [patientId, onDeleteSuccess, onError] as const;
  const deleteAutoSavedNote = useDeleteAutosavedNoteByPatientId(
    ...deleteParams,
  );
  const deleteDraftNote = useDeleteDraftNoteByPatientId(...deleteParams);

  const handleDeleteNote = () => {
    if (type === 'draft') {
      deleteDraftNote.mutate();
    } else {
      deleteAutoSavedNote.mutate(undefined);
    }
  };
  return (
    <>
      <Modal.Header
        title={
          <FormattedMessage
            defaultMessage="Delete this {noteType} note?"
            values={{
              noteType:
                type === 'draft' ? (
                  <FormattedMessage defaultMessage="draft" />
                ) : (
                  <FormattedMessage defaultMessage="autosaved" />
                ),
            }}
          />
        }
      />
      <Modal.Body>
        <LoadingPlaceholder
          isLoading={isLoadingDraft || isLoadingAssociatedTitrationUpdates}
        >
          <div className={body}>
            <FormattedMessage
              defaultMessage="If you delete this {noteType} note the following will happen:"
              values={{
                noteType:
                  type === 'draft' ? (
                    <FormattedMessage defaultMessage="draft" />
                  ) : (
                    <FormattedMessage defaultMessage="autosaved" />
                  ),
              }}
            />
            <ul>
              {hasAssociatedCarePlan && (
                <li>
                  <FormattedMessage defaultMessage="The associated Care Plan will be deleted permanently" />
                </li>
              )}
              <li>
                <FormattedMessage defaultMessage="The call data will no longer be associated with this encounter" />
              </li>
              {titrationUpdates.length > 0 && (
                <li>
                  <FormattedMessage defaultMessage="Any approved, edited, or rejected proactive titrations will revert" />
                </li>
              )}
            </ul>
            <FormattedMessage defaultMessage="This action is permanent and cannot be reverted. Are you positive you want to discard updates and delete?" />
          </div>
        </LoadingPlaceholder>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onPress={onClose}>
          <FormattedMessage defaultMessage="Cancel" />
        </Button>

        <Button
          variant="primary"
          onPress={handleDeleteNote}
          isDisabled={isLoadingDraft}
          isProcessing={
            deleteDraftNote.isLoading || deleteAutoSavedNote.isLoading
          }
        >
          <FormattedMessage defaultMessage="Delete" />
        </Button>
      </Modal.Footer>
    </>
  );
}
