import { AsyncTitrationPatientRejectedTitrationReason as Reason } from '@/shared/generated/grpcGateway/medication.pb';
import { makeI18nHookAndGetter } from '@/shared/hooks/makeI18nHook';

export const {
  hook: usePatientTitrationRejectionReasonsI18n,
  getter: getPatientTitrationRejectionReasonsI18n,
} = makeI18nHookAndGetter<Record<Reason, string>>((intl) => ({
  [Reason.ACUTE_MEDICAL_CONDITION_AFFECTING_VITALS]: intl.formatMessage({
    defaultMessage: 'Acute medical condition affecting vitals',
  }),
  [Reason.DOES_NOT_WANT_TO_TAKE_MORE_MEDICATION]: intl.formatMessage({
    defaultMessage: 'Does not want to take more medication',
  }),
  [Reason.NOT_TAKING_MEDS_AS_PRESCRIBED]: intl.formatMessage({
    defaultMessage:
      'Not taking meds as prescribed/Not adherent to current regimen',
  }),
  [Reason.PROVIDER_SET_GOAL_HIGHER_THAN_130_OVER_80]: intl.formatMessage({
    defaultMessage: 'Has been told by provider that goal is higher than 130/80',
  }),
  [Reason.RECENT_ADJUSTMENT_ON_ANOTHER_MEDICATION]: intl.formatMessage({
    defaultMessage: 'Recent adjustment on another medication',
  }),
  [Reason.RECENT_ADJUSTMENT_ON_THIS_MEDICATION]: intl.formatMessage({
    defaultMessage: 'Recent adjustment on this medication',
  }),
  [Reason.REPORTED_SIDE_EFFECT_AT_HIGHER_DOSE]: intl.formatMessage({
    defaultMessage: 'Side effect at higher dose',
  }),
  [Reason.WOULD_LIKE_TO_SPEAK_TO_PROVIDER_FIRST]: intl.formatMessage({
    defaultMessage: 'Would like to speak to provider first',
  }),
  [Reason.WOULD_LIKE_TO_WORK_ON_LIFESTYLE]: intl.formatMessage({
    defaultMessage: 'Would like to work on lifestyle',
  }),
  [Reason.CADENCE_MEDICATION_LIST_INACCURATE]: intl.formatMessage({
    defaultMessage: 'Cadence medication list inaccurate',
  }),
  [Reason.HIGH_MEDICATION_COST]: intl.formatMessage({
    defaultMessage: 'High cost of medication',
  }),
  [Reason.DOESNT_WANT_INSULIN]: intl.formatMessage({
    defaultMessage: 'Does not want to start insulin',
  }),
  [Reason.PATIENT_REJECTED_TITRATION_REASON_UNSPECIFIED]: intl.formatMessage({
    defaultMessage: 'Reason unspecified',
  }),
}));
