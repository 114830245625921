import type {
  CareProvider as GrpcCareProvider,
  Comment as GrpcComment,
  Task as GrpcTask,
} from '@/shared/generated/grpcGateway/task.pb';
import {
  TaskState as GrpcTaskState,
  TaskTaskTypeDeprecated as GrpcTaskType,
} from '@/shared/generated/grpcGateway/task.pb';
import type { Provider } from '@/shared/types/provider.types';

// this file is mostly re-exporting from the generated grpc types but with
// various tweaks to requiredness since the codegen generates _all_ types as
// optional, which adds a lot of headache when consuming.

export { GrpcTaskType as TaskType };
export { GrpcTaskState as TaskState };
export enum TaskPriority {
  Urgent = 1,
  High = 2,
  Normal = 3,
  Low = 4,
}

export type CareProvider = Required<GrpcCareProvider>;

type CommentWithoutEmbeds = Omit<GrpcComment, 'author'>;
export type Comment = Required<CommentWithoutEmbeds> & {
  author: CareProvider;
};

type TaskWithoutEmbeds = Omit<GrpcTask, 'creator' | 'assignee' | 'comments'>;
export type Task = Optional<Required<TaskWithoutEmbeds>, 'patientId'> & {
  creator: CareProvider;
  assignee: CareProvider | null;
  comments: Comment[];
};

export type CreateTaskPayload = Omit<
  Task,
  | 'name'
  | 'uid'
  | 'createTime'
  | 'updateTime'
  | 'state'
  | 'comments'
  | 'creator'
  | 'assignee'
  | 'etag'
  | 'taskType'
  | 'taskQueues'
  | 'taskTypeId'
  | 'taskQueueIds'
  | 'type'
  | 'noteId'
  | 'scheduledTime'
> & {
  assignee?: { name: string };
  taskTypeId?: string;
  type?: GrpcTaskType;
  scheduledTime?: string;
};

// A Task Provider (which has fewer fields) that has been converted to a Provider
export type ConvertedProvider = Pick<
  Provider,
  'first_name' | 'last_name' | 'role' | 'id'
>;
