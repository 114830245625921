import cx from 'classnames';
import type { HTMLAttributes } from 'react';

import { type TagVariant } from '@/shared/tempo/atom/Tag';

import { badge } from './Badge.css';

export type BadgeVariant = Extract<
  TagVariant,
  'success' | 'danger' | 'default' | 'info'
>;

type Props = {
  variant?: BadgeVariant;
} & HTMLAttributes<HTMLSpanElement>;

export function Badge({
  className,
  children,
  variant = 'default',
  ...props
}: Props) {
  return (
    <span {...props} className={cx(badge[variant], className)}>
      {children}
    </span>
  );
}
