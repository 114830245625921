import { useFormContext } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';

import { Form } from '@/shared/common/Form';

import { GoalUnmetReason } from '../../../../Notes.types';
import { useGoalUnmetReasonsI18n } from '../../../../i18n';
import { roundedMultiselect } from '../ClinicalGoalReachedForm.css';

export function GoalUnmetReasons() {
  const goalUnmetReasonsI18n = useGoalUnmetReasonsI18n();
  const isOtherReason = Boolean(
    useFormContext().watch('reasonsGoalUnmet')?.includes(GoalUnmetReason.Other),
  );
  return (
    <>
      <Form.MultiSelect
        classes={{ root: roundedMultiselect }}
        size={12}
        items={Object.entries(goalUnmetReasonsI18n).map(([value, label]) => ({
          value,
          label,
        }))}
        name="reasonsGoalUnmet"
        label={
          <FormattedMessage defaultMessage="Reason why patient is not at clinical goal *" />
        }
        allOptionsLabel={
          <FormattedMessage defaultMessage="Select all that apply" />
        }
      />
      {isOtherReason && (
        <Form.TextArea
          size={12}
          name="otherReasonDetails"
          label={<FormattedMessage defaultMessage="Other reason details *" />}
        />
      )}
    </>
  );
}
