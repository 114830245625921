import { useIntl } from 'react-intl';

import {
  PatientInfoTagsWrapper,
  TagType,
} from '@/pages/patients/patientInfoSummaries/PatientInfoTags';
import UserIcon from '@/shared/assets/svgs/user.svg?react';
import type {
  AppointmentDetails,
  NextAppointmentRecommendation,
} from '@/shared/generated/grpcGateway/scheduling.pb';
import { color } from '@/shared/tempo/theme';
import { type Patient } from '@/shared/types/patient.types';

import { createFormatInTzFn } from '../appointment.utils';
import { AppointmentInfo } from './AppointmentInfo';
import { RecommendationInfo } from './RecommendationInfo';
import {
  container,
  details,
  iconContainer,
  tagsContainer,
  user,
} from './SchedulingInfo.css';
import { getPatientStateInfo, getPatientTimezoneInfo } from './patient.utils';

type Props = {
  patient: Patient;
  recommendedAppt?: Maybe<NextAppointmentRecommendation>;
  currentAppt?: Maybe<AppointmentDetails>;
};

export function SchedulingInfo({
  patient,
  recommendedAppt,
  currentAppt,
}: Props) {
  const { id: patientId } = patient;
  const patientName = usePatientName(patient);
  const patientState = getPatientStateInfo(patient);
  const timezoneText = useFormattedTimezoneText(patient);
  const timezone = patient.timezone || '';
  const formatInTz = createFormatInTzFn(timezone);

  return (
    <div className={container}>
      <div>
        <div className={user.container}>
          <div className={iconContainer}>
            <UserIcon fill={color.Theme.Light['Base Font']} />
            <div className={user.name}>{patientName}</div>
          </div>
          <PatientInfoTagsWrapper
            patientId={patientId}
            className={tagsContainer}
            show={[TagType.Programs]}
          />
        </div>
        <div className={details}>
          <div>
            {[patientState?.name, timezoneText]?.filter(Boolean).join(' • ')}
          </div>
          <div>
            {recommendedAppt && (
              <RecommendationInfo
                patient={patient}
                recommendedAppt={recommendedAppt}
              />
            )}
            {currentAppt && (
              <AppointmentInfo
                appointment={currentAppt}
                formatInPatientTimezone={formatInTz}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

function usePatientName(patient: Maybe<Patient>) {
  const intl = useIntl();
  const placeholder = intl.formatMessage({ defaultMessage: 'N/A' });
  if (!patient) {
    return placeholder;
  }
  return (
    [patient.first_name, patient.last_name].filter(Boolean).join(' ') ||
    placeholder
  );
}

function useFormattedTimezoneText(patient: Patient) {
  const intl = useIntl();
  const patientTz = patient.timezone;
  if (!patientTz) {
    return intl.formatMessage({ defaultMessage: 'No Timezone' });
  }
  const timezoneInfo = getPatientTimezoneInfo(patient);

  return timezoneInfo
    ? `(${timezoneInfo.display}) ${timezoneInfo.name}`
    : intl.formatMessage(
        { defaultMessage: 'Unknown Timezone - {timezone}' },
        { timezone: patientTz },
      );
}
