import { useIntl } from 'react-intl';

import { useFlags } from '@/shared/hooks';
import {
  DEFAULT_ORDER_BY,
  DEFAULT_ORDER_BY_WITH_SCHEDULED,
  SCHEDULED_TASKS_ORDER_BY,
} from '@/shared/hooks/queries/tasks.queries';
import { Select } from '@/shared/tempo/@labs/molecule/Select';

import { useTaskFilterContext } from '../TaskFilterContext';
import { filterPopover, selectButton, subtleChevron } from './TaskFilters.css';

export function OrderBy() {
  const intl = useIntl();
  const { orderBy, onOrderByChange } = useTaskFilterContext();
  const { enableScheduledTasks } = useFlags();

  const items = [
    {
      displayName: intl.formatMessage({
        defaultMessage: 'Urgency',
      }),
      value: enableScheduledTasks
        ? DEFAULT_ORDER_BY_WITH_SCHEDULED
        : DEFAULT_ORDER_BY,
    },
    {
      displayName: intl.formatMessage({
        defaultMessage: 'Most recent',
      }),
      value: enableScheduledTasks
        ? 'scheduledTime asc, updateTime desc'
        : 'updateTime desc',
    },
    {
      displayName: intl.formatMessage({
        defaultMessage: 'Least recent',
      }),
      value: enableScheduledTasks
        ? 'scheduledTime asc, updateTime asc'
        : 'updateTime asc',
    },
  ];
  if (enableScheduledTasks) {
    items.push({
      displayName: intl.formatMessage({
        defaultMessage: 'Scheduled',
      }),
      value: SCHEDULED_TASKS_ORDER_BY,
    });
  }

  return (
    <Select
      variant="subtle"
      placeholder={intl.formatMessage({ defaultMessage: 'Sort by' })}
      aria-label={intl.formatMessage({ defaultMessage: 'Sort by' })}
      selectedKey={orderBy}
      className={selectButton}
      classes={{ popover: filterPopover, chevron: subtleChevron }}
      popover={{ placement: 'bottom end', width: 'static' }}
      onSelectionChange={(key) => onOrderByChange(key as string)}
      items={items}
    >
      {({ displayName, value }) => (
        <Select.Option key={value}>{displayName}</Select.Option>
      )}
    </Select>
  );
}
