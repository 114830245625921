import { DatePicker as BaseDatePicker } from '@/shared/common/DatePicker';

import type { BaseFieldProps } from '../BaseField';
import { BaseField } from '../BaseField';

type Props = {
  maxDate?: Date;
  minDate?: Date;
  disabled?: boolean;
  inputClass?: string;
  disablePortal?: boolean;
} & BaseFieldProps;

export function DatePicker({
  maxDate,
  minDate,
  disabled,
  inputClass,
  disablePortal,
  ...props
}: Props) {
  return (
    <BaseField {...props}>
      {({ controller }) => (
        <BaseDatePicker
          {...controller.field}
          classes={{ input: inputClass }}
          isError={!!controller.fieldState.error}
          onBlur={controller.field.onBlur}
          maxDate={maxDate}
          minDate={minDate}
          isDisabled={disabled}
          PopperProps={disablePortal ? { disablePortal: true } : undefined}
        />
      )}
    </BaseField>
  );
}
