import { useIntl } from 'react-intl';

import { ConfirmationDialog } from '@/pages/patients/patientDetails/ui/Notes/NoteEditor/dialogs';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
};

export function LateEditConfirmationDialog({
  isOpen,
  onClose,
  onConfirm,
}: Props) {
  const intl = useIntl();

  return (
    <ConfirmationDialog
      isOpen={isOpen}
      onCancel={onClose}
      onConfirm={onConfirm}
      dialogTitle={intl.formatMessage({
        defaultMessage: 'Confirm late edit',
      })}
      dialogDescription={intl.formatMessage({
        defaultMessage:
          'You are creating a late time tracking edit. Please make sure you have approval from your supervisor before making this change.',
      })}
      cancelButtonText={intl.formatMessage({ defaultMessage: 'Go Back' })}
      confirmButtonText={intl.formatMessage({
        defaultMessage: 'Save Anyway',
      })}
    />
  );
}
