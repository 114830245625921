import { FormattedMessage } from 'react-intl';

import { CommonInfoField } from '@/shared/common/CommonInfoField';
import type { Hospital } from '@/shared/generated/grpcGateway/hospital.pb';

import { hospitalNameLabel } from './DynamicPatientInfoFields.css';

const MAX_DISPLAYABLE_HOSPITAL_NAME_CHAR = 46;

type Props = { hospital?: Hospital };

export function LocationInfoField({ hospital }: Props) {
  const locationLabel = <FormattedMessage defaultMessage="Loc" />;

  const hospitalNameString = hospital?.displayName || '';

  return (
    <CommonInfoField
      className={hospitalNameLabel}
      label={locationLabel}
      text={shortenedHospitalNames(hospitalNameString)}
      toolTipMsg={hospitalNameString}
    />
  );
}

function shortenedHospitalNames(hospitalName: string) {
  if (hospitalName.length > MAX_DISPLAYABLE_HOSPITAL_NAME_CHAR) {
    return `${hospitalName.slice(0, MAX_DISPLAYABLE_HOSPITAL_NAME_CHAR)}...`;
  }
  return hospitalName;
}
