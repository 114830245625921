import { parseISO } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import { type IntlShape } from 'react-intl';
import * as yup from 'yup';

import type { FormConfig } from '@/shared/common/Form';
import { validators } from '@/shared/common/Form/validations';
import {
  type PatientDetails,
  ProgramType,
} from '@/shared/generated/grpcGateway/pms.pb';
import { isParticipatingInProgramType } from '@/shared/patient/conditions.utils';
import { getUserTimezone } from '@/shared/utils/time-helpers';

export type FormFields = {
  approvalDate?: Date;
};

export function getFormConfig(
  intl: IntlShape,
  patient: PatientDetails | undefined,
): FormConfig {
  const { required } = validators(intl);
  const approvalDateSchema = getApprovalDateValidator(intl, patient);

  return {
    fields: {
      approvalDate: {
        defaultValue: null,
        validation: required(approvalDateSchema),
      },
      approvalTime: {
        defaultValue: null,
        validation: required(yup.string()),
      },
    },
  };
}

function getApprovalDateValidator(
  intl: IntlShape,
  patient: PatientDetails | undefined,
) {
  const { date } = validators(intl);
  const consentTimezone = patient?.patient?.timeZone || getUserTimezone();
  // CCM-Participating
  if (
    isParticipatingInProgramType(patient, ProgramType.CCM, {
      checkConsent: true,
    }).isParticipating
  ) {
    const ccmConsentDate = patient?.patient?.ccmConsentDate
      ? parseISO(patient.patient.ccmConsentDate)
      : undefined;
    return date({
      minDate: ccmConsentDate
        ? utcToZonedTime(ccmConsentDate, consentTimezone)
        : undefined,
      minDateErrorMessage: intl.formatMessage({
        defaultMessage: 'Precedes CCM Consent Date',
      }),
    });
  }

  // APCM-Participating
  if (
    isParticipatingInProgramType(patient, ProgramType.APCM, {
      checkConsent: true,
    }).isParticipating
  ) {
    return date({
      // TODO: Get zoned APCM consent date once available
      minDate: undefined,
      minDateErrorMessage: intl.formatMessage({
        defaultMessage: 'Precedes APCM Consent Date',
      }),
    });
  }

  return date({});
}
