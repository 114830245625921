import cx from 'classnames';
import { format, startOfDay } from 'date-fns';
import { zonedTimeToUtc } from 'date-fns-tz';
import { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import ScheduledIcon from '@/shared/assets/svgs/alarm.svg?react';
import CloseCircle from '@/shared/assets/svgs/closeCircle.svg?react';

import { DatePicker } from '../../../common/DatePicker';
import { useUpdateTask } from '../../../hooks/queries/tasks.queries';
import { Button } from '../../../tempo/atom/Button/Button';
import { useToaster } from '../../../tempo/molecule/Toast';
import type { Task } from '../../types';
import {
  isAcceptableDate,
  isValidDateObj,
  isoToLocalDate,
  roundToAcceptableDate,
  smallestAcceptableDate,
} from '../../utils';
import { ScheduledIndicator } from '../indicators/ScheduledIndicator';
import {
  clearIcon,
  datePickerBoundsDiv,
  editButton,
  editFade,
  scheduleDatePicker,
  scheduleEditDiv,
  scheduleIcon,
  scheduledButtons,
  scheduledTextDiv,
} from './ScheduleDate.css';

type Props = {
  task: Task;
};

export function ScheduleDate({ task }: Props) {
  // Dates are stored as UTC in backend.
  // Desired behavior is for timezones to behave transparently.
  // I.e. use UTC date as local timezone date to remove timezone offsets.
  // E.g. Nov 9 UTC is treated as Nov 9 EST in EST locales and Nov 9 PST in PST locales.
  const propDate = isoToLocalDate(task.scheduledTime);
  const isTaskScheduled = isAcceptableDate(propDate);
  const [scheduledTimeInDate, setScheduledTimeInDate] = useState(
    roundToAcceptableDate(propDate),
  );
  const [isEditing, setIsEditing] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const isSaveButtonEnabled = isAcceptableDate(scheduledTimeInDate);
  const { toaster } = useToaster();

  // Enable/disable the save button depending on if selected date is valid.
  const handleDateChange = (newDate: unknown) => {
    if (!isValidDateObj(newDate)) {
      return;
    }
    setScheduledTimeInDate(startOfDay(newDate));
  };

  const { isLoading: isSaving, mutate: updateTask } = useUpdateTask(task);
  const saveScheduledDate = () => {
    updateTask(
      {
        task: {
          scheduledTime: zonedTimeToUtc(
            scheduledTimeInDate,
            'UTC',
          ).toISOString(),
        },
      },
      {
        onSuccess: () => {
          toaster.success(
            intl.formatMessage({ defaultMessage: 'Saved scheduled time' }),
          );
          setScheduledTimeInDate(scheduledTimeInDate);
        },
        onError: () => {
          toaster.error(
            intl.formatMessage({
              defaultMessage: 'Failed to save scheduled time',
            }),
          );
        },
      },
    );
  };

  const clearScheduledDate = () => {
    updateTask(
      {
        task: {
          // Can't set to null here because the fieldmask at the grpc-gateway layer wouldn't be able to differentiate
          // setting time to null or no time to set.
          scheduledTime: new Date(0).toISOString(),
        },
      },
      {
        onSuccess: () => {
          toaster.success(
            intl.formatMessage({ defaultMessage: 'Cleared scheduled time' }),
          );
        },
        onError: () => {
          toaster.error(
            intl.formatMessage({
              defaultMessage: 'Failed to clear scheduled time',
            }),
          );
        },
      },
    );
  };

  const intl = useIntl();

  useEffect(() => {
    const timeout = setTimeout(() => setShowEdit(isEditing), 300);
    return () => clearTimeout(timeout);
  }, [isEditing]);

  return (
    <div>
      {showEdit && (
        <div
          className={cx(scheduleEditDiv, {
            [editFade.visible]: isEditing,
            [editFade.hidden]: !isEditing,
          })}
        >
          <div className={datePickerBoundsDiv}>
            <DatePicker
              classes={{ input: scheduleDatePicker }}
              minDate={smallestAcceptableDate()}
              onChange={handleDateChange}
              value={scheduledTimeInDate}
              label={intl.formatMessage({
                defaultMessage: 'Schedule task date',
              })}
              isError={!isSaveButtonEnabled}
              PopperProps={{
                disablePortal: true, // Prevents rendering the dropdown in a portal.
              }}
            />
          </div>
          <Button
            variant="tertiary"
            className={scheduledButtons}
            isDisabled={!isSaveButtonEnabled}
            isProcessing={isSaving}
            onPress={() => {
              saveScheduledDate();
              setIsEditing(false);
            }}
          >
            <FormattedMessage defaultMessage="Save" />
          </Button>
          <Button
            variant="tertiary"
            className={scheduledButtons}
            onPress={() => {
              // Reset the date to the one set in prop.
              setScheduledTimeInDate(roundToAcceptableDate(propDate));
              setIsEditing(false);
            }}
          >
            <FormattedMessage defaultMessage="Cancel" />
          </Button>
          {isTaskScheduled && (
            <CloseCircle
              className={clearIcon}
              onClick={() => {
                clearScheduledDate();
                setIsEditing(false);
              }}
            />
          )}
        </div>
      )}
      {!showEdit && !isTaskScheduled && (
        <div
          className={cx(scheduledTextDiv, {
            [editFade.visible]: !isEditing,
            [editFade.hidden]: isEditing,
          })}
        >
          <Button
            variant="tertiary"
            onPress={() => {
              setIsEditing(true);
            }}
          >
            <ScheduledIcon className={scheduleIcon} />
            <FormattedMessage defaultMessage="Schedule for Later" />
          </Button>
        </div>
      )}
      {!showEdit && isTaskScheduled && (
        <div
          className={cx(scheduledTextDiv, {
            [editFade.visible]: !isEditing,
            [editFade.hidden]: isEditing,
          })}
        >
          <ScheduledIndicator />
          <FormattedMessage
            defaultMessage="Scheduled for {date}"
            values={{ date: format(scheduledTimeInDate, 'MMM d, yyyy') }}
          />
          <Button
            variant="tertiary"
            className={editButton}
            onPress={() => {
              setIsEditing(true);
            }}
          >
            <FormattedMessage defaultMessage="Edit" />
          </Button>
        </div>
      )}
    </div>
  );
}
