import { useIntl } from 'react-intl';

import { Form } from '@/shared/common/Form';
import { useFlags } from '@/shared/hooks';
import type { PatientVitalAvgAndGoals } from '@/shared/hooks/queries/vitalsContext.queries';

import { goalInfo } from '../ClinicalGoalReachedForm.css';
import { BgA1cSummary, isAtA1cGoal, isAtBgGoal, isAtT2dGoal } from '../summary';
import { Goal } from './Goal';
import { GoalUnmetReasons } from './GoalUnmetReason';
import { NonTitrationReason } from './NonTitrationReason';
import { shouldCollectNonTitrationReason } from './shouldCollectNonTitrationReason';
import { STRING_TO_BOOL_MAP } from './stringToBoolMap';
import { useHasMedChange } from './useHasMedChange';

type Props = {
  vitalValuesAndGoals: PatientVitalAvgAndGoals;
};

type LegacyProps = {
  isBgGoalMet?: boolean;
} & Props;

export function T2dGoal({ isBgGoalMet, vitalValuesAndGoals }: LegacyProps) {
  const { gatherNonTitrationReasons } = useFlags();

  if (!gatherNonTitrationReasons) {
    return (
      <LegacyT2dGoal
        isBgGoalMet={isBgGoalMet}
        vitalValuesAndGoals={vitalValuesAndGoals}
      />
    );
  }

  return <NewT2dGoal vitalValuesAndGoals={vitalValuesAndGoals} />;
}

function NewT2dGoal({ vitalValuesAndGoals }: Props) {
  const { isLoading, hasMedChange } = useHasMedChange();

  if (isLoading) {
    return null;
  }

  const bgGoalStatus = isAtBgGoal(vitalValuesAndGoals);
  const a1cGoalStatus = isAtA1cGoal(vitalValuesAndGoals);
  const { isGoalMet } = isAtT2dGoal(bgGoalStatus, a1cGoalStatus);

  return (
    <>
      <div className={goalInfo}>
        <BgA1cSummary />
      </div>
      <Form.Hidden
        name="isA1cGoalMet"
        value={String(a1cGoalStatus.isGoalMet)}
        valueMap={STRING_TO_BOOL_MAP}
      />
      <Form.Hidden
        name="isBgGoalMet"
        value={String(bgGoalStatus.isGoalMet)}
        valueMap={STRING_TO_BOOL_MAP}
      />
      <Form.Hidden
        name="isT2dGoalMet"
        value={String(isGoalMet)}
        valueMap={STRING_TO_BOOL_MAP}
      />
      <Form.Hidden
        name="hasMedChange"
        value={String(hasMedChange)}
        valueMap={STRING_TO_BOOL_MAP}
      />
      {shouldCollectNonTitrationReason({
        isT2d: true,
        hasRelevantMedChange: hasMedChange,
        fields: { isT2dGoalMet: isGoalMet },
      }) && <NonTitrationReason />}
    </>
  );
}

function LegacyT2dGoal({ isBgGoalMet, vitalValuesAndGoals }: LegacyProps) {
  const intl = useIntl();

  return (
    <>
      <Goal
        isGoalMetFieldName="isBgGoalMet"
        goalDetailsFieldName="bgGoalDetails"
        isGoalMetFieldValue={isBgGoalMet}
        description={intl.formatMessage({
          defaultMessage: 'Goal 1 of 1: Is the patient at goal for T2D?',
        })}
        areGoalsDefined={Boolean(
          vitalValuesAndGoals.bgGoal && vitalValuesAndGoals.a1cGoal,
        )}
        vitalSummary={<BgA1cSummary />}
      />
      {isBgGoalMet === false && <GoalUnmetReasons />}
    </>
  );
}
