import type { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';

import { answer, block, question } from './FrequentQuestions.css';

type Props = {
  children: ReactNode;
};

export function QuestionsContainer({ children }: Props) {
  return (
    <div className={block.container}>
      <div className={block.header}>
        <FormattedMessage defaultMessage="FAQs" />
      </div>
      {children}
    </div>
  );
}

export function Question({ children }: Props) {
  return <div className={question}>{children}</div>;
}

export function Answer({ children }: Props) {
  return <div className={answer}>{children}</div>;
}
