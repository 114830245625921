import cx from 'classnames';
import { useState } from 'react';
import { useIntl } from 'react-intl';

import { UserAvatar } from '@/shared/common/UserAvatar';
import { useFlags } from '@/shared/hooks';
import { useSetTaskState } from '@/shared/hooks/queries/tasks.queries';
import { flexContainer } from '@/shared/jsStyle/flex.css';
import { useToaster } from '@/shared/tempo/molecule/Toast';

import { Divider } from '../../common/Divider';
import type { Task } from '../types';
import { TaskState } from '../types';
import { isSchedulableTask } from '../utils';
import { CardSection } from './CardSection';
import { CardTitle } from './CardTitle';
import { Comment } from './Comment';
import { CommentInputControls } from './CommentInputControls';
import { CommentList } from './CommentList';
import { ScheduleDate } from './ScheduleDate/ScheduleDate';
import {
  container,
  dividerTop,
  fullWidthContainer,
  inputCardSection,
  timestamp,
} from './TaskCard.css';
import { TaskHeader } from './TaskHeader';
import { Timestamp } from './Timestamp';
import { UnassignedAvatar } from './UnassignedAvatar';
import { TaskCardContextProvider } from './taskCardContext';
import { isResolved, isUnassigned, lastActivityAt } from './utils';

type Props = {
  task: Task;
};

export function TaskCard({ task }: Props) {
  const { assignee } = task;
  const intl = useIntl();
  const [isEditingComment, setIsEditingComment] = useState(false);
  const [isHovering, setIsHovering] = useState(false);
  const { toaster } = useToaster();
  const { enableScheduledTasks } = useFlags();
  const taskResolved = isResolved(task);
  const { mutate: setTaskState } = useSetTaskState(task, {
    onSuccess: (newState) => {
      toaster.success(
        newState === TaskState.CLOSED
          ? intl.formatMessage({
              defaultMessage: 'Task resolved. Nice work! 🎉',
            })
          : intl.formatMessage({ defaultMessage: 'Task reopened' }),
      );
    },
    onError: () => {
      toaster.error(
        intl.formatMessage({
          defaultMessage: 'Failed to update task',
        }),
      );
    },
  });

  return (
    <TaskCardContextProvider
      value={{ isHovering, isEditingComment, setIsEditingComment }}
    >
      <div
        className={cx(
          taskResolved ? container.resolved : container.default,
          fullWidthContainer,
        )}
        onFocus={() => setIsHovering(true)}
        onBlur={() => setIsHovering(false)}
        onMouseOver={() => setIsHovering(true)}
        onMouseLeave={() => setIsHovering(false)}
      >
        <CardSection>
          <CardSection.LeftColumn>
            {!isUnassigned(task) && assignee ? (
              <UserAvatar
                firstName={assignee.givenName}
                lastName={assignee.familyName}
              />
            ) : (
              <UnassignedAvatar task={task} />
            )}
          </CardSection.LeftColumn>
          <CardSection.RightColumn>
            <TaskHeader
              task={task}
              onResolve={() => setTaskState(TaskState.CLOSED)}
              onReopen={() => setTaskState(TaskState.OPENED)}
            />
            <CardTitle task={task} />
            <Timestamp className={timestamp} ts={lastActivityAt(task)} />
            {enableScheduledTasks && isSchedulableTask(task) && (
              <>
                <Divider className={dividerTop} />
                <ScheduleDate task={task} />
                <Divider />
              </>
            )}
            <Comment taskOrComment={task} canEdit={!isResolved(task)} />
          </CardSection.RightColumn>
        </CardSection>
        <CommentList
          task={task}
          splitMostRecent={!isResolved(task)}
          collapsible={taskResolved}
        />
        {!isResolved(task) && (
          <CardSection className={cx(flexContainer.column, inputCardSection)}>
            <CommentInputControls task={task} isDisabled={isEditingComment} />
          </CardSection>
        )}
      </div>
    </TaskCardContextProvider>
  );
}
