import cx from 'classnames';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';

import {
  emptyStateTitle,
  segmentedTabLabel,
  segmentedTabs,
} from '@/pages/patients/PatientProfile/PatientNotesSidebarPanel/SegmentedTabs.css';
import { NotesList } from '@/pages/patients/patientDetails/ui/Notes/NotesList';
import { usePatientNotes } from '@/pages/patients/patientDetails/ui/Notes/note.queries';
import { ProfileAlertDetail } from '@/pages/patients/patientDetails/ui/ProfileAlertDetail';
import { useFlatPages } from '@/reactQuery';
import mountainImg from '@/shared/assets/mountain.png';
import { SimpleEmptyState as EmptyState } from '@/shared/common/EmptyState';
import {
  SegmentedControlTab,
  SegmentedControlTabList,
  TabContext,
  TabPanel,
} from '@/shared/common/Tab';
import { useFlags, useIsCnExperience, useQueryParams } from '@/shared/hooks';
import {
  DEFAULT_ORDER_BY_WITH_SCHEDULED,
  useTasksByPatientInfinite,
} from '@/shared/hooks/queries/tasks.queries';
import { hidden } from '@/shared/jsStyle/utils.css';
import type { Task } from '@/shared/tasking/types';
import { TaskState } from '@/shared/tasking/types';
import { NotificationBadge } from '@/shared/tempo/atom/NotificationBadge';
import type { VitalsAlert } from '@/shared/types/alert.types';
import type { RouteParam } from '@/shared/types/route.types';

import {
  isScheduledTask,
  startOfTodayUTC,
} from '../../../../shared/tasking/utils';
import { usePatientVitalsAlertsData } from '../shared';
import { ProfileTaskList } from './ProfileTaskList';

enum SelectedTab {
  Tasks = 'Tasks',
  ActiveAlerts = 'Active Alerts',
  Notes = 'Notes',
}

type Props = {
  isHidden: boolean;
  onClickNewNote: () => void;
  onOpenNewAlertNote?: (alert: VitalsAlert) => void;
};

export function SegmentedTabs({
  isHidden,
  onClickNewNote,
  onOpenNewAlertNote,
}: Props) {
  const intl = useIntl();
  const { patientId }: RouteParam = useParams();
  const { isCnExperience } = useIsCnExperience();
  const { enableScheduledTasks } = useFlags();
  const tasksQuery = useTasksByPatientInfinite(
    patientId,
    enableScheduledTasks ? DEFAULT_ORDER_BY_WITH_SCHEDULED : undefined,
    enableScheduledTasks ? startOfTodayUTC() : undefined,
  );
  const tasks = useFlatPages<Task, 'data'>(tasksQuery);
  const openTaskCount = tasks.filter(
    (t) =>
      t.state === TaskState.OPENED &&
      (!enableScheduledTasks || !isScheduledTask(t)),
  ).length;
  const alertCount = usePatientVitalsAlertsData(patientId, true).length;
  const params = useQueryParams();
  const targetNoteId = params.get('noteId');

  const { setSelectedTab, selectedTab } = useSelectedTab(
    isCnExperience,
    openTaskCount,
    alertCount,
    targetNoteId,
  );
  usePatientNotes(patientId);

  return (
    <div
      className={cx(
        segmentedTabs.container,
        // Hide instead of unmount so that selected tab and dropdown option state persists through opening and closing editor
        { [hidden]: isHidden },
      )}
    >
      <TabContext value={selectedTab}>
        <div className={segmentedTabs.tabListContainer}>
          <SegmentedControlTabList
            onChange={(_, value) => {
              setSelectedTab(value);
            }}
          >
            <SegmentedControlTab
              position="left"
              label={
                <div className={segmentedTabLabel.container}>
                  {intl.formatMessage({ defaultMessage: 'Tasks' })}
                  <BadgeCount count={openTaskCount} />
                </div>
              }
              value={SelectedTab.Tasks}
            />
            {!isCnExperience && (
              <SegmentedControlTab
                position="base"
                label={
                  <div className={segmentedTabLabel.container}>
                    {intl.formatMessage({ defaultMessage: 'Alerts' })}
                    <BadgeCount count={alertCount} />
                  </div>
                }
                value={SelectedTab.ActiveAlerts}
              />
            )}
            <SegmentedControlTab
              position="right"
              label={intl.formatMessage({ defaultMessage: 'Notes' })}
              value={SelectedTab.Notes}
            />
          </SegmentedControlTabList>
        </div>
        <TabPanel
          keepMounted
          value={SelectedTab.Tasks}
          className={segmentedTabs.tabPanel}
        >
          <ProfileTaskList query={tasksQuery} />
        </TabPanel>
        <TabPanel
          value={SelectedTab.ActiveAlerts}
          className={segmentedTabs.tabPanel}
        >
          {alertCount > 0 && onOpenNewAlertNote ? (
            <ProfileAlertDetail
              isProfilePage
              patientId={patientId}
              onOpenNewAlertNote={onOpenNewAlertNote}
            />
          ) : (
            <EmptyAlerts />
          )}
        </TabPanel>
        <TabPanel value={SelectedTab.Notes} className={segmentedTabs.tabPanel}>
          <NotesList
            patientId={patientId}
            readOnly={false}
            onClickNewNote={onClickNewNote}
            targetNoteId={targetNoteId}
          />
        </TabPanel>
      </TabContext>
    </div>
  );
}

function useSelectedTab(
  isCnExperience: boolean,
  taskCount: number,
  alertCount: number,
  targetNoteId: Nullable<string>,
) {
  const [selectedTab, setSelectedTab] = useState<SelectedTab | null>(null);
  let defaultTab = SelectedTab.Notes;

  if (targetNoteId) {
    defaultTab = SelectedTab.Notes;
  } else if (taskCount) {
    defaultTab = SelectedTab.Tasks;
  } else if (alertCount && !isCnExperience) {
    defaultTab = SelectedTab.ActiveAlerts;
  }

  const tabToShow = selectedTab ?? defaultTab;

  return { setSelectedTab, selectedTab: tabToShow };
}

function EmptyAlerts() {
  const intl = useIntl();
  return (
    <EmptyState
      image={
        <img
          src={mountainImg}
          alt={intl.formatMessage({ defaultMessage: 'mountain' })}
        />
      }
    >
      <div className={emptyStateTitle}>
        {intl.formatMessage({ defaultMessage: 'No active alerts' })}
      </div>
    </EmptyState>
  );
}

function BadgeCount({ count }: { count: number }) {
  if (count < 1) {
    return null;
  }

  return (
    <NotificationBadge count={count} className={segmentedTabLabel.badge} />
  );
}
