import { settings } from '@/config';

// Field ids for development and production environments
const ACUITY_FIELD_IDS = {
  development: {
    cadenceUrl: '13445419',
    mrn: '13445420',
    conditions: '13445421',
    orderingProvider: '13445422',
    schedulingProviderId: '14045088',
    recommendedSourceApptId: '14045089',
  },
  production: {
    cadenceUrl: '12678313',
    mrn: '12678363',
    conditions: '12678629',
    orderingProvider: '12678700',
    schedulingProviderId: '14047728',
    recommendedSourceApptId: '14047729',
  },
} as const;

export const ACUITY_APPOINTMENT_IDS = {
  development: {
    CCM_CARE_PLAN_VISIT: '47735433',
    CCM_CARE_PLAN_INTERPRETER_VISIT: '67863889',
    APCM_CARE_PLAN_VISIT: '71658942',
    APCM_CARE_PLAN_INTERPRETER_VISIT: '71658974',
  },
  production: {
    CCM_CARE_PLAN_VISIT: '44571689',
    CCM_CARE_PLAN_INTERPRETER_VISIT: '63123878',
    APCM_CARE_PLAN_VISIT: '71667024',
    APCM_CARE_PLAN_INTERPRETER_VISIT: '71667065',
  },
} as const;

type ValuesOf<T> = T[keyof T];
type NestedValues<T> = ValuesOf<ValuesOf<T>>;

type CustomIntakeFormFieldNames = keyof ValuesOf<typeof ACUITY_FIELD_IDS>;
export type CustomIntakeFormIds = NestedValues<typeof ACUITY_FIELD_IDS>;

type ApptTypes = keyof ValuesOf<typeof ACUITY_APPOINTMENT_IDS>;
export type ApptTypeIds = NestedValues<typeof ACUITY_APPOINTMENT_IDS>;

export function getCurrentEnvAcuityFieldIds() {
  const environmentKey = settings.VITE_ENVIRONMENT || 'development';
  if (environmentKey.includes('development')) {
    return ACUITY_FIELD_IDS.development;
  }
  if (environmentKey.includes('production')) {
    return ACUITY_FIELD_IDS.production;
  }
  return {} as Record<CustomIntakeFormFieldNames, CustomIntakeFormIds>;
}

export function getCurrentEnvAcuityApptTypeIds() {
  const environmentKey = settings.VITE_ENVIRONMENT || 'development';
  if (environmentKey.includes('development')) {
    return ACUITY_APPOINTMENT_IDS.development;
  }
  if (environmentKey.includes('production')) {
    return ACUITY_APPOINTMENT_IDS.production;
  }
  return {} as Record<ApptTypes, ApptTypeIds>;
}
