import first from 'lodash/first';
import { useEffect } from 'react';
import type { FieldValues } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';

import { useVitalsAndGoals } from '@/components/AsyncTitration/PatientActivity/useVitalsAndGoals';
import { logger } from '@/logger';
import {
  MedPermissions,
  PatientMedicationsList,
} from '@/pages/patients/PatientMedications/PatientMedicationsList';
import { usePatientMedications } from '@/pages/patients/PatientMedications/patientMedications.queries';
import {
  FieldGroup,
  Script,
} from '@/pages/patients/PatientProfile/CNNotesSidebarPanel/shared';
import { scriptText } from '@/pages/patients/PatientProfile/CNNotesSidebarPanel/shared/Script/Script.css';
import { unorderedVerificationsList } from '@/pages/patients/PatientProfile/CNNotesSidebarPanel/shared/shared.css';
import { useTimeEstimate } from '@/pages/patients/PatientProfile/CNNotesSidebarPanel/shared/useTimeEstimate';
import { LoadingPlaceholder } from '@/shared/common/LoadingPlaceholder';
import { Wizard } from '@/shared/common/Wizard';
import { useVitalsEngagement } from '@/shared/hooks/useVitalsEngagement';
import { Button } from '@/shared/tempo/atom/Button';
import { VitalType } from '@/shared/types/vitals.types';
import { spellOutDigit } from '@/shared/utils/i18n';

import { useCNFormContext } from '../../../CNFormContext';
import { usePendingTitrationList } from '../hooks/usePendingTitrationList';
import { TITRATION_OUTREACH_SECTIONS } from '../metadata';
import { titrationOutreachPath } from '../paths';

export function ChartPrep() {
  const intl = useIntl();
  const history = useHistory();
  const { patientId, noteId } = useCNFormContext();
  const { data: meds, isLoading: isLoadingMeds } =
    usePatientMedications(patientId);
  const { data: engagement, isLoading: isLoadingEngagement } =
    useVitalsEngagement(patientId);
  const { data: vitalsAndGoals, isLoading: isLoadingVitalsAndGoals } =
    useVitalsAndGoals(patientId);
  const bp = vitalsAndGoals?.[VitalType.BloodPressure];
  const titrations = usePendingTitrationList(meds?.referencedMedications);

  // explicitly not handling the case where a patient has multiple pending
  // titrations performed by different providers. this is technically allowed
  // in the data model but it should never happen in practice.
  //
  // to whoever reads this when you're troubleshooting an issue caused by
  // the above despite it "never happening in practice," please contact
  // sean through any means necessary to let him know.
  const provider = first(titrations)?.approvingCareProvider;

  useEffect(() => {
    const providers = titrations?.map(
      (titration) => titration?.approvingCareProviderId,
    );
    const hasMultipleProviders = new Set(providers).size > 1;

    if (hasMultipleProviders) {
      logger.error(
        `CN titration outreach has multiple distinct providers. This doesn't break anything, but the scripting is incorrect and it should be addressed.`,
        undefined,
        {
          patientId,
          providers,
        },
      );
    }
  }, [patientId, titrations]);

  return (
    <Wizard.Step
      sections={TITRATION_OUTREACH_SECTIONS}
      title={intl.formatMessage({ defaultMessage: 'Chart Prep' })}
      subtitle={useTimeEstimate(
        intl.formatMessage({ defaultMessage: '1-3 min' }),
      )}
      onNext={() => {
        // Replace history entry so that we can't navigate back to chart prep step
        history.replace(titrationOutreachPath('/intro', '/patient-attendance'));
      }}
      submitButton={({ submitWithTiming, form, onNext }) => (
        <Button
          size="small"
          type="submit"
          onPress={() => {
            const storeValues = submitWithTiming(true);
            if (form) {
              onNext(storeValues as FieldValues);
            } else {
              onNext();
            }
          }}
          isDisabled={!!form && !form.formState.isValid}
        >
          <FormattedMessage defaultMessage="Complete Chart Prep" />
        </Button>
      )}
    >
      <LoadingPlaceholder
        isLoading={
          isLoadingMeds || isLoadingEngagement || isLoadingVitalsAndGoals
        }
      >
        <FieldGroup>
          <Script className={scriptText}>
            <FormattedMessage defaultMessage="Please review prior to the call. This should take no longer than 2-3 minutes." />
          </Script>
          <Script className={scriptText}>
            <FormattedMessage
              defaultMessage="Cadence NP {npName} has proposed {spelledTitrationCount} {titrationCount, plural, =1 {medication titration} other {medication titrations}} for this patient. Review the change, instructions and side effects to share with the patient, and capture their decision to proceed with the change. But before capturing the patient's decision to proceed with the change, structure current medications and review with the patient."
              values={{
                npName: `${provider?.givenName} ${provider?.familyName}`,
                titrationCount: titrations.length,
                spelledTitrationCount: spellOutDigit(titrations.length),
              }}
            />
          </Script>
          <Script className={scriptText}>
            <FormattedMessage defaultMessage="Recent activity:" />
          </Script>
          <ul className={unorderedVerificationsList}>
            <li>
              <FormattedMessage
                defaultMessage="Above the target goal of {goal} with a 30-day average of {bpAverage}"
                values={{ bpAverage: bp?.avg, goal: bp?.goal }}
              />
            </li>
            <li>
              <FormattedMessage
                defaultMessage="{readingsCount} readings in the last {numDays} days"
                values={{
                  readingsCount: engagement?.daysWithVitals,
                  numDays: engagement?.daysVitalsFetchedFor,
                }}
              />
            </li>
          </ul>
          <PatientMedicationsList
            showRequiredActions={false}
            noteId={noteId}
            patientId={patientId}
            medPermissions={MedPermissions.Review}
            hideOtherMeds
          />
        </FieldGroup>
      </LoadingPlaceholder>
    </Wizard.Step>
  );
}
