import { FormattedMessage } from 'react-intl';

import { TrendDown } from '@/shared/assets/svgs';

import {
  trendDownIcon,
  vitalDataMissing,
  vitalGoalText,
  vitalValueGoalMet,
  vitalValueGoalUnmet,
  vitalValueHeader,
  vitalsContainer,
} from '../ClinicalGoalReachedForm.css';
import { useVitalAvgAndGoals } from '../useVitalAvgAndGoals';
import { InsufficientDataMessage } from './InsufficientDataMessage';
import { isAtA1cGoal, isAtBgGoal, isAtT2dGoal } from './isAtGoal';

export function BgA1cSummary() {
  const { isLoading, vitalValuesAndGoals } = useVitalAvgAndGoals();
  if (isLoading || !vitalValuesAndGoals) {
    return null;
  }

  const { bgAvg30d, bgGoal, a1c, a1cGoal } = vitalValuesAndGoals;
  const { isDataMissing, isGoalMet } = isAtT2dGoal(
    isAtBgGoal(vitalValuesAndGoals),
    isAtA1cGoal(vitalValuesAndGoals),
  );

  let vitalValueTextCls = vitalDataMissing;
  if (!isDataMissing) {
    vitalValueTextCls = isGoalMet ? vitalValueGoalMet : vitalValueGoalUnmet;
  }

  return (
    <div>
      <div className={vitalsContainer}>
        <TrendDown className={trendDownIcon} />
        <div>
          <div>
            <span className={vitalValueHeader}>
              <FormattedMessage defaultMessage="ACTUAL: " />
            </span>
            <span className={vitalValueTextCls}>
              {bgAvg30d || a1c ? (
                <FormattedMessage
                  defaultMessage="{bgAvg30d}mg/dl blood glucose; {a1c}% A1c (30-day avg)"
                  values={{
                    bgAvg30d: bgAvg30d || 'N/A ',
                    a1c: a1c || 'N/A ',
                  }}
                />
              ) : (
                <InsufficientDataMessage />
              )}
            </span>
          </div>
          <div className={vitalGoalText}>
            <FormattedMessage
              defaultMessage="GOAL: <{bgGoal}mg/dl blood glucose; or <{a1cGoal}% A1c (30-day avg)"
              values={{
                bgGoal: bgGoal || 'N/A ',
                a1cGoal: a1cGoal || 'N/A ',
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
