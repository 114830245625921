import { useIntl } from 'react-intl';

import { Form } from '@/shared/common/Form';
import { useFlags } from '@/shared/hooks';
import type { PatientVitalAvgAndGoals } from '@/shared/hooks/queries/vitalsContext.queries';

import { goalInfo } from '../ClinicalGoalReachedForm.css';
import { BpSummary, isAtBpGoal } from '../summary';
import { Goal } from './Goal';
import { GoalUnmetReasons } from './GoalUnmetReason';
import { NonTitrationReason } from './NonTitrationReason';
import { shouldCollectNonTitrationReason } from './shouldCollectNonTitrationReason';
import { STRING_TO_BOOL_MAP } from './stringToBoolMap';
import { useHasMedChange } from './useHasMedChange';

type Props = {
  vitalValuesAndGoals: PatientVitalAvgAndGoals;
};

type LegacyProps = {
  isBpGoalMet?: boolean;
} & Props;

export function HtnGoal({ isBpGoalMet, vitalValuesAndGoals }: LegacyProps) {
  const { gatherNonTitrationReasons } = useFlags();

  if (!gatherNonTitrationReasons) {
    return (
      <LegacyHtnGoal
        isBpGoalMet={isBpGoalMet}
        vitalValuesAndGoals={vitalValuesAndGoals}
      />
    );
  }

  return <NewHtnGoal vitalValuesAndGoals={vitalValuesAndGoals} />;
}

function NewHtnGoal({ vitalValuesAndGoals }: Props) {
  const { isLoading, hasMedChange } = useHasMedChange();

  if (isLoading) {
    return null;
  }

  const { isGoalMet } = isAtBpGoal(vitalValuesAndGoals);

  return (
    <>
      <div className={goalInfo}>
        <BpSummary />
      </div>
      <Form.Hidden
        name="isBpGoalMet"
        value={String(isGoalMet)}
        valueMap={STRING_TO_BOOL_MAP}
      />
      <Form.Hidden
        name="hasMedChange"
        value={String(hasMedChange)}
        valueMap={STRING_TO_BOOL_MAP}
      />
      {shouldCollectNonTitrationReason({
        isHtn: true,
        hasRelevantMedChange: hasMedChange,
        fields: { isBpGoalMet: isGoalMet },
      }) && <NonTitrationReason />}
    </>
  );
}

function LegacyHtnGoal({ isBpGoalMet, vitalValuesAndGoals }: LegacyProps) {
  const intl = useIntl();

  return (
    <>
      <Goal
        isGoalMetFieldName="isBpGoalMet"
        goalDetailsFieldName="bpGoalDetails"
        isGoalMetFieldValue={isBpGoalMet}
        description={intl.formatMessage({
          defaultMessage: 'Goal 1 of 1: Is the patient at goal for HTN?',
        })}
        areGoalsDefined={Boolean(
          vitalValuesAndGoals.systolicGoal && vitalValuesAndGoals.diastolicGoal,
        )}
        vitalSummary={<BpSummary />}
      />
      {isBpGoalMet === false && <GoalUnmetReasons />}
    </>
  );
}
