import classNames from 'classnames';

import { LoadingPlaceholder } from '@/shared/common/LoadingPlaceholder';
import { usePatientDetails, usePatientHospitals } from '@/shared/hooks/queries';

import { SkeletonInfoSummary } from '../SkeletonInfoSummary';
import { BasicPatientInfoFields } from './BasicInfoFields';
import {
  loadingContainer,
  patientInfoContainer,
} from './BasicPatientInfoSummary.css';

type Props = {
  patientId: string | undefined;
};

export function BasicPatientInfoSummary({ patientId }: Props) {
  const { data: patientDetails, isLoading: isLoadingPatient } =
    usePatientDetails(patientId || '', true, !!patientId);
  const { data, isLoading } = usePatientHospitals({
    patientId: patientId || '',
  });

  return (
    <div className={classNames(patientInfoContainer)}>
      <LoadingPlaceholder
        className={loadingContainer}
        isLoading={isLoading || isLoadingPatient}
        placeholder={<SkeletonInfoSummary />}
      >
        {patientDetails && (
          <BasicPatientInfoFields
            patientDetails={patientDetails}
            hospitals={data?.hospitals}
            showMedManagement
          />
        )}
      </LoadingPlaceholder>
    </div>
  );
}
