import { Tag as BaseTag } from './Tag';
import { TagIcon } from './TagIcon';

// Export types
export type { TagVariant, Props as TagProps } from './Tag';

// Export Tag with subcomponents (i.e. Tag.Icon etc...)
export const Tag = Object.assign(BaseTag, {
  Icon: TagIcon,
});
