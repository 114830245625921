import type { Dispatch, SetStateAction, SyntheticEvent } from 'react';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useIntl } from 'react-intl';

import type { TextFieldProps } from '@/deprecated/mui';
import { Autocomplete, IconButton, TextField } from '@/deprecated/mui';
import { queriesAreLoading } from '@/reactQuery';
import CloseIcon from '@/shared/assets/svgs/close.svg?react';
import { useFlags } from '@/shared/hooks';
import {
  useAthenaProviders,
  usePatientDetails,
  usePatientHospitals,
} from '@/shared/hooks/queries';
import { useProviderList } from '@/shared/hooks/queries/ehr-clinic.queries';

type NoteCareProviderSearchBarProps = {
  disabled?: boolean;
  patientId: string;
  onClose: () => void;
  onSelect: (id: string) => void;
  selectedValue?: string;
};

type ProviderOption = { id: string; label: string };

function useNoteProviders(patientId: string) {
  const { ehrProviderSearch: usingNewProviderSearch } = useFlags();
  const clinicsQuery = usePatientHospitals({ patientId });
  const patientsQuery = usePatientDetails(patientId, false);

  const practiceId =
    clinicsQuery.data?.hospitals?.[0]?.ehr_information?.athena_practice_id;

  // we'll only use one of these queries based on the value of the ehr-provider-search flag
  const athenaProvidersQuery = useAthenaProviders(practiceId, {
    enabled: !usingNewProviderSearch,
  });
  const externalProvidersQuery = useProviderList(
    clinicsQuery.data?.hospitals?.[0],
    patientsQuery.data?.ehr_information ?? undefined,
    { enabled: usingNewProviderSearch },
  );
  const isLoading = queriesAreLoading([
    clinicsQuery,
    patientsQuery,
    usingNewProviderSearch ? externalProvidersQuery : athenaProvidersQuery,
  ]);
  const providers = usingNewProviderSearch
    ? externalProvidersQuery.data?.providers
    : athenaProvidersQuery.data?.providers;

  const providerOptions = useMemo(
    () =>
      (providers ?? []).map(({ provider_id, provider_name, provider_type }) => {
        let label = provider_name;
        if (provider_type && !provider_name.includes(', ')) {
          label += `, ${provider_type}`;
        }

        return {
          id: provider_id.toString(),
          label,
        };
      }),
    [providers],
  );

  return {
    providerOptions,
    isLoading,
  };
}

export const NoteCareProviderSearchBar = ({
  disabled,
  patientId,
  onClose,
  onSelect,
  selectedValue,
}: NoteCareProviderSearchBarProps) => {
  const intl = useIntl();
  const [inputValue, setInputValue] = useState('');
  const { providerOptions, isLoading: isLoadingProviders } =
    useNoteProviders(patientId);

  useUpdateInputValue(providerOptions, setInputValue, selectedValue);

  const optionValue = useMemo(() => {
    if (selectedValue) {
      const selectedOption = providerOptions.find(
        (option) => option.id === selectedValue,
      );
      if (selectedOption) {
        return selectedOption;
      }
    }
    // No option selected
    return undefined;
  }, [selectedValue, providerOptions]);

  function handleSelectOption(
    e: SyntheticEvent,
    option: ProviderOption | null | string, // | string to make mui types happy, this should not happen
  ) {
    if (!option || typeof option === 'string') return;
    onSelect(option.id);
  }

  function handleClear() {
    setInputValue('');
    onClose();
  }

  function handleBlur() {
    if (disabled) {
      handleClear();
    }
  }

  function handleInput(e: SyntheticEvent, value: string) {
    setInputValue(value);
  }

  const getInputProps = (params: TextFieldProps) => ({
    ...params.InputProps,
    ...(inputValue && {
      endAdornment: (
        <IconButton disabled={disabled} onMouseDown={handleClear} size="small">
          <CloseIcon width="12px" />
        </IconButton>
      ),
    }),
  });

  const inputRef = useRef<HTMLInputElement>(null);

  return (
    <Autocomplete
      size="small"
      disabled={disabled}
      onBlur={handleBlur}
      onChange={handleSelectOption}
      inputValue={inputValue}
      value={optionValue}
      onInputChange={handleInput}
      loading={isLoadingProviders}
      options={providerOptions}
      fullWidth
      disableClearable
      renderOption={(props, option) => (
        // Override default renderOption to fix bug when there are duplicate
        // labels due to defaultRenderOption setting key={option.label})
        <li {...props} key={option.id}>
          {option.label}
        </li>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          disabled={disabled}
          inputRef={inputRef}
          label={
            isLoadingProviders
              ? intl.formatMessage({ defaultMessage: 'Loading...' })
              : intl.formatMessage({ defaultMessage: 'Search Providers' })
          }
          InputProps={getInputProps(params)}
          sx={{ width: '100%' }}
        />
      )}
    />
  );
};

function useUpdateInputValue(
  providerOptions: ProviderOption[],
  setInputValue: Dispatch<SetStateAction<string>>,
  selectedValue?: string,
) {
  useEffect(() => {
    const selectedOption = providerOptions.find(
      (option) => option.id === selectedValue,
    );
    if (selectedOption) {
      setInputValue(selectedOption?.label || '');
    }
    // We want this to run when selectedValue or providerOptions changes.
    // setInputValue should not change because it is a React setState handler
    // (but the lint rule doesn't detect it as such because it's being passed
    // as a callback)
  }, [selectedValue, providerOptions, setInputValue]);
}
