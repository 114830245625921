import type { Props as TempoSelectProps } from '@/shared/tempo/@labs/molecule/Select';
import { Select as TempoSelect } from '@/shared/tempo/@labs/molecule/Select';

import { BaseField, type BaseFieldProps } from '../BaseField';
import { select } from './Select.css';

type Props<T, V> = {
  label: string;
} & Pick<TempoSelectProps<T>, 'children' | 'placeholder' | 'items'> &
  Omit<BaseFieldProps<V>, 'label'>;

export function Select<T extends object, V>({
  children,
  placeholder,
  items,
  label,
  ...baseProps
}: Props<T, V>) {
  return (
    <BaseField<V> {...baseProps} label="">
      {({ controller: { field, fieldState } }) => (
        <TempoSelect
          isRequired={baseProps.required}
          label={label}
          name={field.name}
          className={select}
          errorMessage={fieldState.error?.message}
          hasError={Boolean(fieldState.error)}
          isDisabled={baseProps.isDisabled}
          selectedKey={field.value ?? null}
          onSelectionChange={field.onChange}
          items={items}
          placeholder={placeholder}
          aria-label={label}
        >
          {children}
        </TempoSelect>
      )}
    </BaseField>
  );
}
