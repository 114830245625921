import { FormattedMessage } from 'react-intl';

export const TITRATION_OUTREACH_SECTIONS = [
  {
    basePath: '/chart-prep',
    excludeFromProgress: true,
    title: <FormattedMessage defaultMessage="Chart Prep" />,
    steps: [{ path: '/index' }],
  },
  {
    basePath: '/intro',
    title: <FormattedMessage defaultMessage="Intro" />,
    steps: [
      { path: '/patient-attendance' },
      { path: '/patient-identification' },
    ],
  },
  {
    basePath: '/med-review',
    title: <FormattedMessage defaultMessage="Med Review" />,
    steps: [{ path: '/index' }],
  },
  {
    basePath: '/titration',
    title: <FormattedMessage defaultMessage="Titration" />,
    steps: [{ path: '/index' }],
  },
  {
    basePath: '/action-plan',
    title: <FormattedMessage defaultMessage="Action Plan" />,
    steps: [
      { path: '/prev-goal-progress' },
      { path: '/prev-goal-progress/discuss' },
      { path: '/no-prev-goal' },
      { path: '/set-goal' },
      { path: '/education-modules-review' },
      { path: '/set-action-steps' },
      { path: '/review-action-steps' },
    ],
  },
  {
    basePath: '/scheduling',
    title: <FormattedMessage defaultMessage="Scheduling" />,

    steps: [{ path: '/index' }],
  },
  {
    basePath: '/time-tracking',
    title: <FormattedMessage defaultMessage="Time Tracking" />,
    steps: [{ path: '/index' }],
  },
  {
    basePath: '/end-call',
    excludeFromProgress: true,
    title: <FormattedMessage defaultMessage="End early" />,
    steps: [{ path: '/index' }],
  },
] as const;
