import { FormattedMessage, useIntl } from 'react-intl';

import { AlertTriangle } from '@/shared/assets/svgs';
import { Link } from '@/shared/tempo/atom/Link';

import {
  alertTriangleIcon,
  noGoalsSet,
  noGoalsSetText,
} from '../ClinicalGoalReachedForm.css';

type Props = {
  healthSystemId: string;
};

export function NoGoalsSetMessage({ healthSystemId }: Props) {
  const intl = useIntl();
  return (
    <div className={noGoalsSet}>
      <AlertTriangle className={alertTriangleIcon} />
      <div className={noGoalsSetText}>
        <FormattedMessage defaultMessage="Clinical goals have not been set for this health system. " />
        <Link.Routed
          to={`/admin/health-system/${healthSystemId}/provider-settings`}
        >
          {intl.formatMessage({ defaultMessage: 'Configure here' })}
        </Link.Routed>
      </div>
    </div>
  );
}
