import { usePatientMedications } from '@/pages/patients/PatientMedications/patientMedications.queries';
import type {
  MedicationChangeChangeType as MedChangeType,
  Medication,
} from '@/shared/generated/grpcGateway/medication.pb';

import { usePropGuard } from './usePropGuard';

type MedFilter = (medication: Medication) => boolean;

export function useNoteHasMedChange(
  patientId: string,
  noteId: Maybe<number>,
  changeTypes: MedChangeType[] = [],
  medFilter: MedFilter = () => true,
): IsLoadingGuard<{ hasMedChange: boolean }> {
  const { isValid, value: validatedNoteId } = usePropGuard(noteId, 'noteId');
  const { data: meds, isLoading } = usePatientMedications(patientId, {
    enabled: isValid,
  });

  if (!isValid) {
    return { isLoading: false, hasMedChange: false };
  }

  if (isLoading) {
    return { isLoading };
  }

  const refMeds = meds?.referencedMedications ?? [];
  const hasMedChange = refMeds
    .filter(medFilter)
    .flatMap((med) => med.medChanges)
    .some((medChange) => {
      if (!medChange) {
        return false;
      }

      if (medChange.noteId !== validatedNoteId) {
        return false;
      }

      if (
        changeTypes.length &&
        medChange.changeType &&
        !changeTypes.includes(medChange.changeType)
      ) {
        return false;
      }

      return true;
    });

  return { isLoading, hasMedChange };
}
