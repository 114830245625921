import first from 'lodash/first';
import type { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';

import {
  type AsyncTitration,
  AsyncTitrationPatientRejectedTitrationReason as RejectionReason,
  AsyncTitrationAsyncTitrationStatus as Status,
} from '@/shared/generated/grpcGateway/medication.pb';

import { InlineMessage } from '../../../../shared/InlineMessage';

type Props = {
  titrationUpdates: AsyncTitration[];
};

const CONTENT_BY_STATUS: Record<string, ReactNode> = {
  [Status.PATIENT_CONSENTED]: (
    <FormattedMessage defaultMessage="After publishing the encounter, please send the patient an SMS confirming medication change using textexpander (.ttcon)" />
  ),
  [Status.PATIENT_REJECTED]: (
    <FormattedMessage defaultMessage='Because the patient rejected the titration: After publishing the encounter, please send a task, "Provider escalation needed," assigned to the CN titration supervisor.' />
  ),
  [Status.ASYNC_TITRATION_STATUS_UNSPECIFIED]: null,
};

const HIDE_FOR_PATIENT_REJECTION_REASONS = [
  RejectionReason.REPORTED_SIDE_EFFECT_AT_HIGHER_DOSE,
  RejectionReason.CADENCE_MEDICATION_LIST_INACCURATE,
  RejectionReason.RECENT_ADJUSTMENT_ON_THIS_MEDICATION,
  RejectionReason.RECENT_ADJUSTMENT_ON_ANOTHER_MEDICATION,
];

export function TitrationUpdateReminder({ titrationUpdates }: Props) {
  // technically possible to have multiple, but protocols disallow it.
  const update = first(titrationUpdates) as AsyncTitration;
  const content =
    CONTENT_BY_STATUS[
      update.status ?? Status.ASYNC_TITRATION_STATUS_UNSPECIFIED
    ];

  if (
    !content ||
    HIDE_FOR_PATIENT_REJECTION_REASONS.includes(
      update.patientRejectedTitrationReason as RejectionReason,
    )
  ) {
    return null;
  }

  return <InlineMessage>{content}</InlineMessage>;
}
