import cx from 'classnames';
import { FormattedMessage } from 'react-intl';

import { ProgramStatusTag } from '@/shared/common/ProgramStatusTag';
import {
  type PatientDetails,
  ProgramType,
} from '@/shared/generated/grpcGateway/pms.pb';
import { isParticipatingInProgramType } from '@/shared/patient/conditions.utils';
import { Tag } from '@/shared/tempo/atom/Tag';

import { errorTag, tagsContainer } from './ProgramStatusTags.css';

type Props = {
  className?: string;
  patientDetails: PatientDetails;
};

export function ProgramStatusTags({ patientDetails, className }: Props) {
  const { isParticipating: isParticipatingRpm } = isParticipatingInProgramType(
    patientDetails,
    ProgramType.RPM,
  );
  const { isParticipating: isParticipatingCcm } = isParticipatingInProgramType(
    patientDetails,
    ProgramType.CCM,
  );
  const { isParticipating: isParticipatingApcm } = isParticipatingInProgramType(
    patientDetails,
    ProgramType.APCM,
  );

  return (
    <div className={cx(tagsContainer, className)}>
      <ProgramStatusTag
        program={ProgramType.RPM}
        patientDetails={patientDetails}
      />
      <ProgramStatusTag
        program={ProgramType.CCM}
        patientDetails={patientDetails}
      />
      <ProgramStatusTag
        program={ProgramType.APCM}
        patientDetails={patientDetails}
      />
      {![isParticipatingRpm, isParticipatingCcm, isParticipatingApcm].some(
        Boolean,
      ) && (
        <Tag variant="error" className={errorTag}>
          <FormattedMessage defaultMessage="No Program Assigned" />
        </Tag>
      )}
    </div>
  );
}
