import { addDays, isValid, parseISO, startOfDay, startOfToday } from 'date-fns';
import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';

import { isResolved } from './TaskCard/utils';
import type { Task } from './types';

export function isValidDateObj(value: unknown): value is Date {
  return isValid(value);
}

// Smallest acceptable date is tomorrow of current date.
export function smallestAcceptableDate(): Date {
  return addDays(startOfDay(new Date()), 1);
}

// Acceptable date is defined as a valid date that's greater or equal than the smallest acceptable date.
export function isAcceptableDate(date: Date): boolean {
  const smallestDate = smallestAcceptableDate();
  return isValid(date.getTime()) && date.getTime() >= smallestDate.getTime();
}

// Sets the time of date to zero if date is acceptable, otherwise return today's date.
export function roundToAcceptableDate(date: Date): Date {
  if (isAcceptableDate(date)) {
    return startOfDay(date);
  }
  return smallestAcceptableDate();
}

export function dateIsScheduled(date: Date): boolean {
  return date >= smallestAcceptableDate();
}

// Converts a Date d to another Date with its local month, day, year set to the UTC month, day, year of Date d.
export function isoToLocalDate(d: string): Date {
  // Call startOfDay() second because it acts on the localized timezone version of time.
  // E.g. if local time is Jan 3 11pm -0500 (Jan 4 4am UTC), then startOfDay() returns Jan 3 12am -500.
  return startOfDay(utcToZonedTime(parseISO(d), 'UTC'));
}

export function isSchedulableTask(task: Task): boolean {
  return !isResolved(task);
}

export function isScheduledTask(task: Task): boolean {
  return (
    isSchedulableTask(task) &&
    dateIsScheduled(isoToLocalDate(task.scheduledTime))
  );
}

export function startOfTodayUTC() {
  return zonedTimeToUtc(startOfToday(), 'UTC');
}
