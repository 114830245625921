import { isValid, parseISO } from 'date-fns';

import type { PatientDetails } from '../generated/grpcGateway/pms.pb';
import type { EhrSyncTaskRequestScope } from '../generated/grpcGateway/synchronization.pb';

export function scopeLastSyncTime(
  patient: Maybe<PatientDetails>,
  scope: EhrSyncTaskRequestScope,
): Maybe<Date> {
  const syncScope = patient?.synchronizationData?.find(
    (task) => task.scope === scope,
  );

  const date = parseISO(
    syncScope?.syncTime || patient?.patient?.lastEhrSyncDate || '',
  );

  return isValid(date) ? date : null;
}
