import cx from 'classnames';
import { FormattedMessage, useIntl } from 'react-intl';

import { logger } from '@/logger';
import ChartIcon from '@/shared/assets/svgs/chart.svg?react';
import { Modal } from '@/shared/common/Modal';
import type {
  Medication,
  MedicationChange,
  RxNormComponent,
} from '@/shared/generated/grpcGateway/medication.pb';
import {
  MedicationChangeChangeType,
  MedicationChangeStatus,
} from '@/shared/generated/grpcGateway/medication.pb';
import { Tag } from '@/shared/tempo/atom/Tag';

import { StatusIcon } from '../MedicationRow';
import type {
  ChangeTypeOptions,
  FrequencyOption,
  RxnormUnitOptions,
  StatusOptions,
} from '../forms/formFieldLabels';
import {
  frequencyLabels,
  medicationChangeStatusLabels,
  medicationChangeTypeLabels,
  rxNormMedicationUnitLabels,
} from '../forms/formFieldLabels';
import {
  ehrMedChartIcon,
  ehrMedInactiveTag,
  ehrMedName,
  ehrMedStatusContainer,
  ehrMedTitle,
  medHistoryDetailText,
  medHistoryHeaderActiveTag,
  medHistoryHeaderNeedsReviewTag,
  medHistoryHeaderRemovedTag,
  medHistoryRow,
  medHistoryRowDetail,
  medHistoryRowDetailSection,
  medHistoryRowTitle,
  medHistoryTypeTag,
  modalBodyContainer,
} from '../styles.css';
import { unstructuredMedInfo } from '../unstructuredMedInfo';
import { emrDate, getSortedMedChanges } from '../utils/medChangeUtils';

export function MedicationHistoryModal({
  onClose,
  medication,
}: {
  onClose: () => void;
  medication: Medication;
}) {
  const medicationsByDate = getSortedMedChanges(medication.medChanges || []);

  const medStatus = medicationsByDate[0].status;

  return (
    <Modal open onClose={onClose}>
      <Modal.Header
        title={<FormattedMessage defaultMessage="Medication History" />}
      />
      <Modal.Body className={modalBodyContainer}>
        <Tag
          className={cx({
            [medHistoryHeaderActiveTag]:
              medStatus === MedicationChangeStatus.ACTIVE,
            [medHistoryHeaderRemovedTag]:
              medStatus === MedicationChangeStatus.INACTIVE,
            [medHistoryHeaderNeedsReviewTag]:
              medStatus === MedicationChangeStatus.NEEDS_REVIEW,
          })}
        >
          <Tag.Icon>
            <StatusIcon status={medicationsByDate[0].status} fromHistoryModal />
          </Tag.Icon>
          {medication.referenceMedicationName?.toUpperCase()}
        </Tag>
        <EhrStatus medication={medication} />
        {medicationsByDate.map((medChange, index) => (
          <MedicationChangeDetails
            medChange={medChange}
            key={medChange.name}
            isFirst={index === medicationsByDate.length - 1}
          />
        ))}
      </Modal.Body>
    </Modal>
  );
}

function EhrStatus({ medication }: { medication: Medication }) {
  const activeEhrMeds = (medication?.medChanges || [])
    .map((mc) => mc.ehrMedication)
    .filter((ehrMed) => ehrMed && ehrMed?.name !== '' && ehrMed.active);

  return (
    <div className={ehrMedStatusContainer}>
      <div className={ehrMedTitle}>
        <ChartIcon className={ehrMedChartIcon} />
        <FormattedMessage defaultMessage="Current EMR Status" />
        {activeEhrMeds.length === 0 && (
          <Tag variant="danger" className={ehrMedInactiveTag}>
            <FormattedMessage defaultMessage="Not active" />
          </Tag>
        )}
      </div>
      {activeEhrMeds.map((ehrMed) => (
        <div className={ehrMedName}>{unstructuredMedInfo(ehrMed)}</div>
      ))}
    </div>
  );
}

function MedicationChangeDetails({
  medChange,
  isFirst,
}: {
  medChange: MedicationChange;
  isFirst: boolean;
}) {
  const {
    changeType,
    updatedDate,
    status,
    frequencies,
    doseQuantities,
    reason,
    rxnorm,
  } = medChange;
  const intl = useIntl();
  const freqI18nLabels = frequencyLabels(intl);
  const unitI18nLabels = rxNormMedicationUnitLabels(intl);
  const statusI18nLabels = medicationChangeStatusLabels(intl);
  const changeTypeI18nLabels = medicationChangeTypeLabels(intl);

  const isPartnerUpdate =
    !isFirst && changeType === MedicationChangeChangeType.EMR_CAPTURE;

  const dateFromEMR = emrDate(medChange);

  return (
    <div className={medHistoryRow}>
      {isPartnerUpdate && (
        <Tag className={medHistoryTypeTag}>
          {isPartnerUpdate && (
            <FormattedMessage defaultMessage="Partner Update" />
          )}
        </Tag>
      )}
      <h3 className={medHistoryRowTitle}>
        {`${
          dateFromEMR
            ? formatDateWithAt(dateFromEMR.toUTCString())
            : formatDateWithAt(updatedDate)
        }${
          isPartnerUpdate
            ? ''
            : ` - ${changeTypeI18nLabels[changeType as ChangeTypeOptions]}`
        }`}
      </h3>
      <div className={medHistoryRowDetailSection}>
        <div className={medHistoryRowDetail}>
          <FormattedMessage defaultMessage="Status:" />
          <div className={medHistoryDetailText}>
            {statusI18nLabels[status as StatusOptions]}
          </div>
        </div>
        {rxnorm?.components && rxnorm.unit && (
          <div className={medHistoryRowDetail}>
            <FormattedMessage defaultMessage="Dose Amount:" />
            <div className={medHistoryDetailText}>
              {getDoseAmountName(
                rxnorm?.components,
                unitI18nLabels[rxnorm.unit as RxnormUnitOptions],
              )}
            </div>
          </div>
        )}
        {doseQuantities &&
          frequencies &&
          doseQuantities?.length !== 0 &&
          (doseQuantities?.length === 1 ? (
            <>
              <div className={medHistoryRowDetail}>
                <FormattedMessage defaultMessage="Dose qty:" />
                <div className={medHistoryDetailText}>{doseQuantities}</div>
              </div>
              <div className={medHistoryRowDetail}>
                <FormattedMessage defaultMessage="Dose frequency:" />
                <div className={medHistoryDetailText}>
                  {freqI18nLabels[frequencies[0] as FrequencyOption]}
                </div>
              </div>
            </>
          ) : (
            <>
              <div className={medHistoryRowDetail}>
                <FormattedMessage defaultMessage="Dose quantities and frequencies:" />
                <div className={medHistoryDetailText}>
                  {getSplitDoseInfo(
                    doseQuantities,
                    frequencies.map(
                      (freq) => freqI18nLabels[freq as FrequencyOption],
                    ),
                  )}
                </div>
              </div>
            </>
          ))}
        {/** Show New Structured Note column when available:
         * https://cadencerpm.atlassian.net/browse/PLAT-6352
         */}
        {reason && (
          <div className={medHistoryRowDetail}>
            <FormattedMessage defaultMessage="Patient note: " />
            <div className={medHistoryDetailText}>{reason}</div>
          </div>
        )}
      </div>
    </div>
  );
}

function getDoseAmountName(components: RxNormComponent[], unit: string) {
  if (components.length === 0) {
    return 'N/A';
  }
  if (components.length === 1) {
    return `${components[0].strength}${unit}`;
  }
  return components
    .map((comp) => `${comp.ingredient} ${comp.strength}${unit}`)
    .join(', ');
}

function getSplitDoseInfo(quantities: number[], frequencies: string[]) {
  if (quantities.length !== frequencies.length) {
    return 'Unmatched quantities and frequencies';
  }
  return frequencies
    .map((freq, index) => `${quantities[index]} ${freq}`)
    .join(', ');
}

function formatDateWithAt(dateString: Maybe<string>): string {
  if (!dateString) {
    logger.error('Unmatched quantities and frequencies');
    return 'Date Not Available';
  }

  const date = new Date(dateString);
  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: '2-digit',
    hour12: true,
  };

  return new Intl.DateTimeFormat('en-US', options)
    .format(date)
    .replace(',', ' at ');
}
