import {
  ConditionConfidence,
  type Condition as PmsCondition,
  ProgramStatus,
  ProgramType,
} from '@/shared/generated/grpcGateway/pms.pb';
import type { RpmCondition } from '@/shared/types/clinicalprofile.types';
import type { Program } from '@/shared/types/patient.types';
import { PatientStatus } from '@/shared/types/patient.types';

import { convertToRpmConditions } from '../conditions.utils';

/**
 * @deprecated
 */
export function getDeprecatedRpmConditionsFromProgramAndStatus(
  programs: Maybe<Program[]>,
  status: Maybe<PatientStatus>,
): RpmCondition[] {
  if (!programs?.length || !status) {
    return [];
  }
  let programStatus: ProgramStatus;
  if ([PatientStatus.Enrolled].includes(status)) {
    programStatus = ProgramStatus.ENROLLED;
  } else {
    programStatus = ProgramStatus.SELECTED;
  }
  const foundProgram = programs.find(
    (program) =>
      program.program_type === ProgramType.RPM &&
      program.program_status === programStatus,
  );

  return getDeprecatedRpmConditionsByProgram(foundProgram);
}

/**
 * @deprecated
 */
export function getDeprecatedRpmConditionsByProgram(
  program: Maybe<Program>,
  highConfidenceOnly?: boolean,
): RpmCondition[] {
  if (!program || !program.conditions) {
    return [];
  }
  const conditionTypes: PmsCondition[] = [];
  program.conditions.forEach((condition) => {
    if (condition.condition_type) {
      if (
        highConfidenceOnly &&
        condition.condition_confidence !== ConditionConfidence.HIGH_CONFIDENCE
      ) {
        return;
      }
      conditionTypes.push(condition.condition_type);
    }
  });
  return convertToRpmConditions(conditionTypes);
}
