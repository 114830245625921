import cx from 'classnames';
import { FormattedMessage, useIntl } from 'react-intl';

import PillIcon from '@/shared/assets/svgs/pill.svg?react';
import {
  ActionIconTag,
  ActionIconTagType,
} from '@/shared/common/ActionIconTag';
import { type PatientDetails } from '@/shared/generated/grpcGateway/pms.pb';
import {
  MedManagementDelegationUiMapping,
  type MedManagementDelegationUiMappingValue,
  useMedManagementDelegation,
} from '@/shared/hooks/queries/med-management.queries';
import { color } from '@/shared/tempo/theme';

import { accentuateWarning, pillIconStyle } from './PatientMedsInfoTag.css';

type Props = {
  patient: PatientDetails;
};

export function PatientMedsInfoTag({ patient }: Props) {
  const { isLoading, shouldNotifyProvider, medDelegation } =
    useMedManagementDelegation(patient);
  const patientMedsMgmtLabels = usePatientMedsMgmtLabels();

  const { tooltip, iconColor, severityLevel, label } =
    patientMedsMgmtLabels[medDelegation];
  return (
    <>
      {medDelegation && (
        <ActionIconTag
          isLoading={isLoading}
          className={cx({ [accentuateWarning]: !!shouldNotifyProvider })}
          tagType={severityLevel}
          icon={<PillIcon className={pillIconStyle} stroke={iconColor} />}
          tooltipMessage={tooltip}
          tagLabel={
            <FormattedMessage
              defaultMessage="{medsMgmtLabel}"
              values={{ medsMgmtLabel: label }}
            />
          }
        />
      )}
    </>
  );
}

type PatientMedsMgmtLabels = {
  [k in MedManagementDelegationUiMappingValue]: {
    label: string;
    severityLevel: ActionIconTagType;
    tooltip: string;
    iconColor: string;
  };
};
function usePatientMedsMgmtLabels(): PatientMedsMgmtLabels {
  const intl = useIntl();
  return {
    [MedManagementDelegationUiMapping.OptInFullyManaged]: {
      label: intl.formatMessage({
        defaultMessage: 'Fully managed meds',
      }),
      severityLevel: ActionIconTagType.NEUTRAL,
      tooltip: intl.formatMessage({
        defaultMessage:
          'New medications and medication changes are fully delegated.',
      }),
      iconColor: color.Theme.Light.Success,
    },
    [MedManagementDelegationUiMapping.OptInFullyManagedAndNotify]: {
      label: intl.formatMessage({
        defaultMessage: 'Fully managed meds + Notify',
      }),
      severityLevel: ActionIconTagType.NEUTRAL,
      tooltip: intl.formatMessage({
        defaultMessage:
          'New medications and medication changes are fully delegated. Should notify provider of med changes.',
      }),
      iconColor: color.Theme.Light.Success,
    },
    [MedManagementDelegationUiMapping.OptInCurrentMeds]: {
      label: intl.formatMessage({
        defaultMessage: 'Current meds',
      }),
      severityLevel: ActionIconTagType.WARNING,
      tooltip: intl.formatMessage({
        defaultMessage:
          'Only titrate and manage current medications. Any new medications will require physician approval.',
      }),
      iconColor: color.Theme.Light.Warning,
    },
    [MedManagementDelegationUiMapping.OptInCurrentMedsAndNotify]: {
      label: intl.formatMessage({
        defaultMessage: 'Current meds + Notify',
      }),
      severityLevel: ActionIconTagType.WARNING,
      tooltip: intl.formatMessage({
        defaultMessage:
          'Only titrate and manage current medications. Any new medications will require physician approval. Should notify provider of med changes.',
      }),
      iconColor: color.Theme.Light.Warning,
    },
    [MedManagementDelegationUiMapping.OptOutRecommendMeds]: {
      label: intl.formatMessage({
        defaultMessage: 'Recommend meds',
      }),
      tooltip: intl.formatMessage({
        defaultMessage:
          'Do not manage medications for patient without physician approval.',
      }),
      severityLevel: ActionIconTagType.CAUTION,
      iconColor: color.Theme.Light['Caution Shade'],
    },
    [MedManagementDelegationUiMapping.NoReferringPhysician]: {
      label: intl.formatMessage({
        defaultMessage: 'No physician assigned',
      }),
      tooltip: intl.formatMessage({
        defaultMessage: 'No referring physician assigned.',
      }),
      severityLevel: ActionIconTagType.ERROR_OUTLINE,
      iconColor: color.Theme.Light.Danger,
    },
    [MedManagementDelegationUiMapping.ProviderMedMgmtMissing]: {
      label: intl.formatMessage({
        defaultMessage: 'Meds preference missing',
      }),
      severityLevel: ActionIconTagType.ERROR_OUTLINE,
      tooltip: intl.formatMessage({
        defaultMessage:
          'Please Slack the provider relations manager (PRM) to resolve',
      }),
      iconColor: color.Theme.Light.Danger,
    },
    [MedManagementDelegationUiMapping.OptOutProviderManaged]: {
      label: intl.formatMessage({
        defaultMessage: 'Provider managed',
      }),
      severityLevel: ActionIconTagType.ERROR,
      tooltip: intl.formatMessage({
        defaultMessage:
          'Do not manage medications for patient without physician approval.',
      }),
      iconColor: color.Theme.Light.Danger,
    },
    [MedManagementDelegationUiMapping.ToBeDetermined]: {
      label: intl.formatMessage({
        defaultMessage: 'To be determined',
      }),
      severityLevel: ActionIconTagType.ERROR_OUTLINE,
      tooltip: intl.formatMessage({
        defaultMessage:
          "Provider's medication preference is not set, contact partnerships for provider's medication preference",
      }),
      iconColor: color.Theme.Light.Danger,
    },
  };
}
