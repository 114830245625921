import * as yup from 'yup';

export type TitrationFormFields = {
  pharmacy: string;
  preferredLabwork: string;
  patientNote: string;
};

export function getTitrationFormConfig() {
  return {
    fields: {
      pharmacy: {
        defaultValue: '',
        validation: yup.string(),
      },
      preferredLabwork: {
        defaultValue: '',
        validation: yup.string(),
      },
      patientNote: {
        defaultValue: '',
        validation: yup.string(),
      },
    },
  };
}
