import uniq from 'lodash/uniq';
import { useIntl } from 'react-intl';

import type { Condition, Program } from '@/shared/generated/grpcGateway/pms.pb';
import {
  PatientStatusEnum,
  ProgramStatus,
  ProgramType,
} from '@/shared/generated/grpcGateway/pms.pb';

export function useProgramTypeMapping() {
  const intl = useIntl();
  return {
    [ProgramType.RPM]: intl.formatMessage({
      defaultMessage: 'RPM',
    }),
    [ProgramType.CCM]: intl.formatMessage({
      defaultMessage: 'CCM',
    }),
    [ProgramType.APCM]: intl.formatMessage({
      defaultMessage: 'APCM',
    }),
    [ProgramType.PROGRAM_TYPE_UNSPECIFIED]: intl.formatMessage({
      defaultMessage: 'Unspecified',
    }),
  };
}

export function useProgramStatusMapping() {
  const intl = useIntl();
  return {
    [ProgramStatus.ELIGIBLE]: intl.formatMessage({
      defaultMessage: 'Eligible',
    }),
    [ProgramStatus.ORDERED]: intl.formatMessage({
      defaultMessage: 'Ordered',
    }),
    [ProgramStatus.SELECTED]: intl.formatMessage({
      defaultMessage: 'Selected',
    }),
    [ProgramStatus.ENROLLED]: intl.formatMessage({
      defaultMessage: 'Enrolled',
    }),
    [ProgramStatus.DISENROLLED]: intl.formatMessage({
      defaultMessage: 'Disenrolled',
    }),
    [ProgramStatus.SUGGESTED]: intl.formatMessage({
      defaultMessage: 'Suggested',
    }),
    [ProgramStatus.PROGRAM_STATUS_UNSPECIFIED]: intl.formatMessage({
      defaultMessage: 'Unspecified',
    }),
  };
}

export function getRpmConditions(
  programs: Maybe<Program[]>,
  status: Maybe<PatientStatusEnum>,
): Condition[] {
  if (!programs?.length || !status) {
    return [];
  }
  let programStatus: ProgramStatus;
  if ([PatientStatusEnum.ENROLLED].includes(status)) {
    programStatus = ProgramStatus.ENROLLED;
  } else {
    programStatus = ProgramStatus.SELECTED;
  }
  const foundProgram = programs.find(
    (program) =>
      program.programType === ProgramType.RPM &&
      program.programStatus === programStatus,
  );

  const conditions =
    foundProgram?.conditions
      ?.filter((condition) => Boolean(condition.conditionType))
      .map((condition) => condition.conditionType as Condition) || [];
  return uniq(conditions);
}
