import { Fragment } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { InfoRow } from '@/pages/patients/patientDetails/ui/tabs/PatientInfo/InfoRow';
import { LoadingPlaceholder } from '@/shared/common/LoadingPlaceholder';
import { Snackbar } from '@/shared/common/Snackbar';
import { CareProviderProviderSpecialty } from '@/shared/generated/grpcGateway/cadence/models/models.pb';
import type { PatientExternalProvider } from '@/shared/generated/grpcGateway/pms.pb';
import { useFlags } from '@/shared/hooks';
import {
  usePatientCareProviders,
  usePatientDetails,
} from '@/shared/hooks/queries';
import type {
  PatientDetails,
  RelationshipKey,
} from '@/shared/types/patient.types';
import { Relationship } from '@/shared/types/patient.types';
import { ProviderType } from '@/shared/types/provider.types';
import type { PhoneType } from '@/shared/types/shared.types';
import { formatPhoneNumber } from '@/shared/utils/phone';

import { AssignedDevices } from './AssignedDevices';
import { Caregivers } from './Caregivers';
import { CommunicationPreferences } from './CommunicationPreferences';
import { Contact } from './Contact';
import { EmptySection } from './EmptySection';
import { MedicalInfo } from './MedicalInfo';
import { PatientGoals } from './PatientGoals';
import { leftColumn, rightColumn, tableContainer } from './PatientInfo.css';
import { PersonalInfo } from './PersonalInfo';
import { SectionHeader } from './SectionHeader';
import { getAssociationsLink } from './adminLinks';
import {
  getCardiologistProvider,
  getPrimaryCareProvider,
  getReferringCareProvider,
} from './externalProvider.util';
import { useStaff } from './useStaff';

function PhysicianDetails({ provider }: { provider: PatientExternalProvider }) {
  const intl = useIntl();

  return (
    <>
      <InfoRow
        left={`${provider.careProvider?.givenName} ${provider.careProvider?.familyName}`}
      />
      <InfoRow
        leftSubdued
        left={intl.formatMessage({ defaultMessage: 'Email' })}
        right={provider.careProvider?.email}
      />
      <InfoRow
        leftSubdued
        left={intl.formatMessage({
          defaultMessage: 'NPI',
          description: 'Abbreviation for National Provider Identifier',
        })}
        right={provider.careProvider?.npi}
      />
      <InfoRow
        leftSubdued
        left={intl.formatMessage({ defaultMessage: 'Hospital' })}
        right={provider.careProvider?.primaryHospital}
      />
    </>
  );
}

type PatientInfoProps = {
  patient: PatientDetails;
  updatePatient: (patient: PatientDetails) => void;
};

export function PatientInfo({ patient, updatePatient }: PatientInfoProps) {
  const intl = useIntl();
  const { displayExternalCareTeamForPatient } = useFlags();
  const { data: patientDetails, isLoading: patientDetailsLoading } =
    usePatientDetails(patient.id ?? '', true, !!patient.id);

  const referringCp = getReferringCareProvider(
    patientDetails?.externalCareProviders,
  );
  const primaryCp = getPrimaryCareProvider(
    patientDetails?.externalCareProviders,
  );
  const cardioCp = getCardiologistProvider(
    patientDetails?.externalCareProviders,
  );

  // Only display Primary CP if it is different from Referring and from the cardiologist
  const isDifferentPrimaryCp =
    referringCp?.careProvider?.id !== primaryCp?.careProvider?.id &&
    (cardioCp === undefined ||
      cardioCp.careProvider?.id !== primaryCp?.careProvider?.id);

  // Only display Cardiologist if it is different than referring
  const isDifferentCardioCp =
    referringCp?.careProvider?.id !== cardioCp?.careProvider?.id;

  const [staffIsLoading, fetchError, staff] = useStaff(patient.id);
  // Used to de-dupe the supervising provider from care providers below
  // Not the most ideal criteria, but should do the job. No ID is provided
  // from the staff endpoint.
  const supervisingProviderEmail = staff?.referring_physician?.email;

  const {
    data: careProviders,
    isLoading: careProvidersLoading,
    isError: careProvidersError,
  } = usePatientCareProviders(patient.id, {});
  const cadenceCareProviders = careProviders?.care_providers?.filter(
    (careProvider) =>
      careProvider.provider_type === ProviderType.Cadence &&
      careProvider.email !== supervisingProviderEmail,
  );
  const hasCadenceCareProviders =
    cadenceCareProviders && cadenceCareProviders.length > 0;
  const hospitalCareProviders = careProviders?.care_providers?.filter(
    (careProvider) =>
      careProvider.provider_type === ProviderType.Hospital &&
      careProvider.email !== supervisingProviderEmail,
  );
  const hasHospitalCareProviders =
    hospitalCareProviders && hospitalCareProviders.length > 0;

  const emergencyContacts =
    patient.contacts?.filter(({ emergency }) => !!emergency) ?? [];
  const hasHospitals = staff?.hospitals.length;
  const hospitalsLink = getAssociationsLink(patient.id, 'hospitals');
  const cliniciansLink = getAssociationsLink(patient.id, 'providers');

  return (
    <div className={tableContainer}>
      {(fetchError || careProvidersError) && (
        <Snackbar message={fetchError} variant="error" />
      )}
      <div className={leftColumn}>
        <div>
          <SectionHeader>
            <FormattedMessage defaultMessage="Communication Preferences" />
          </SectionHeader>
          <CommunicationPreferences />
        </div>
        <div>
          <SectionHeader editLink={`/admin/patient/${patient.id}/profile`}>
            <FormattedMessage defaultMessage="Personal Info" />
          </SectionHeader>
          <PersonalInfo patient={patient} />
        </div>
        <div>
          <PatientGoals patientId={patient.id} />
        </div>
        <div>
          <Caregivers patientId={patient.id} />
        </div>
        <div>
          <SectionHeader>
            <FormattedMessage defaultMessage="Emergency Contact" />
          </SectionHeader>
          {emergencyContacts.length ? (
            emergencyContacts.map(({ contact_id, contact, relationship }) => (
              <div key={`emergency-${contact_id}`}>
                <InfoRow
                  left={`${contact.name}, ${
                    Relationship[relationship as RelationshipKey]
                  }`}
                />
                <Contact
                  isPreferred={false}
                  phoneNumber={contact.phone_number}
                  phoneType={contact.phone_type as PhoneType}
                />
              </div>
            ))
          ) : (
            <p>
              <FormattedMessage defaultMessage="This patient does not have an emergency contact." />
            </p>
          )}
        </div>
      </div>
      <div className={rightColumn}>
        <div>
          <SectionHeader>
            <FormattedMessage defaultMessage="Supervising Provider" />
          </SectionHeader>
          <LoadingPlaceholder isLoading={patientDetailsLoading}>
            {referringCp ? (
              <PhysicianDetails provider={referringCp} />
            ) : (
              <EmptySection
                message={intl.formatMessage({
                  defaultMessage: 'No supervising provider configured',
                })}
              />
            )}
          </LoadingPlaceholder>
        </div>
        <div>
          {displayExternalCareTeamForPatient &&
            isDifferentPrimaryCp &&
            primaryCp && (
              <>
                <SectionHeader>
                  {primaryCp.careProvider?.providerSpecialty ===
                  CareProviderProviderSpecialty.RESIDENT ? (
                    <FormattedMessage defaultMessage="Primary Care Provider (Resident)" />
                  ) : (
                    <FormattedMessage defaultMessage="Primary Care Provider" />
                  )}
                </SectionHeader>
                <PhysicianDetails provider={primaryCp} />
              </>
            )}
        </div>
        <div>
          {displayExternalCareTeamForPatient &&
            isDifferentCardioCp &&
            cardioCp && (
              <>
                <SectionHeader>
                  <FormattedMessage defaultMessage="Cardiologist" />
                </SectionHeader>
                <PhysicianDetails provider={cardioCp} />
              </>
            )}
        </div>
        <div>
          <SectionHeader
            editLink={hasHospitalCareProviders ? cliniciansLink : undefined}
          >
            <FormattedMessage defaultMessage="Additional External Care Team" />
          </SectionHeader>
          <LoadingPlaceholder isLoading={careProvidersLoading}>
            {hasHospitalCareProviders ? (
              hospitalCareProviders.map((careProvider) => (
                <Fragment key={careProvider.id}>
                  <InfoRow
                    left={`${careProvider.first_name} ${careProvider.last_name}`}
                    right={formatPhoneNumber(
                      careProvider.contact?.phone_number,
                    )}
                  />
                  <InfoRow
                    leftSubdued
                    left={intl.formatMessage({
                      defaultMessage: 'Email',
                    })}
                    right={careProvider.email}
                  />
                </Fragment>
              ))
            ) : (
              <EmptySection
                message={intl.formatMessage({
                  defaultMessage: 'No assigned additional external care team',
                })}
                action={intl.formatMessage({
                  defaultMessage: 'Assign clinician',
                })}
                href={cliniciansLink}
              />
            )}
          </LoadingPlaceholder>
        </div>
        <div>
          <SectionHeader
            editLink={hasCadenceCareProviders ? cliniciansLink : undefined}
          >
            <FormattedMessage defaultMessage="Cadence Care Team" />
          </SectionHeader>
          <LoadingPlaceholder isLoading={careProvidersLoading}>
            {hasCadenceCareProviders ? (
              cadenceCareProviders.map((careProvider) => (
                <Fragment key={careProvider.id}>
                  <InfoRow
                    left={`${careProvider.first_name} ${careProvider.last_name}`}
                    right={formatPhoneNumber(
                      careProvider.contact?.phone_number,
                    )}
                  />
                  <InfoRow
                    leftSubdued
                    left={intl.formatMessage({
                      defaultMessage: 'Email',
                    })}
                    right={careProvider.email}
                  />
                </Fragment>
              ))
            ) : (
              <EmptySection
                message={intl.formatMessage({
                  defaultMessage: 'No assigned Cadence clinicians',
                })}
                action={intl.formatMessage({
                  defaultMessage: 'Assign clinician',
                })}
                href={cliniciansLink}
              />
            )}
          </LoadingPlaceholder>
        </div>
        <div>
          <SectionHeader editLink={hasHospitals ? hospitalsLink : undefined}>
            <FormattedMessage defaultMessage="Hospitals" />
          </SectionHeader>
          <LoadingPlaceholder isLoading={staffIsLoading}>
            {hasHospitals ? (
              staff?.hospitals.map((hospital) => (
                <div key={`hospital-${hospital.phone_number}`}>
                  <InfoRow left={hospital.name} />
                  <InfoRow
                    leftSubdued
                    left={intl.formatMessage({
                      defaultMessage: 'Phone',
                    })}
                    right={formatPhoneNumber(hospital.phone_number)}
                  />
                </div>
              ))
            ) : (
              <EmptySection
                message={intl.formatMessage({
                  defaultMessage: 'No assigned hospital',
                })}
                action={intl.formatMessage({
                  defaultMessage: 'Assign hospital',
                })}
                href={hospitalsLink}
              />
            )}
          </LoadingPlaceholder>
        </div>
        <div>
          <MedicalInfo patient={patient} updatePatient={updatePatient} />
        </div>
        <div>
          <AssignedDevices patientId={patient.id} timezone={patient.timezone} />
        </div>
        <div>
          <SectionHeader>
            <FormattedMessage defaultMessage="Insurance" />
          </SectionHeader>
          <InfoRow
            leftSubdued
            left={intl.formatMessage({
              defaultMessage: 'Primary',
            })}
            right={patient.primary_insurance_name}
          />
          <InfoRow
            leftSubdued
            left={intl.formatMessage({
              defaultMessage: 'Secondary',
            })}
            right={patient.secondary_insurance_name}
          />
        </div>
      </div>
    </div>
  );
}
