import { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory, useRouteMatch } from 'react-router-dom';

import { CarePlanAlertBanner } from '@/pages/patients/patientDetails/ui/tabs/CarePlan/CarePlanAlertBanner';
import { marshalCarePlanToFormData } from '@/pages/patients/patientDetails/ui/tabs/CarePlan/CarePlanForm/dataTransformation';
import { useOnSave } from '@/pages/patients/patientDetails/ui/tabs/CarePlan/CarePlanForm/persistence';
import { LoadingPlaceholder } from '@/shared/common/LoadingPlaceholder';
import {
  PatientStatusEnum,
  ProgramType,
} from '@/shared/generated/grpcGateway/pms.pb';
import { usePatientDetails } from '@/shared/hooks/queries';
import { useCarePlanDraft } from '@/shared/hooks/queries/carePlan.queries';
import { isParticipatingInProgramType } from '@/shared/patient/conditions.utils';
import { Button } from '@/shared/tempo/atom/Button';
import { Link } from '@/shared/tempo/atom/Link';
import { Tooltip } from '@/shared/tempo/atom/Tooltip';
import { useToaster } from '@/shared/tempo/molecule/Toast';
import { grpcNameToId } from '@/shared/utils/grpc';

import { divider } from './CarePlanButton.css';
import { ReassociateCarePlanModal } from './ReassociateCarePlanModal';

type Props = {
  noteId: Maybe<number>;
};

export function CarePlanButton({ noteId }: Props) {
  const history = useHistory();
  const intl = useIntl();
  const [showReassignModal, setShowReassignModal] = useState(false);
  const { toaster } = useToaster();
  const { url, params } = useRouteMatch<{ patientId: string }>();
  const { data, isLoading } = useCarePlanDraft(params.patientId);
  const { onSave, isSaving } = useOnSave(params.patientId, noteId);
  const hasDraft = !!data?.carePlan;

  const { data: patient, isLoading: isLoadingPatient } = usePatientDetails(
    params.patientId,
    true,
  );
  const { isParticipating: isCcmParticipating } = isParticipatingInProgramType(
    patient,
    ProgramType.CCM,
    { checkConsent: true },
  );
  const { isParticipating: isApcmParticipating } = isParticipatingInProgramType(
    patient,
    ProgramType.APCM,
    { checkConsent: true },
  );
  const isParticipatingInProgramWithCarePlan =
    isCcmParticipating || isApcmParticipating;

  const isPatientEnrolled =
    patient?.patient?.status === PatientStatusEnum.ENROLLED;

  function onPress() {
    const base = `${url}/care-plan`;

    if (hasDraft) {
      history.push(`${base}/${grpcNameToId(data?.name ?? '')}`);
    } else {
      // Create a new care plan and redirect to draft
      onSave(
        {},
        {
          onSuccess() {
            history.push(`${base}/draft`);
          },
        },
      );
    }
  }

  if (isLoading || isLoadingPatient) {
    return <LoadingPlaceholder isLoading />;
  }

  // In this case, there is a draft Care Plan associated with a previously published
  // encounter and so we want to warn the user
  if (hasDraft && data.noteId !== noteId) {
    return (
      <CarePlanAlertBanner
        title={
          <FormattedMessage defaultMessage="Please fix the following errors" />
        }
      >
        <FormattedMessage
          defaultMessage="The <PlanLink>Care Plan draft</PlanLink> is associated with another care plan encounter. Complete the current care plan draft to create a new care plan."
          values={{
            PlanLink: (text: string) => (
              <Link.Routed
                to={`${url}/care-plan/${grpcNameToId(data?.name ?? '')}`}
              >
                {text}
              </Link.Routed>
            ),
          }}
        />
        <div className={divider} />
        <FormattedMessage
          defaultMessage="If the care plan draft should be re-associated with the current care plan encounter (i.e. prior encounter was a no-show) please click <ReassociateButton>here.</ReassociateButton>"
          values={{
            ReassociateButton: (text: string) => (
              <Button
                variant="tertiary"
                size="small"
                onPress={() => setShowReassignModal(true)}
              >
                {text}
              </Button>
            ),
          }}
        />
        <ReassociateCarePlanModal
          isOpen={showReassignModal}
          onClose={() => setShowReassignModal(false)}
          onConfirm={() => {
            if (data?.carePlan) {
              onSave(marshalCarePlanToFormData(data.carePlan), {
                onSuccess() {
                  setShowReassignModal(false);
                  toaster.success(
                    intl.formatMessage({
                      defaultMessage: 'Reassociated care plan draft',
                    }),
                  );
                },
              });
            }
          }}
        />
      </CarePlanAlertBanner>
    );
  }

  return (
    <div>
      <Tooltip
        isDisabled={
          (isPatientEnrolled && isParticipatingInProgramWithCarePlan) ||
          hasDraft
        }
        content={
          <FormattedMessage defaultMessage="Patient must be enrolled in either CCM (with conditions) or APCM, and have provided program consent." />
        }
      >
        <Button
          onPress={onPress}
          isProcessing={isSaving}
          isDisabled={
            !(isParticipatingInProgramWithCarePlan && isPatientEnrolled)
          }
        >
          {hasDraft ? (
            <FormattedMessage defaultMessage="View Care Plan" />
          ) : (
            <FormattedMessage defaultMessage="Create Care Plan" />
          )}
        </Button>
      </Tooltip>
    </div>
  );
}
