import type { IntlShape } from 'react-intl';

import { TITRATION_OUTREACH_SECTIONS } from '@/pages/patients/PatientProfile/CNNotesSidebarPanel/CNWizardForm/sections/metadata';
import type { GoalDiscussFormFields } from '@/pages/patients/PatientProfile/CNNotesSidebarPanel/CNWizardForm/sections/regularVisit/actionPlan/PrevGoalDiscuss/formConfig';
import type { GoalProgressFormFields } from '@/pages/patients/PatientProfile/CNNotesSidebarPanel/CNWizardForm/sections/regularVisit/actionPlan/PrevGoalProgress/formConfig';
import type { EducationModulesReviewFormFields } from '@/pages/patients/PatientProfile/CNNotesSidebarPanel/CNWizardForm/sections/shared/EducationModulesReview';
import type { SetActionStepsFields } from '@/pages/patients/PatientProfile/CNNotesSidebarPanel/CNWizardForm/sections/shared/SetActionSteps';
import { type PatientGoalFormFields } from '@/pages/patients/PatientProfile/CNNotesSidebarPanel/CNWizardForm/sections/shared/setPatientGoal';
import { TypeOfEncounter } from '@/pages/patients/patientDetails/ui/Notes/Notes.types';
import type { SectionStepsState } from '@/shared/common/Wizard/state';
import { getWizardStepValues } from '@/shared/common/Wizard/state';

import { cnActionPlan } from '../../shared/cnActionPlan';

export function titrationOutreachActionPlan(
  data: SectionStepsState,
  intl: IntlShape,
) {
  const patientGoalData = getWizardStepValues(
    data,
    TITRATION_OUTREACH_SECTIONS,
    '/action-plan',
    '/set-goal',
  )<PatientGoalFormFields>();
  const educationModuleData = getWizardStepValues(
    data,
    TITRATION_OUTREACH_SECTIONS,
    '/action-plan',
    '/education-modules-review',
  )<EducationModulesReviewFormFields>();
  const actionStepsData = getWizardStepValues(
    data,
    TITRATION_OUTREACH_SECTIONS,
    '/action-plan',
    '/set-action-steps',
  )<SetActionStepsFields>();

  const goalProgressData = getWizardStepValues(
    data,
    TITRATION_OUTREACH_SECTIONS,
    '/action-plan',
    '/prev-goal-progress',
  )<GoalProgressFormFields>();

  const goalDiscussionData = getWizardStepValues(
    data,
    TITRATION_OUTREACH_SECTIONS,
    '/action-plan',
    '/prev-goal-progress/discuss',
  )<GoalDiscussFormFields>();

  return cnActionPlan({
    intl,
    visitType: TypeOfEncounter.CN_VISIT,
    patientGoalData,
    educationModuleData,
    actionStepsData,
    goalProgressData,
    goalDiscussionData,
  });
}
