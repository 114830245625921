import { TITRATION_OUTREACH_SECTIONS } from '@/pages/patients/PatientProfile/CNNotesSidebarPanel/CNWizardForm/sections/titrationOutreachVisit';
import type { TitrationFormFields } from '@/pages/patients/PatientProfile/CNNotesSidebarPanel/CNWizardForm/sections/titrationOutreachVisit/titration/formConfig';
import type { SectionStepsState } from '@/shared/common/Wizard/state';
import { getWizardStepValues } from '@/shared/common/Wizard/state';

import { MarkdownBuilder } from '../../../engine/MarkdownBuilder';

export function titrationOutreachPharmacy(data: SectionStepsState) {
  const titrationData = getWizardStepValues(
    data,
    TITRATION_OUTREACH_SECTIONS,
    '/titration',
    '/index',
  )<TitrationFormFields>();

  if (!titrationData?.pharmacy) {
    return '';
  }

  const md = new MarkdownBuilder();
  md.p(`Pharmacy confirmed to be: ${titrationData.pharmacy}`);

  return md.toString();
}
