import { FormattedMessage } from 'react-intl';

import { CommonInfoField, PLACEHOLDER } from '@/shared/common/CommonInfoField';
import type { Hospital } from '@/shared/generated/grpcGateway/hospital.pb';
import {
  type PatientDetails,
  ProgramType,
} from '@/shared/generated/grpcGateway/pms.pb';
import { isParticipatingInProgramType } from '@/shared/patient/conditions.utils';
import {
  displayGenderV2,
  displayGoogleDateAge,
  displayGoogleDateDOB,
} from '@/shared/patient/patientInfoUtils';

import { MrnsInfoField } from '../MrnsInfoField';
import { parseEhrInfos } from '../utils/ehrInfo.utils';
import { formatConditions } from '../utils/formatting.utils';
import {
  patientInfoFieldsContainer,
  patientInfoSection,
} from './BasicInfoFields.css';
import { ConditionInfoField } from './ConditionInfoField';
import { GenderAgeDobCompoundInfoField } from './GenderAgeDobCompoundInfoField';
import { LocationsInfoField } from './LocationsInfoField';
import { PatientMedsInfoTag } from './PatientMedsInfoTag';

export function BasicPatientInfoFields({
  patientDetails,
  hospitals,
  showMedManagement,
}: {
  patientDetails: PatientDetails;
  showMedManagement?: boolean;
  hospitals?: Hospital[];
}) {
  const patient = patientDetails?.patient;
  const { allEhrInformation } = patientDetails;
  const { mrns } = parseEhrInfos(allEhrInformation);
  const { conditions: rpmConditions } = isParticipatingInProgramType(
    patientDetails,
    ProgramType.RPM,
  );

  return showMedManagement ? (
    <div className={patientInfoSection}>
      <div className={patientInfoFieldsContainer}>
        <CommonInfoField
          label={<FormattedMessage defaultMessage="Age" />}
          text={patient?.dob ? displayGoogleDateAge(patient.dob) : PLACEHOLDER}
        />
        <CommonInfoField
          label={<FormattedMessage defaultMessage="Gender" />}
          text={patient?.gender ? displayGenderV2(patient.gender) : PLACEHOLDER}
        />
        <ConditionInfoField conditions={rpmConditions} />
        <LocationsInfoField hospitals={hospitals} />
        <MrnsInfoField mrns={mrns} />
        <CommonInfoField
          label={<FormattedMessage defaultMessage="DoB" />}
          text={patient?.dob ? displayGoogleDateDOB(patient.dob) : PLACEHOLDER}
        />
      </div>
      <PatientMedsInfoTag patient={patientDetails} />
    </div>
  ) : (
    <div className={patientInfoFieldsContainer}>
      <GenderAgeDobCompoundInfoField patientDetails={patientDetails} />
      <MrnsInfoField mrns={mrns} />
      <LocationsInfoField hospitals={hospitals} />
      <CommonInfoField
        label={<FormattedMessage defaultMessage="Condition" />}
        text={formatConditions(rpmConditions)}
      />
      <ConditionInfoField conditions={rpmConditions} />
    </div>
  );
}
