import type { IntlShape } from 'react-intl';

import { getPatientTitrationRejectionReasonsI18n } from '@/components/AsyncTitration/hooks';
import { getRejectionReasonI18n } from '@/components/AsyncTitration/hooks/useRejectionReasonI18n';
import type { EndCallFormFields } from '@/pages/patients/PatientProfile/CNNotesSidebarPanel/CNWizardForm/sections/shared/EndCall/formConfig';
import type { IntroFormFields } from '@/pages/patients/PatientProfile/CNNotesSidebarPanel/CNWizardForm/sections/shared/IntroPatientAttendance';
import type { MedReviewFormFields } from '@/pages/patients/PatientProfile/CNNotesSidebarPanel/CNWizardForm/sections/shared/MedReview';
import { TITRATION_OUTREACH_SECTIONS } from '@/pages/patients/PatientProfile/CNNotesSidebarPanel/CNWizardForm/sections/titrationOutreachVisit';
import { getAsyncTitrationNoteBody } from '@/pages/patients/patientDetails/ui/Notes/NotePreview/hooks/useGetAsyncTitrationNoteBody';
import { TypeOfEncounter } from '@/pages/patients/patientDetails/ui/Notes/Notes.types';
import type { SectionStepsState } from '@/shared/common/Wizard/state';
import { getWizardStepValues } from '@/shared/common/Wizard/state';
import type {
  PatientMedications,
  ReferenceMedication,
} from '@/shared/generated/grpcGateway/medication.pb';
import type { PatientContextVitals } from '@/shared/generated/grpcGateway/telemetry.pb';

import { cnMedicationReview } from '../../shared/cnMedicationReview';
import { sectionHasData } from '../../util';

export function titrationOutreachTitrationReview(
  intl: IntlShape,
  data: SectionStepsState,
  noteId: number,
  patientMeds: PatientMedications,
  patientVitals: PatientContextVitals,
  referenceMeds: ReferenceMedication[],
) {
  const introData = getWizardStepValues(
    data,
    TITRATION_OUTREACH_SECTIONS,
    '/intro',
    '/patient-attendance',
  )<IntroFormFields>();
  const medReviewData = getWizardStepValues(
    data,
    TITRATION_OUTREACH_SECTIONS,
    '/med-review',
    '/index',
  )<MedReviewFormFields>();
  const endCallData = getWizardStepValues(
    data,
    TITRATION_OUTREACH_SECTIONS,
    '/end-call',
    '/index',
  )<EndCallFormFields>();

  const isNoShow = introData?.patientAttendance === 'false';
  const isEndEarly = sectionHasData(endCallData);
  const rejectionReasonsI18n = getRejectionReasonI18n(intl);
  const patientRejectionReasonsI18n =
    getPatientTitrationRejectionReasonsI18n(intl);
  const medReviewBody = cnMedicationReview({
    noteId,
    meds: patientMeds,
    medReviewData,
  });

  return (
    getAsyncTitrationNoteBody(
      intl,
      noteId,
      rejectionReasonsI18n,
      patientRejectionReasonsI18n,
      patientMeds,
      patientVitals,
      referenceMeds,
      TypeOfEncounter.CN_TITRATION_OUTREACH,
      isNoShow,
      isEndEarly,
      medReviewBody,
    ) ?? ''
  );
}
