import { FormattedMessage } from 'react-intl';

import { TrendDown } from '@/shared/assets/svgs';

import {
  trendDownIcon,
  vitalDataMissing,
  vitalGoalText,
  vitalValueGoalMet,
  vitalValueGoalUnmet,
  vitalValueHeader,
  vitalsContainer,
} from '../ClinicalGoalReachedForm.css';
import { useVitalAvgAndGoals } from '../useVitalAvgAndGoals';
import { InsufficientDataMessage } from './InsufficientDataMessage';
import { isAtBpGoal } from './isAtGoal';

export function BpSummary() {
  const { isLoading, vitalValuesAndGoals } = useVitalAvgAndGoals();
  if (isLoading || !vitalValuesAndGoals) {
    return null;
  }

  const { systolicGoal, diastolicGoal, systolicAvg30d, diastolicAvg30d } =
    vitalValuesAndGoals;
  const { isDataMissing, isGoalMet } = isAtBpGoal(vitalValuesAndGoals);

  let vitalValueTextCls = vitalDataMissing;
  if (!isDataMissing) {
    vitalValueTextCls = isGoalMet ? vitalValueGoalMet : vitalValueGoalUnmet;
  }

  return (
    <div>
      <div className={vitalsContainer}>
        <TrendDown className={trendDownIcon} />
        <div>
          <div>
            <span className={vitalValueHeader}>
              <FormattedMessage defaultMessage="ACTUAL: " />
            </span>
            <span className={vitalValueTextCls}>
              {systolicAvg30d && diastolicAvg30d ? (
                <FormattedMessage
                  defaultMessage="{systolicAvg30d}/{diastolicAvg30d} blood pressure (30-day avg)"
                  values={{ systolicAvg30d, diastolicAvg30d }}
                />
              ) : (
                <InsufficientDataMessage />
              )}
            </span>
          </div>
          <div className={vitalGoalText}>
            <FormattedMessage
              defaultMessage="GOAL: {systolicGoal}/{diastolicGoal} blood pressure (30-day avg)"
              values={{
                systolicGoal: systolicGoal || 'N/A ',
                diastolicGoal: diastolicGoal || ' N/A',
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
