import { useIntl } from 'react-intl';

import { flexGrow } from '@/shared/jsStyle/flex.css';
import { Select } from '@/shared/tempo/@labs/molecule/Select';

import { useFiltersQuery } from '../../hooks/useFiltersQuery.hook';
import { ConditionFilter } from '../../types/filter.types';

export function ConditionFilterDropdown() {
  const intl = useIntl();
  const { conditionFilter, setConditionFilter } = useFiltersQuery();

  return (
    <Select
      className={flexGrow[1]}
      selectedKey={conditionFilter}
      aria-label={intl.formatMessage({ defaultMessage: 'Condition' })}
      onSelectionChange={(condition) =>
        setConditionFilter(condition as ConditionFilter)
      }
    >
      <Select.Option key={ConditionFilter.AllConditions}>
        {intl.formatMessage({ defaultMessage: 'All conditions' })}
      </Select.Option>
      <Select.Option key={ConditionFilter.CHF}>
        {intl.formatMessage({ defaultMessage: 'Congestive Heart Failure' })}
      </Select.Option>
      <Select.Option key={ConditionFilter.Hypertension}>
        {intl.formatMessage({ defaultMessage: 'Hypertension' })}
      </Select.Option>
      <Select.Option key={ConditionFilter.T2D}>
        {intl.formatMessage({ defaultMessage: 'Type 2 Diabetes' })}
      </Select.Option>
    </Select>
  );
}
