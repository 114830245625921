import sortBy from 'lodash/sortBy';

import { AsyncTitrationMedicationWasNotTitratedReason as Reason } from '@/shared/generated/grpcGateway/medication.pb';

import { useRejectionReasonI18n } from './useRejectionReasonI18n';

export function useNotTitratingReasons() {
  const reasonI18n = useRejectionReasonI18n();
  const reasons = [
    {
      key: Reason.RECENT_MEDICATION_CHANGE_BY_EXTERNAL_PROVIDER,
      label: reasonI18n[Reason.RECENT_MEDICATION_CHANGE_BY_EXTERNAL_PROVIDER],
    },
    {
      key: Reason.MANAGED_BY_EXTERNAL_PROVIDER,
      label: reasonI18n[Reason.MANAGED_BY_EXTERNAL_PROVIDER],
    },
    {
      key: Reason.AGE_OR_CONDITION,
      label: reasonI18n[Reason.AGE_OR_CONDITION],
    },
    {
      key: Reason.SIDE_EFFECT_AT_HIGHER_DOSE,
      label: reasonI18n[Reason.SIDE_EFFECT_AT_HIGHER_DOSE],
    },
    {
      key: Reason.NEW_PRESCRIPTION_REQUIRED,
      label: reasonI18n[Reason.NEW_PRESCRIPTION_REQUIRED],
    },
    {
      key: Reason.MEDICATION_NOT_ACCURATE,
      label: reasonI18n[Reason.MEDICATION_NOT_ACCURATE],
    },
    {
      key: Reason.HEART_RATE_TOO_LOW,
      label: reasonI18n[Reason.HEART_RATE_TOO_LOW],
    },
    {
      key: Reason.ADJUSTED_ANOTHER_MEDICATION,
      label: reasonI18n[Reason.ADJUSTED_ANOTHER_MEDICATION],
    },
    { key: Reason.NEED_LABS, label: reasonI18n[Reason.NEED_LABS] },
    {
      key: Reason.ACUTE_MEDICAL_ISSUE,
      label: reasonI18n[Reason.ACUTE_MEDICAL_ISSUE],
    },
    {
      key: Reason.PROVIDER_GOAL_DIFFERS,
      label: reasonI18n[Reason.PROVIDER_GOAL_DIFFERS],
    },
    {
      key: Reason.MEDICATION_VERIFICATION,
      label: reasonI18n[Reason.MEDICATION_VERIFICATION],
    },
    {
      key: Reason.ABNORMAL_LABS,
      label: reasonI18n[Reason.ABNORMAL_LABS],
    },
    {
      key: Reason.LABILE_BLOOD_GLUCOSE,
      label: reasonI18n[Reason.LABILE_BLOOD_GLUCOSE],
    },
    {
      key: Reason.HYPOTENSION,
      label: reasonI18n[Reason.HYPOTENSION],
    },
    {
      key: Reason.HYPOGLYCEMIC_EPISODES,
      label: reasonI18n[Reason.HYPOGLYCEMIC_EPISODES],
    },
  ];

  return sortBy(reasons, 'label');
}
