import { type ReactNode, useCallback, useState } from 'react';

import { useFlatPages } from '@/reactQuery';
import type {
  TaskState,
  TaskType,
} from '@/shared/generated/grpcGateway/task.pb';
import {
  DEFAULT_ORDER_BY,
  DEFAULT_ORDER_BY_WITH_SCHEDULED,
  type TaskQuery,
  useTaskTypesInfinite,
} from '@/shared/hooks/queries/tasks.queries';

import { useFlags } from '../../../shared/hooks';
import { startOfTodayUTC } from '../../../shared/tasking/utils';
import { TabKey } from '../TabKey';
import type {
  HealthSystemsSelection,
  TeamTabAssignee,
} from './taskFilterContext';
import { TaskFilterContextProvider } from './taskFilterContext';

type Props = {
  children: ReactNode;
};

export function ConfiguredTaskFilterContext({ children }: Props) {
  const { enableScheduledTasks } = useFlags();
  const [orderBy, setOrderBy] = useState(
    enableScheduledTasks ? DEFAULT_ORDER_BY_WITH_SCHEDULED : DEFAULT_ORDER_BY,
  );
  const [taskTypes, setTaskTypes] = useState<TaskType[]>([]);
  const [teamTabAssignee, setTeamTabAssignee] =
    useState<TeamTabAssignee>('unassigned');
  const [healthSystems, setHealthSystems] =
    useState<HealthSystemsSelection>('all');
  const allTaskTypes = useFlatPages<TaskType, 'data'>(useTaskTypesInfinite({}));

  const getFilter = useCallback(
    (tab?: TabKey, state?: TaskState): TaskQuery => {
      const filter = {
        state,
        types: taskTypes,
        healthSystemIds: healthSystems === 'all' ? undefined : healthSystems,
        scheduledTimeThreshold: enableScheduledTasks
          ? startOfTodayUTC()
          : undefined,
      };

      if (tab === TabKey.Team) {
        return {
          ...filter,
          hasAssignee: teamTabAssignee !== 'unassigned',
          // if we have "All" selected on the team tab, we want to
          // limit to relevant task types for the user
          ...(filter.types.length === 0 && {
            types: allTaskTypes.filter((type) => type.isVisible),
          }),
        };
      }

      return filter;
    },
    [
      allTaskTypes,
      healthSystems,
      taskTypes,
      teamTabAssignee,
      enableScheduledTasks,
    ],
  );

  return (
    <TaskFilterContextProvider
      value={{
        orderBy,
        onOrderByChange: setOrderBy,
        taskTypes,
        onTaskTypesChange: setTaskTypes,
        teamTabAssignee,
        onTeamTabAssigneeChange: setTeamTabAssignee,
        healthSystems,
        onHealthSystemsChange: setHealthSystems,
        getFilter,
      }}
    >
      {children}
    </TaskFilterContextProvider>
  );
}
