import cx from 'classnames';
import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';

import {
  FieldGroup,
  Script,
} from '@/pages/patients/PatientProfile/CNNotesSidebarPanel/shared';
import { useRecentNoteFormData } from '@/pages/patients/PatientProfile/CNNotesSidebarPanel/shared/useRecentNoteFormData';
import { useTimeEstimate } from '@/pages/patients/PatientProfile/CNNotesSidebarPanel/shared/useTimeEstimate';
import { AlertTriangle } from '@/shared/assets/svgs';
import { RatingScaleField } from '@/shared/common/RatingScaleField';
import { Wizard } from '@/shared/common/Wizard';
import {
  useWizardFormFromConfig,
  useWizardStateContext,
} from '@/shared/common/Wizard/state';
import { flexContainer } from '@/shared/jsStyle/flex.css';
import { gap } from '@/shared/jsStyle/gap.css';
import { Button } from '@/shared/tempo/atom/Button';

import { useShowVitalsEducation } from '../../../../sectionUtils';
import { initVisitPath } from '../../../initialVisit/paths';
import { TITRATION_OUTREACH_SECTIONS } from '../../../metadata';
import { GoalAndStepsBlock } from '../../../shared/ReviewActionSteps/GoalAndStepsBlock';
import type { SetActionStepsFields } from '../../../shared/SetActionSteps';
import {
  type PatientGoalFormFields,
  usePatientGoalI18n,
} from '../../../shared/setPatientGoal';
import { titrationOutreachPath } from '../../paths';
import { scaleList, skipAheadWarning } from './PrevGoalProgress.css';
import {
  type GoalProgressFormFields,
  getGoalProgressFormConfig,
} from './formConfig';

export function PrevGoalProgress() {
  const intl = useIntl();
  const history = useHistory();

  const form = useWizardFormFromConfig<GoalProgressFormFields>(
    titrationOutreachPath('/action-plan', '/prev-goal-progress'),
    getGoalProgressFormConfig(intl),
  );

  const { isLoading: isLoadingPrevGoal, data: prevGoalData } =
    useRecentNoteFormData<PatientGoalFormFields>([
      titrationOutreachPath('/action-plan', '/set-goal'),
      initVisitPath('/action-plan', '/set-goal'),
    ]);
  const { isLoading: isLoadingPrevActionSteps, data: prevActionStepsData } =
    useRecentNoteFormData<SetActionStepsFields>([
      titrationOutreachPath('/action-plan', '/set-action-steps'),
      initVisitPath('/action-plan', '/set-action-steps'),
    ]);

  const patientGoalI18n = usePatientGoalI18n();
  const prevPatientGoal = prevGoalData?.patientGoal;
  const prevPatientGoalText = prevPatientGoal
    ? patientGoalI18n[prevPatientGoal]
    : null;

  const prevActionSteps = prevActionStepsData?.actionSteps || [];
  const showVitalsEducation = useShowVitalsEducation();
  const { skipSectionStep } = useWizardStateContext();

  const onSkip = () => {
    skipSectionStep(
      titrationOutreachPath('/action-plan', '/prev-goal-progress'),
    );
    skipSectionStep(
      titrationOutreachPath('/action-plan', '/prev-goal-progress/discuss'),
    );
    skipSectionStep(titrationOutreachPath('/action-plan', '/no-prev-goal'));
    skipSectionStep(titrationOutreachPath('/action-plan', '/set-goal'));
    skipSectionStep(
      titrationOutreachPath('/action-plan', '/education-modules-review'),
    );
    skipSectionStep(titrationOutreachPath('/action-plan', '/set-action-steps'));
    skipSectionStep(
      titrationOutreachPath('/action-plan', '/review-action-steps'),
    );

    history.push(titrationOutreachPath('/scheduling', '/index'));
  };

  return (
    <Wizard.Step
      form={form}
      isLoading={isLoadingPrevGoal || isLoadingPrevActionSteps}
      sections={TITRATION_OUTREACH_SECTIONS}
      title={intl.formatMessage({ defaultMessage: 'Action plan' })}
      subtitle={useTimeEstimate(
        intl.formatMessage({ defaultMessage: '1-3 min' }),
      )}
      onNext={() =>
        history.push(
          titrationOutreachPath('/action-plan', '/prev-goal-progress/discuss'),
        )
      }
    >
      <div className={cx(flexContainer.column, gap.M)}>
        <div className={skipAheadWarning.container}>
          <AlertTriangle className={skipAheadWarning.icon} />
          <div className={skipAheadWarning.text}>
            <FormattedMessage
              defaultMessage="If you reach this point and it's been 13 minutes or more, please move to scheduling. <a>Skip step</a>"
              values={{
                a: (text: string) => (
                  <Button variant="tertiary" size="small" onPress={onSkip}>
                    {text}
                  </Button>
                ),
              }}
            />
          </div>
        </div>
        <FieldGroup>
          {showVitalsEducation ? (
            <Script className={cx(flexContainer.column, gap.M)}>
              <FormattedMessage defaultMessage="Our next step in today's visit is to review the action plan you created in our last appointment. Your previous goal and action steps were:" />
            </Script>
          ) : (
            <>
              <Script className={cx(flexContainer.column, gap.M)}>
                <FormattedMessage defaultMessage="Thanks for reviewing all of that information with me. It helps to know if anything has changed since the last time we spoke." />
              </Script>
              <Script className={cx(flexContainer.column, gap.M)}>
                <FormattedMessage defaultMessage="Our next step in today's visit is to review the action plan you set out in the last appointment. Your previous goal and action steps were:" />
              </Script>
            </>
          )}
        </FieldGroup>
        <GoalAndStepsBlock
          actionSteps={prevActionSteps}
          patientGoalText={prevPatientGoalText}
        />
        <Script className={cx(flexContainer.column, gap.M)}>
          {showVitalsEducation ? (
            <FormattedMessage defaultMessage="Let's talk about how it went following your action steps since your last visit." />
          ) : (
            <FormattedMessage defaultMessage="Let's review your progress towards this goal." />
          )}
        </Script>
        <Script className={cx(flexContainer.column, gap.M)}>
          <FormattedMessage defaultMessage="On a scale from 1-5, how successful do you feel you were at completing your action steps routinely? One being not successful at all, and five being successful daily." />
        </Script>
        <RatingScaleField
          name="goalProgressRating"
          label=""
          size={12}
          aria-label={intl.formatMessage({
            defaultMessage: 'Patient goal progress rating',
          })}
          max={5}
          scale={{
            min: intl.formatMessage({
              defaultMessage: 'Not successful at all',
            }),
            max: intl.formatMessage({
              defaultMessage: 'Successful daily',
            }),
          }}
        />
        <ul className={scaleList}>
          <li>
            <FormattedMessage defaultMessage="If 1-2: reassure that this is common occurrence" />
          </li>
          <li>
            <FormattedMessage defaultMessage="If 3-4: recognize partial success" />
          </li>
          <li>
            <FormattedMessage defaultMessage="If 5: recognize stellar success" />
          </li>
        </ul>
      </div>
    </Wizard.Step>
  );
}
