import merge from 'lodash/merge';
import { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';

import { useRouteParamPatientDetails } from '@/shared/hooks/queries';
import { bodyDefault } from '@/shared/jsStyle/typography.css';
import { getDeprecatedRpmConditionsFromProgramAndStatus } from '@/shared/patient/deprecated/conditions.utils';

import type { SymptomsInputs } from '../../NotePreview/formatSymptomsInputs.util';
import type { EncounterModuleInstance } from '../../Notes.types';
import { EncounterModuleId } from '../../Notes.types';
import { useEncounterModule } from '../../note.queries';
import { NotesSchemaDrivenForm } from '../@deprecated/NotesSchemaDrivenForm';
import { formContainer } from './SymptomsForm.css';
import { getInitialSymptomsData } from './getInitialSymptomsData';
import { useUiSchema } from './symptomsUiSchema';

export function SymptomsForm({
  initialValues,
  onChange,
  shouldShowValidation,
}: {
  initialValues?: SymptomsInputs;
  onChange: (encounterModuleInstance: EncounterModuleInstance) => void;
  shouldShowValidation: boolean;
}) {
  const { encounterModule, isLoading } = useEncounterModule(
    EncounterModuleId.Symptoms,
  );
  const { uiSchema, portalContainers } = useUiSchema();
  const { data: patientDetails } = useRouteParamPatientDetails();
  const conditions = getDeprecatedRpmConditionsFromProgramAndStatus(
    patientDetails?.programs,
    patientDetails?.status,
  );

  // Memoize so that SchemaDrivenForm can run effect when initialFormData changes
  const initialFormData = useMemo(() => {
    if (!encounterModule?.schema) return initialValues;

    return getInitialSymptomsData(
      encounterModule.schema,
      conditions,
      initialValues,
    );
  }, [conditions, initialValues, encounterModule?.schema]);

  if (!conditions?.length) {
    return (
      <div className={bodyDefault}>
        <FormattedMessage defaultMessage="The patient has no conditions set for which symptoms can be displayed." />
      </div>
    );
  }

  return (
    <>
      {!isLoading && encounterModule && (
        <div className={formContainer}>
          <NotesSchemaDrivenForm
            shouldShowValidation={shouldShowValidation}
            shouldLiveValidate={
              // See TypeOfEncounterForm.tsx for why we are setting this to false
              false
            }
            initialFormData={initialFormData}
            schema={encounterModule.schema}
            uiSchema={merge(uiSchema, SYMPTOM_UI_SCHEMAS_FOR_LABELS)}
            onChange={(inputs: SymptomsInputs) => {
              onChange({
                encounter_module_id: encounterModule.id,
                inputs,
              });
            }}
          />
          {portalContainers}
        </div>
      )}
    </>
  );
}

// eslint-disable-next-line react-refresh/only-export-components
export const SYMPTOMS_TITLE = <FormattedMessage defaultMessage="Symptoms" />;

// eslint-disable-next-line react-refresh/only-export-components
export const SYMPTOMS_CAPITALIZED_FORMATTED_MESSAGES = {
  other: <FormattedMessage defaultMessage="Other" />,
  confusion: <FormattedMessage defaultMessage="Confusion" />,
  nausea: <FormattedMessage defaultMessage="Nausea" />,
  dysuria: <FormattedMessage defaultMessage="Dysuria" />,
  fatigue: <FormattedMessage defaultMessage="Fatigue" />,
  syncope: <FormattedMessage defaultMessage="Syncope" />,
  anorexia: <FormattedMessage defaultMessage="Anorexia" />,
  diarrhea: <FormattedMessage defaultMessage="Diarrhea" />,
  erythema: <FormattedMessage defaultMessage="Erythema" />,
  headache: <FormattedMessage defaultMessage="Headache" />,
  polyuria: <FormattedMessage defaultMessage="Frequent urination" />,
  vomiting: <FormattedMessage defaultMessage="Vomiting" />,
  bone_pain: <FormattedMessage defaultMessage="Bone pain" />,
  dizziness: <FormattedMessage defaultMessage="Dizziness" />,
  hematuria: <FormattedMessage defaultMessage="Hematuria" />,
  orthopnea: <FormattedMessage defaultMessage="Orthopnea" />,
  tachypnea: <FormattedMessage defaultMessage="Tachypnea" />,
  chest_pain: <FormattedMessage defaultMessage="Chest pain" />,
  joint_pain: <FormattedMessage defaultMessage="Joint pain" />,
  polydypsia: <FormattedMessage defaultMessage="Increased thirst" />,
  presyncope: <FormattedMessage defaultMessage="Presyncope" />,
  abdominal_bloating: <FormattedMessage defaultMessage="Abdominal bloating" />,
  abdominal_pain: <FormattedMessage defaultMessage="Abdominal pain" />,
  focal_weakness: <FormattedMessage defaultMessage="Focal weakness" />,
  slurred_speech: <FormattedMessage defaultMessage="Slurred speech" />,
  lightheadedness: <FormattedMessage defaultMessage="Lightheadedness" />,
  excessive_hunger: <FormattedMessage defaultMessage="Excessive hunger" />,
  fever_or_malaise: <FormattedMessage defaultMessage="Fever or malaise" />,
  profuse_sweating: <FormattedMessage defaultMessage="Sweating" />,
  changes_in_vision: <FormattedMessage defaultMessage="Changes in vision" />,
  decreased_appetite: <FormattedMessage defaultMessage="Decreased appetite" />,
  incomplete_voiding: <FormattedMessage defaultMessage="Incomplete voiding" />,
  pain_or_tenderness: <FormattedMessage defaultMessage="Pain or tenderness" />,
  shortness_of_breath: (
    <FormattedMessage defaultMessage="Shortness of breath" />
  ),
  altered_mental_status: (
    <FormattedMessage defaultMessage="Altered mental status" />
  ),
  lower_extremity_edema: (
    <FormattedMessage defaultMessage="Lower extremity edema" />
  ),
  numbness_of_extremities: (
    <FormattedMessage defaultMessage="Numbness of extremities" />
  ),
  injection_site_reactions: (
    <FormattedMessage defaultMessage="Injection site reactions" />
  ),
  swelling_genital_or_perineal: (
    <FormattedMessage defaultMessage="Swelling genital or perineal" />
  ),
  paraoxysmal_nocturnal_dyspnea: (
    <FormattedMessage defaultMessage="Paroxysmal nocturnal dyspnea" />
  ),
};

// eslint-disable-next-line react-refresh/only-export-components
export const SYMPTOMS_FORMATTED_MESSAGES = {
  other: <FormattedMessage defaultMessage="other" />,
  confusion: <FormattedMessage defaultMessage="confusion" />,
  nausea: <FormattedMessage defaultMessage="nausea" />,
  dysuria: <FormattedMessage defaultMessage="dysuria" />,
  fatigue: <FormattedMessage defaultMessage="fatigue" />,
  syncope: <FormattedMessage defaultMessage="syncope" />,
  anorexia: <FormattedMessage defaultMessage="anorexia" />,
  diarrhea: <FormattedMessage defaultMessage="diarrhea" />,
  erythema: <FormattedMessage defaultMessage="erythema" />,
  headache: <FormattedMessage defaultMessage="headache" />,
  polyuria: <FormattedMessage defaultMessage="frequent urination" />,
  vomiting: <FormattedMessage defaultMessage="vomiting" />,
  bone_pain: <FormattedMessage defaultMessage="bone pain" />,
  dizziness: <FormattedMessage defaultMessage="dizziness" />,
  hematuria: <FormattedMessage defaultMessage="hematuria" />,
  orthopnea: <FormattedMessage defaultMessage="orthopnea" />,
  tachypnea: <FormattedMessage defaultMessage="tachypnea" />,
  chest_pain: <FormattedMessage defaultMessage="chest pain" />,
  joint_pain: <FormattedMessage defaultMessage="joint pain" />,
  polydypsia: <FormattedMessage defaultMessage="increased thirst" />,
  presyncope: <FormattedMessage defaultMessage="Presyncope" />,
  abdominal_pain: <FormattedMessage defaultMessage="abdominal pain" />,
  abdominal_bloating: <FormattedMessage defaultMessage="abdominal bloating" />,
  focal_weakness: <FormattedMessage defaultMessage="focal weakness" />,
  slurred_speech: <FormattedMessage defaultMessage="slurred speech" />,
  lightheadedness: <FormattedMessage defaultMessage="lightheadedness" />,
  excessive_hunger: <FormattedMessage defaultMessage="excessive hunger" />,
  fever_or_malaise: <FormattedMessage defaultMessage="fever or malaise" />,
  profuse_sweating: <FormattedMessage defaultMessage="sweating" />,
  changes_in_vision: <FormattedMessage defaultMessage="changes in vision" />,
  decreased_appetite: <FormattedMessage defaultMessage="decreased appetite" />,
  incomplete_voiding: <FormattedMessage defaultMessage="incomplete voiding" />,
  pain_or_tenderness: <FormattedMessage defaultMessage="pain or tenderness" />,
  shortness_of_breath: (
    <FormattedMessage defaultMessage="shortness of breath" />
  ),
  altered_mental_status: (
    <FormattedMessage defaultMessage="altered mental status" />
  ),
  lower_extremity_edema: (
    <FormattedMessage defaultMessage="lower extremity edema" />
  ),
  numbness_of_extremities: (
    <FormattedMessage defaultMessage="numbness of extremities" />
  ),
  injection_site_reactions: (
    <FormattedMessage defaultMessage="injection site reactions" />
  ),
  swelling_genital_or_perineal: (
    <FormattedMessage defaultMessage="swelling genital or perineal" />
  ),
  paraoxysmal_nocturnal_dyspnea: (
    <FormattedMessage defaultMessage="paroxysmal nocturnal dyspnea" />
  ),
};

const SYMPTOM_UI_SCHEMAS_FOR_LABELS = Object.fromEntries(
  Object.entries(SYMPTOMS_CAPITALIZED_FORMATTED_MESSAGES).map(
    ([symptomName, formattedMessage]) => [
      symptomName,
      {
        has_symptom: {
          'ui:label': formattedMessage,
        },
        description: {
          'ui:label': formattedMessage,
        },
      },
    ],
  ),
);
