import type { HelperDeclareSpec } from 'handlebars';
import type { IntlShape } from 'react-intl';

import type { SectionStepsState } from '@/shared/common/Wizard/state';
import type { PatientMedications } from '@/shared/generated/grpcGateway/medication.pb';
import type { VitalsEngagement } from '@/shared/hooks/useVitalsEngagement';
import type { RpmCondition } from '@/shared/types/clinicalprofile.types';

import { chfVisitActionPlan } from './chfVisitActionPlan';
import { chfVisitEarlyEndCall } from './chfVisitEarlyEndCall';
import { chfVisitEmergencyVisits } from './chfVisitEmergencyVisits';
import { chfVisitGeneralAssessment } from './chfVisitGeneralAssessment';
import { chfVisitIntro } from './chfVisitIntro';
import { chfVisitMedicationReview } from './chfVisitMedicationReview';
import { chfVisitPatientNoShow } from './chfVisitPatientNoShow';
import { chfVisitProductSupport } from './chfVisitProductSupport';
import { chfVisitSymptoms } from './chfVisitSymptoms';
import { isChfVisitPatientNoShow } from './isChfVisitPatientNoShow';

interface TemplateHelpers extends HelperDeclareSpec {
  chfVisitProductSupport: (
    data: SectionStepsState,
    vitalsEngagement: VitalsEngagement,
  ) => string;
  isChfVisitPatientNoShow: (data: SectionStepsState) => boolean;
  chfVisitPatientNoShow: (data: SectionStepsState, intl: IntlShape) => string;
  chfVisitMedicationReview: (
    data: SectionStepsState,
    noteId: number,
    meds: PatientMedications,
  ) => string;
  chfVisitGeneralAssessment: (data: SectionStepsState) => string;
  chfVisitEarlyEndCall: (data: SectionStepsState, intl: IntlShape) => string;
  chfVisitSymptoms: (
    data: SectionStepsState,
    rpmConditions: RpmCondition[],
    intl: IntlShape,
  ) => string;
  chfVisitActionPlan: (data: SectionStepsState, intl: IntlShape) => string;
  chfVisitEmergencyVisits: (data: SectionStepsState, intl: IntlShape) => string;
  chfVisitIntro: (data: SectionStepsState) => string;
}

/**
 * These helpers can be used from within handlebars templates to
 * format/transform data
 */
export const CHF_VISIT_HELPERS: TemplateHelpers = {
  chfVisitProductSupport,
  isChfVisitPatientNoShow,
  chfVisitPatientNoShow,
  chfVisitGeneralAssessment,
  chfVisitMedicationReview,
  chfVisitEarlyEndCall,
  chfVisitSymptoms,
  chfVisitActionPlan,
  chfVisitEmergencyVisits,
  chfVisitIntro,
};
