/* eslint-disable */
// @ts-nocheck
/*
* This file is a generated Typescript file for GRPC Gateway, DO NOT MODIFY
*/

import * as GoogleProtobufTimestamp from "./google/protobuf/timestamp.pb"
import * as GoogleProtobufWrappers from "./google/protobuf/wrappers.pb"
import * as GoogleTypeDate from "./google/type/date.pb"
import * as fm from "./fetch.pb"

export enum PatientDeviceHealthDeviceDeviceHealth {
  UNKNOWN = "UNKNOWN",
  GREEN = "GREEN",
  YELLOW = "YELLOW",
  RED = "RED",
}

export enum ListPatientVitalsResponseVitalReadingQualifier {
  READING_QUALIFIER_UNSPECIFIED = "READING_QUALIFIER_UNSPECIFIED",
  BEFORE_MEAL = "BEFORE_MEAL",
  AFTER_MEAL = "AFTER_MEAL",
  CONTROL_SOLUTION = "CONTROL_SOLUTION",
  MOCK_TEST = "MOCK_TEST",
  UNKNOWN = "UNKNOWN",
}

export enum ListPatientVitalsResponseVitalType {
  TYPE_UNSPECIFIED = "TYPE_UNSPECIFIED",
  BLOOD_PRESSURE = "BLOOD_PRESSURE",
  BLOOD_GLUCOSE = "BLOOD_GLUCOSE",
  HEART_RATE = "HEART_RATE",
  WEIGHT = "WEIGHT",
}

export type DerivePatientVitalsEngagementRequest = {
  name?: string
  startDate?: GoogleTypeDate.Date
  endDate?: GoogleTypeDate.Date
}

export type DerivePatientVitalsEngagementResponse = {
  datesTakenVitals?: GoogleTypeDate.Date[]
}

export type GetPatientContextVitalsRequest = {
  name?: string
}

export type PatientContextVitalsVitalSigns = {
  bloodPressure?: PatientContextVitalsBloodPressureMetrics
  heartRate?: PatientContextVitalsVitalMetrics
  weight?: PatientContextVitalsVitalMetrics
  bloodGlucose?: PatientContextVitalsVitalMetrics
}

export type PatientContextVitalsBloodPressureMetrics = {
  systolic?: PatientContextVitalsVitalMetrics
  diastolic?: PatientContextVitalsVitalMetrics
}

export type PatientContextVitalsVitalMetrics = {
  avg30d?: GoogleProtobufWrappers.DoubleValue
  avg7d?: GoogleProtobufWrappers.DoubleValue
  median30d?: GoogleProtobufWrappers.DoubleValue
  median7d?: GoogleProtobufWrappers.DoubleValue
  min30d?: GoogleProtobufWrappers.DoubleValue
  max30d?: GoogleProtobufWrappers.DoubleValue
}

export type PatientContextVitals = {
  name?: string
  contextVitals?: PatientContextVitalsVitalSigns
}

export type GetPatientDeviceHealthRequest = {
  name?: string
}

export type PatientDeviceHealthDevice = {
  name?: string
  status?: PatientDeviceHealthDeviceDeviceHealth
  delayMins?: number
  signalStrength?: number
}

export type PatientDeviceHealth = {
  name?: string
  devices?: PatientDeviceHealthDevice[]
}

export type ListPatientVitalsRequest = {
  parent?: string
  pageSize?: number
  pageToken?: string
  filter?: string
  orderBy?: string
}

export type ListPatientVitalsResponseVital = {
  name?: string
  timestamp?: GoogleProtobufTimestamp.Timestamp
  timeZone?: string
  tags?: {[key: string]: ListPatientVitalsResponseThresholds}
  type?: ListPatientVitalsResponseVitalType
  value?: number
  secondaryValue?: GoogleProtobufWrappers.DoubleValue
  readingQualifier?: ListPatientVitalsResponseVitalReadingQualifier
  regular?: GoogleProtobufWrappers.BoolValue
}

export type ListPatientVitalsResponseThresholds = {
  lowP0?: ListPatientVitalsResponseThreshold
  highP0?: ListPatientVitalsResponseThreshold
  lowP1?: ListPatientVitalsResponseThreshold
  highP1?: ListPatientVitalsResponseThreshold
  lowP2?: ListPatientVitalsResponseThreshold
  highP2?: ListPatientVitalsResponseThreshold
  highWeeklyP0?: ListPatientVitalsResponseThreshold
  highWeeklyP1?: ListPatientVitalsResponseThreshold
  highWeeklyP2?: ListPatientVitalsResponseThreshold
  gainP0?: ListPatientVitalsResponseThreshold
  lossP1?: ListPatientVitalsResponseThreshold
}

export type ListPatientVitalsResponseThreshold = {
  value?: number
  systolic?: number
  diastolic?: number
}

export type ListPatientVitalsResponse = {
  patientVitals?: ListPatientVitalsResponseVital[]
  nextPageToken?: string
  totalSize?: number
}

export type Patient = {
  name?: string
}

export type ListHeartRatesRequest = {
  pageSize?: number
  pageToken?: string
  filter?: string
  orderBy?: string
}

export type ListHeartRatesResponse = {
  heartRates?: HeartRate[]
  nextPageToken?: string
  totalSize?: number
}

export type HeartRate = {
  name?: string
  uid?: string
}

export type BatchGetVitalsSummariesRequest = {
  names?: string[]
}

export type BatchGetVitalsSummariesResponse = {
  vitalsSummaries?: VitalsSummary[]
}

export type BatchGetPatientsForProviderNotificationsRequest = {
  names?: string[]
  bpSysUpperLimit?: number
  bpSysLowerLimit?: number
  bpDiaUpperLimit?: number
  bpDiaLowerLimit?: number
  bgUpperLimit?: number
  bgLowerLimit?: number
  readingsCount?: number
}

export type BatchGetPatientsForProviderNotificationsResponse = {
  vitalsChecks?: ProviderNotificationsPatient[]
}

export type ProviderNotificationsPatient = {
  patientId?: string
  highBpInLastWeek?: boolean
  lowBpInLastWeek?: boolean
  highBgInLastWeek?: boolean
  lowBgInLastWeek?: boolean
  monthlyAvgHighBpTagFound?: boolean
  monthlyAvgHighBgTagFound?: boolean
  avgSystolic?: GoogleProtobufWrappers.DoubleValue
  avgDiastolic?: GoogleProtobufWrappers.DoubleValue
  avgGlucose?: GoogleProtobufWrappers.DoubleValue
  countHighBp?: number
  countLowBp?: number
  countHighBg?: number
  countLowBg?: number
  recentBpHighReadings?: RecentBloodPressureReading[]
  recentBpLowReadings?: RecentBloodPressureReading[]
  recentBgHighReadings?: RecentBloodGlucoseReading[]
  recentBgLowReadings?: RecentBloodGlucoseReading[]
}

export type RecentBloodPressureReading = {
  values?: BloodPressure
  timestamp?: GoogleProtobufTimestamp.Timestamp
}

export type BloodPressure = {
  systolic?: GoogleProtobufWrappers.DoubleValue
  diastolic?: GoogleProtobufWrappers.DoubleValue
}

export type RecentBloodGlucoseReading = {
  values?: BloodGlucose
  timestamp?: GoogleProtobufTimestamp.Timestamp
}

export type BloodGlucose = {
  glucoseLevel?: GoogleProtobufWrappers.DoubleValue
}

export type VitalsSummary = {
  patientId?: string
  averageSystolic?: GoogleProtobufWrappers.DoubleValue
  averageDiastolic?: GoogleProtobufWrappers.DoubleValue
  averageGlucose?: GoogleProtobufWrappers.DoubleValue
  bloodPressureReadingsUsed?: string
  bloodGlucoseReadingsUsed?: string
  daysWithVitals?: string
  daysWithBpVitals?: string
  daysWithBgVitals?: string
}

export type BatchGetHighBloodPressureCountsRequest = {
  patientHighBloodPressureCountParams?: HighBloodPressureCountParams[]
}

export type HighBloodPressureCountParams = {
  patientId?: string
  systolicThresholdPa?: number
  diastolicThresholdPa?: number
  startTime?: GoogleProtobufTimestamp.Timestamp
}

export type BatchGetHighBloodPressureCountsResponse = {
  highBloodPressureCounts?: HighBloodPressureCount[]
}

export type HighBloodPressureCount = {
  patientId?: string
  count?: string
}

export class TelemetryService {
  static ListHeartRates(req: ListHeartRatesRequest, initReq?: fm.InitReq): Promise<ListHeartRatesResponse> {
    return fm.fetchReq<ListHeartRatesRequest, ListHeartRatesResponse>(`/telemetry/v1/heartRates?${fm.renderURLSearchParams(req, [])}`, {...initReq, method: "GET"})
  }
  static BatchGetVitalsSummaries(req: BatchGetVitalsSummariesRequest, initReq?: fm.InitReq): Promise<BatchGetVitalsSummariesResponse> {
    return fm.fetchReq<BatchGetVitalsSummariesRequest, BatchGetVitalsSummariesResponse>(`/telemetry/v1/vitalSummaries:batchGet?${fm.renderURLSearchParams(req, [])}`, {...initReq, method: "GET"})
  }
  static BatchGetHighBloodPressureCounts(req: BatchGetHighBloodPressureCountsRequest, initReq?: fm.InitReq): Promise<BatchGetHighBloodPressureCountsResponse> {
    return fm.fetchReq<BatchGetHighBloodPressureCountsRequest, BatchGetHighBloodPressureCountsResponse>(`/telemetry/v1/bloodPressureCounts:batchGet?${fm.renderURLSearchParams(req, [])}`, {...initReq, method: "GET"})
  }
  static ListPatientVitals(req: ListPatientVitalsRequest, initReq?: fm.InitReq): Promise<ListPatientVitalsResponse> {
    return fm.fetchReq<ListPatientVitalsRequest, ListPatientVitalsResponse>(`/telemetry/v1/${req["parent"]}/vitals?${fm.renderURLSearchParams(req, ["parent"])}`, {...initReq, method: "GET"})
  }
  static GetPatientDeviceHealth(req: GetPatientDeviceHealthRequest, initReq?: fm.InitReq): Promise<PatientDeviceHealth> {
    return fm.fetchReq<GetPatientDeviceHealthRequest, PatientDeviceHealth>(`/telemetry/v1/${req["name"]}/deviceHealth?${fm.renderURLSearchParams(req, ["name"])}`, {...initReq, method: "GET"})
  }
  static GetPatientContextVitals(req: GetPatientContextVitalsRequest, initReq?: fm.InitReq): Promise<PatientContextVitals> {
    return fm.fetchReq<GetPatientContextVitalsRequest, PatientContextVitals>(`/telemetry/v1/${req["name"]}/contextVitals?${fm.renderURLSearchParams(req, ["name"])}`, {...initReq, method: "GET"})
  }
  static DerivePatientVitalsEngagement(req: DerivePatientVitalsEngagementRequest, initReq?: fm.InitReq): Promise<DerivePatientVitalsEngagementResponse> {
    return fm.fetchReq<DerivePatientVitalsEngagementRequest, DerivePatientVitalsEngagementResponse>(`/telemetry/v1/${req["name"]}:deriveVitalsEngagement`, {...initReq, method: "POST", body: JSON.stringify(req, fm.replacer)})
  }
  static BatchGetPatientsForProviderNotifications(req: BatchGetPatientsForProviderNotificationsRequest, initReq?: fm.InitReq): Promise<BatchGetPatientsForProviderNotificationsResponse> {
    return fm.fetchReq<BatchGetPatientsForProviderNotificationsRequest, BatchGetPatientsForProviderNotificationsResponse>(`/telemetry/v1/providerNotificationsPatients:batchGet?${fm.renderURLSearchParams(req, [])}`, {...initReq, method: "GET"})
  }
}