import type { PatientVitalAvgAndGoals } from '@/shared/hooks/queries/vitalsContext.queries';

type GoalReturn = {
  isDataMissing: boolean;
  isGoalMet: boolean;
};

export function isAtBpGoal({
  systolicGoal,
  diastolicGoal,
  systolicAvg30d,
  diastolicAvg30d,
}: PatientVitalAvgAndGoals) {
  const systolicGoalStatus = isAtGoal(systolicAvg30d, systolicGoal);
  const diastolicGoalStatus = isAtGoal(diastolicAvg30d, diastolicGoal);
  const isDataMissing =
    systolicGoalStatus.isDataMissing || diastolicGoalStatus.isDataMissing;
  const isGoalMet =
    !isDataMissing &&
    systolicGoalStatus.isGoalMet &&
    diastolicGoalStatus.isGoalMet;

  return { isGoalMet, isDataMissing };
}

export function isAtT2dGoal(
  bgGoalStatus: GoalReturn,
  a1cGoalStatus: GoalReturn,
) {
  // we don't require both bg and a1c to declare a patient at goal, so we only
  // consider data to be missing if we're missing both bg AND a1c data.
  const isDataMissing =
    bgGoalStatus.isDataMissing && a1cGoalStatus.isDataMissing;

  // similarly, we can ignore a vital type that is missing data as long as
  // the other one has data and is at goal. if data for a vital type exists,
  // it must be under goal to consider the goal met.
  const isGoalMet =
    !isDataMissing &&
    (bgGoalStatus.isDataMissing || bgGoalStatus.isGoalMet) &&
    (a1cGoalStatus.isDataMissing || a1cGoalStatus.isGoalMet);

  return { isDataMissing, isGoalMet };
}

export function isAtBgGoal({ bgAvg30d, bgGoal }: PatientVitalAvgAndGoals) {
  return isAtGoal(bgAvg30d, bgGoal);
}

export function isAtA1cGoal({ a1c, a1cGoal }: PatientVitalAvgAndGoals) {
  return isAtGoal(a1c, a1cGoal);
}

function isAtGoal(vital?: number, goal?: number) {
  const areGoalsSet = !!goal;
  const isDataMissing = !areGoalsSet || !vital;
  const isGoalMet = !isDataMissing && vital < goal;

  return { isGoalMet, isDataMissing };
}
