import { useEffect } from 'react';
import { useController, useFormContext } from 'react-hook-form';

import type { ValueMappable } from '../../form.types';
import type { BaseFieldProps } from '../BaseField';

type Props<T> = {
  value: T;
} & Pick<BaseFieldProps, 'name'> &
  ValueMappable;

export function Hidden<T extends string>({ name, value, valueMap }: Props<T>) {
  const { control, register } = useFormContext();
  const { field } = useController({ name, control });

  useEffect(() => {
    field.onChange(valueMap?.[value] ?? value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return (
    <input type="hidden" {...register(name, { value })} defaultValue={value} />
  );
}
