import cx from 'classnames';
import { type IntlShape, useIntl } from 'react-intl';

import { EllipseIcon } from '@/shared/assets/icons/Ellipse';
import { Tooltip } from '@/shared/common/Tooltip';
import {
  type PatientDetails,
  ProgramType,
} from '@/shared/generated/grpcGateway/pms.pb';
import {
  getConditionAbbreviationI18nMap,
  isParticipatingInProgramType,
} from '@/shared/patient/conditions.utils';
import { Badge } from '@/shared/tempo/atom/Badge';
import { Tag, type TagVariant } from '@/shared/tempo/atom/Tag';
import type {
  CcmCondition,
  RpmCondition,
} from '@/shared/types/clinicalprofile.types';

import { badge as badgeCss, dot, marginRightXxs } from './ProgramStatusTag.css';
import { getProgramTypeI18n } from './useProgramI18n';

type Props = {
  program: ProgramType;
  patientDetails: PatientDetails;
  className?: string;
};

export function ProgramStatusTag({
  program,
  className,
  patientDetails,
}: Props) {
  const intl = useIntl();
  const { isParticipating, isDisenrolled, conditions } =
    isParticipatingInProgramType(patientDetails, program);

  if (!isParticipating && !isDisenrolled) {
    return null;
  }

  const programTypeI18n = getProgramTypeI18n(intl);
  const programName = programTypeI18n[program];
  const badges = getConditionBadges(intl, program, conditions);

  const variant: TagVariant = !isDisenrolled ? 'success' : 'danger';

  return (
    <Tooltip tooltipMessage={getTooltipContent(program, conditions)}>
      <Tag className={className} variant={variant}>
        <Tag.Icon>
          <EllipseIcon className={dot[variant]} />
        </Tag.Icon>
        <span className={cx({ [marginRightXxs]: !!badges.length })}>
          {programName}
        </span>
        {/* No Badges For Disenrolled */}
        {!isDisenrolled &&
          badges.map((badge) => (
            <Badge variant={variant} key={badge} className={badgeCss}>
              {badge}
            </Badge>
          ))}
      </Tag>
    </Tooltip>
  );
}

function getTooltipContent(
  program: ProgramType,
  conditions: RpmCondition[] | CcmCondition[],
) {
  if (program === ProgramType.CCM) {
    return conditions.join(', ');
  }
  return undefined;
}

function getConditionBadges(
  intl: IntlShape,
  program: ProgramType,
  conditions: RpmCondition[] | CcmCondition[],
) {
  if (program === ProgramType.CCM) {
    return [(conditions.length || 0).toString()];
  }
  if (program === ProgramType.RPM) {
    const conditionAbbreviations = getConditionAbbreviationI18nMap(intl);
    return conditions.map(
      (condition) => conditionAbbreviations[condition as RpmCondition],
    );
  }
  return [];
}
