import { useState } from 'react';
import { FormattedMessage } from 'react-intl';

import type { AsyncTitrationMedicationWasNotTitratedReason as Reason } from '@/shared/generated/grpcGateway/medication.pb';
import { Select } from '@/shared/tempo/@labs/molecule/Select';

import { useNotTitratingReasons } from '../hooks/useNotTitratingReasons';
import { notTitrating } from './SuggestedTitration.css';

type Props = {
  isDisabled?: boolean;
  onChange: (reason: Reason) => void;
};

export function NotTitratingReasonSelect({
  isDisabled = false,
  onChange,
}: Props) {
  const [value, setValue] = useState<Reason | null>(null);
  const options = useNotTitratingReasons();

  return (
    <Select
      label={
        <FormattedMessage defaultMessage="Select a reason why a medication wasn't titrated" />
      }
      className={notTitrating.select}
      selectedKey={value}
      onSelectionChange={(key) => {
        setValue(key as Reason);
        onChange(key as Reason);
      }}
      isDisabled={isDisabled}
      items={options}
    >
      {({ key, label }) => <Select.Option key={key}>{label}</Select.Option>}
    </Select>
  );
}
