import { useIntl } from 'react-intl';

import { Form } from '@/shared/common/Form';

import { useOptions } from './useOptions';

type Props = {
  required?: boolean;
};

export function PatientTitrationRejectionReason({ required }: Props) {
  const intl = useIntl();
  const options = useOptions();

  return (
    <Form.Select
      size={12}
      name="patientTitrationRejectionReason"
      label={intl.formatMessage({
        defaultMessage: 'Select a reason why patient rejected titration',
      })}
      items={options}
      required={required}
    >
      {({ key, label }) => (
        <Form.Select.Option key={key}>{label}</Form.Select.Option>
      )}
    </Form.Select>
  );
}
