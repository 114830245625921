import { FormattedMessage, useIntl } from 'react-intl';

import { getProblemList } from '@/pages/adminPanel/patient-enrollment-tracker/ui/shared/icd10CodeUtils';
import {
  MedPermissions,
  PatientMedicationsList,
} from '@/pages/patients/PatientMedications/PatientMedicationsList';
import type { CCMCarePlan } from '@/shared/generated/grpcGateway/ccm_care_plan.pb';
import {
  type CCMCondition,
  type CCMPrognosis,
} from '@/shared/generated/grpcGateway/ccm_care_plan.pb';
import { useRouteParamPatientDetails } from '@/shared/hooks/queries';

import { useCcmConditionI18n } from '../../CarePlanForm/conditions';
import { GRPC_CONDITION_TO_FALCON_CONDITION } from '../../CarePlanForm/dataTransformation';
import { useCcmPrognosisI18n } from '../../CarePlanForm/prognosis';
import { PREVENTATIVE_CARE_OPTIONS } from '../../CarePlanForm/sections/preventativeCareOptions';
import { PSYCHOSOCIAL_ASSESSMENT_OPTIONS } from '../../CarePlanForm/sections/psychoSocialAssessmentOptions';
import {
  container,
  goalsAndInterventionsContainer,
  goalsAndInterventionsHeader,
  list,
  listItem,
  medsList,
  sectionContainer,
  selfManagementGoals,
} from './CarePlanPreview.css';
import { Field, FieldValue, Header, Subheader } from './Layout';
import { fieldLabel, fieldValue } from './Layout/Layout.css';
import { LetterSends } from './LetterSends';
import {
  formatDisabledPsychosocialAssessments,
  formatGoogleDate,
  formatSpecialist,
} from './formatters';

type Props = {
  patientId: string;
  carePlan: CCMCarePlan;
  allCarePlans: CCMCarePlan[];
};

export function CarePlanPreview({ patientId, carePlan, allCarePlans }: Props) {
  const intl = useIntl();
  const { data: patient } = useRouteParamPatientDetails();
  const problemList = getProblemList(patient?.diagnosis_codes);
  const { carePlan: carePlanData } = carePlan;
  const prognosisI18n = useCcmPrognosisI18n();
  const conditionI18n = useCcmConditionI18n();

  if (!carePlanData) {
    // TODO: error
    return null;
  }

  const surgicalHistories = carePlanData.surgicalHistories
    // When displaying, filter out incomplete surgical histories
    ?.filter(({ diagnosis, details }) => diagnosis || details);

  const specialists = carePlanData.specialists
    // When displaying, filter out incomplete specialists
    ?.filter(
      ({ name, specialty, lastVisit, nextVisit }) =>
        name || specialty || lastVisit || nextVisit,
    );

  const medAllergies = carePlanData.medicationAllergies
    // When displaying, filter out incomplete medication allergies
    ?.filter(({ medication, reaction }) => medication && reaction);

  const emergencyVisits = carePlanData.emergencyVisits
    // When displaying, filter out incomplete visits
    ?.filter(
      ({ date, hospital, relatedDiagnosis }) =>
        date && hospital && relatedDiagnosis,
    );

  return (
    <>
      <LetterSends
        patientId={patientId}
        carePlan={carePlan}
        allCarePlans={allCarePlans}
      />
      <div className={container}>
        <Header>
          <FormattedMessage defaultMessage="General Assessment" />
        </Header>
        <div className={sectionContainer}>
          <Subheader>
            <FormattedMessage defaultMessage="Problem List" />
          </Subheader>
          <ul className={list}>
            {problemList.map((description) => (
              <li key={description} className={listItem}>
                {description}
              </li>
            ))}
          </ul>
        </div>
        <div className={sectionContainer}>
          <Subheader>
            <FormattedMessage defaultMessage="Past Surgical History" />
          </Subheader>
          <ul className={list}>
            {surgicalHistories?.length ? (
              surgicalHistories.map(({ diagnosis, details }, idx) => (
                <li key={`${diagnosis}-${details}-${idx}`} className={listItem}>
                  {[diagnosis, details].filter(Boolean).join(': ')}
                </li>
              ))
            ) : (
              <li className={listItem}>
                <FormattedMessage defaultMessage="No known surgical history at this time" />
              </li>
            )}
          </ul>
        </div>
        <div className={sectionContainer}>
          <Subheader>
            <FormattedMessage defaultMessage="Specialists" />
          </Subheader>
          <ul className={list}>
            {specialists?.length ? (
              specialists.map((specialist, idx) => {
                const { name, specialty, lastVisit, nextVisit } = specialist;
                return (
                  <li
                    key={`${name}-${specialty}-${lastVisit}-${nextVisit}-${idx}`}
                    className={listItem}
                  >
                    {formatSpecialist(specialist, intl)}
                  </li>
                );
              })
            ) : (
              <li className={listItem}>
                <FormattedMessage defaultMessage="No known specialists at this time" />
              </li>
            )}
          </ul>
        </div>
        <div className={sectionContainer}>
          <Subheader>
            <FormattedMessage defaultMessage="Emergency Visits and Hospitalizations in the last 12 months" />
          </Subheader>
          <ul className={list}>
            {emergencyVisits?.length ? (
              emergencyVisits.map(
                ({ date, hospital, relatedDiagnosis }, idx) => (
                  <li key={`${hospital}-${idx}`} className={listItem}>
                    {hospital}, {formatGoogleDate(date)}, {relatedDiagnosis}
                  </li>
                ),
              )
            ) : (
              <li className={listItem}>
                <FormattedMessage defaultMessage="No emergency visits reported" />
              </li>
            )}
          </ul>
        </div>
        <div className={sectionContainer}>
          <Subheader>
            <FormattedMessage defaultMessage="Medications" />
          </Subheader>
          {carePlanData.medsNotReviewed ? (
            <FieldValue>
              <FormattedMessage defaultMessage="Unable to verify medications at this time" />
            </FieldValue>
          ) : (
            patient && (
              <PatientMedicationsList
                classes={{ container: medsList }}
                patientId={patient.id}
                noteId={carePlan.noteId}
                showRequiredActions={false}
                medPermissions={MedPermissions.View}
              />
            )
          )}
        </div>
        <div className={sectionContainer}>
          <Subheader>
            <FormattedMessage defaultMessage="Medication Allergies" />
          </Subheader>
          <ul className={list}>
            {medAllergies?.length ? (
              medAllergies.map(({ reaction, medication }, idx) => (
                <li key={`${medication}-${idx}`} className={listItem}>
                  {medication}: {reaction}
                </li>
              ))
            ) : (
              <li className={listItem}>
                <FormattedMessage defaultMessage="NKDA" />
              </li>
            )}
          </ul>
        </div>
        <div className={sectionContainer}>
          <Subheader>
            <FormattedMessage defaultMessage="Preventative Care" />
          </Subheader>
          <ul className={list}>
            {carePlanData.preventativeCare?.map(
              ({ date, enabled, name, notApplicable }) => (
                <li key={name} className={listItem}>
                  {notApplicable ? (
                    <FormattedMessage
                      defaultMessage="{name}, N/A"
                      values={{
                        name: PREVENTATIVE_CARE_OPTIONS.find(
                          (opt) => opt.name === name,
                        )?.label,
                      }}
                    />
                  ) : (
                    <FormattedMessage
                      defaultMessage="{name}, {enabled, select, true {Yes, {date}} other {No}}"
                      values={{
                        name: PREVENTATIVE_CARE_OPTIONS.find(
                          (opt) => opt.name === name,
                        )?.label,
                        enabled,
                        date,
                      }}
                    />
                  )}
                </li>
              ),
            )}
          </ul>
        </div>
        <div className={sectionContainer}>
          <Subheader>
            <FormattedMessage defaultMessage="Social History" />
          </Subheader>
          <Field
            label={
              <FormattedMessage defaultMessage="History of tobacco use?" />
            }
            value={carePlanData.socialHistory?.tobaccoUse}
          />
        </div>
        <div className={sectionContainer}>
          <Subheader>
            <FormattedMessage defaultMessage="Diet" />
          </Subheader>
          <Field
            label={
              <FormattedMessage defaultMessage="How many times per week do you eat fast food meals?" />
            }
            value={carePlanData.diet?.fastFood}
          />
          <Field
            label={<FormattedMessage defaultMessage="Do you snack often?" />}
            value={carePlanData.diet?.snacks}
          />
          <Field
            label={
              <FormattedMessage defaultMessage="How many times per week do you eat desserts or other sweets?" />
            }
            value={carePlanData.diet?.desserts}
          />
          <Field
            label={
              <FormattedMessage defaultMessage="Do you drink regular soda?" />
            }
            value={carePlanData.diet?.regularSoda}
          />
          <Field
            label={
              <FormattedMessage defaultMessage="Do you drink sweet tea?" />
            }
            value={carePlanData.diet?.sweetTea}
          />
          <Field
            label={
              <FormattedMessage defaultMessage="How much water do you drink per day?" />
            }
            value={carePlanData.diet?.waterIntake}
          />
          <Field
            label={
              <FormattedMessage defaultMessage="Do you add salt to meals regularly?" />
            }
            value={carePlanData.diet?.saltIntake}
          />
        </div>
        <div className={sectionContainer}>
          <Subheader>
            <FormattedMessage defaultMessage="Exercise" />
          </Subheader>
          <Field
            label={
              <FormattedMessage defaultMessage="Do you exercise regularly? If so, What type of exercise? How long and how often?" />
            }
            value={carePlanData.exercise}
          />
        </div>
        <div className={sectionContainer}>
          <Subheader>
            <FormattedMessage defaultMessage="Psychosocial Assessment/Social Determinants of Need" />
          </Subheader>
          {carePlanData.psychosocialAssessments
            ?.filter(({ enabled }) => enabled)
            .map(({ name, description }) => (
              <Field
                key={name}
                label={
                  PSYCHOSOCIAL_ASSESSMENT_OPTIONS.find(
                    (opt) => opt.name === name,
                  )?.label
                }
                value={description}
              />
            ))}
          <Field
            label={
              <FormattedMessage defaultMessage="Domains assessed with no current need" />
            }
            value={formatDisabledPsychosocialAssessments(
              intl,
              carePlanData.psychosocialAssessments,
            )}
          />
        </div>
        <div className={sectionContainer}>
          <Header>
            <FormattedMessage defaultMessage="Goals and Interventions" />
          </Header>
          {carePlanData.goalsAndInterventions?.map(
            (
              {
                condition,
                prognosis,
                treatmentGoal,
                healthGoal,
                actionSteps,
                coordination,
              },
              idx,
            ) => (
              <div key={condition} className={goalsAndInterventionsContainer}>
                <p className={goalsAndInterventionsHeader}>
                  <FormattedMessage
                    defaultMessage="Condition {number}: {condition}"
                    values={{
                      number: idx + 1,
                      condition:
                        conditionI18n[
                          GRPC_CONDITION_TO_FALCON_CONDITION[
                            condition as CCMCondition
                          ]
                        ],
                    }}
                  />
                </p>
                <p className={fieldValue}>
                  <span className={fieldLabel}>
                    <FormattedMessage defaultMessage="Prognosis: " />
                  </span>
                  {prognosisI18n[prognosis as CCMPrognosis]}
                </p>
                <p className={fieldValue}>
                  <span className={fieldLabel}>
                    <FormattedMessage defaultMessage="Treatment Goal: " />
                  </span>
                  {treatmentGoal}
                </p>
                <p className={selfManagementGoals}>
                  <FormattedMessage defaultMessage="Self-Management Goals" />
                </p>
                <Field
                  label={
                    <FormattedMessage defaultMessage="Patient self-defined health goal" />
                  }
                  value={healthGoal}
                />
                <Field
                  label={
                    <FormattedMessage defaultMessage="Patient self-defined action step(s)" />
                  }
                  value={actionSteps}
                />
                <Field
                  label={
                    <FormattedMessage defaultMessage="Coordination of care/resources" />
                  }
                  value={coordination}
                />
              </div>
            ),
          )}
          <div className={sectionContainer}>
            <Subheader>
              <FormattedMessage defaultMessage="Preventative Care Gaps" />
            </Subheader>
            {carePlanData.preventativeCare
              ?.filter(
                ({ enabled, nextSteps, notApplicable }) =>
                  !enabled && !notApplicable && nextSteps,
              )
              .map(({ name, nextSteps }) => (
                <Field
                  key={name}
                  label={
                    PREVENTATIVE_CARE_OPTIONS.find((e) => e.name === name)
                      ?.label
                  }
                  value={nextSteps}
                />
              ))}
          </div>
          <div className={sectionContainer}>
            <Subheader>
              <FormattedMessage defaultMessage="Follow-up" />
            </Subheader>
            <Field
              label={
                <FormattedMessage defaultMessage="Date and time for next follow-up with my care manager" />
              }
              value={carePlanData.followUp?.ccmAppointment}
            />
            <Field
              label={
                <FormattedMessage defaultMessage="Date and time for next appointment with my doctor" />
              }
              value={carePlanData.followUp?.providerAppointment}
            />
          </div>
        </div>
      </div>
    </>
  );
}
