import cx from 'classnames';

import { Badge } from '@/shared/tempo/atom/Badge';
import { Tag, type TagVariant } from '@/shared/tempo/atom/Tag';

import { badge as badgeCss, label, marginRightXxs } from './ProgramTag.css';

type Props = {
  variant?: Extract<TagVariant, 'default' | 'info'>;
  program: string;
  badges?: string[];
  className?: string;
};

export function ProgramTag({
  program,
  badges = [],
  className,
  variant = 'default',
}: Props) {
  return (
    <Tag className={className} variant={variant}>
      <span
        className={cx(label[variant], { [marginRightXxs]: !!badges.length })}
      >
        {program}
      </span>
      {badges.map((badge) => (
        <Badge variant={variant} key={badge} className={badgeCss}>
          {badge}
        </Badge>
      ))}
    </Tag>
  );
}
