import { FormattedMessage } from 'react-intl';

import { Form } from '@/shared/common/Form';
import { EndEncounterReason } from '@/shared/types/note.types';

import type { TypeOfEncounter } from '../../../Notes.types';
import { isUnscheduledVisit } from '../../../utils/encounterTypeUtils';

type Props = {
  encounterType: Maybe<TypeOfEncounter>;
};

export function NoShowReasons({ encounterType }: Props) {
  return (
    <>
      <Form.RadioGroup
        required
        size={12}
        name="noShowReason"
        label={
          <FormattedMessage defaultMessage="Select the reason this visit was not completed" />
        }
        orientation="vertical"
      >
        {isUnscheduledVisit(encounterType) ? (
          <>
            <Form.Radio value={EndEncounterReason.UnabletoReachPatient}>
              <FormattedMessage defaultMessage="Unable to reach patient" />
            </Form.Radio>
            <Form.Radio value={EndEncounterReason.PatientRequestedCallback}>
              <FormattedMessage defaultMessage="Patient requested callback" />
            </Form.Radio>
            <Form.Radio value={EndEncounterReason.Disenrollment}>
              <FormattedMessage defaultMessage="Disenrollment" />
            </Form.Radio>
          </>
        ) : (
          <>
            <Form.Radio value={EndEncounterReason.NoShow}>
              <FormattedMessage defaultMessage="No show" />
            </Form.Radio>
            <Form.Radio value={EndEncounterReason.PatientRequestReschedule}>
              <FormattedMessage defaultMessage="Patient requested reschedule" />
            </Form.Radio>
            <Form.Radio value={EndEncounterReason.SchedulingErrorDisenrollment}>
              <FormattedMessage defaultMessage="Scheduling error / disenrollment" />
            </Form.Radio>
          </>
        )}
        <Form.Radio value={EndEncounterReason.PatientHospitalized}>
          <FormattedMessage defaultMessage="Patient hospitalized" />
        </Form.Radio>
      </Form.RadioGroup>
    </>
  );
}
