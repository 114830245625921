import {
  EducationModulesReview,
  NoPrevGoal,
  PrevGoalDiscuss,
  PrevGoalProgress,
  ReviewActionSteps,
  SetActionSteps,
  SetGoal,
} from './actionPlan';
import { ChartPrep } from './chartPrep';
import { EndCall } from './endCall';
import { IntroConfirmData, IntroPatientAttendance } from './introduction';
import { MedReview } from './medReview';
import { Scheduling } from './scheduling';
import { TimeTracking } from './timeTracking';
import { Titration } from './titration';

export const TITRATION_OUTREACH_ROUTES = {
  '/chart-prep/index': ChartPrep,
  '/intro/patient-attendance': IntroPatientAttendance,
  '/intro/patient-identification': IntroConfirmData,
  '/med-review/index': MedReview,
  '/titration/index': Titration,
  '/action-plan/prev-goal-progress': PrevGoalProgress,
  '/action-plan/prev-goal-progress/discuss': PrevGoalDiscuss,
  '/action-plan/no-prev-goal': NoPrevGoal,
  '/action-plan/set-goal': SetGoal,
  '/action-plan/education-modules-review': EducationModulesReview,
  '/action-plan/set-action-steps': SetActionSteps,
  '/action-plan/review-action-steps': ReviewActionSteps,
  '/scheduling/index': Scheduling,
  '/time-tracking/index': TimeTracking,
  '/end-call/index': EndCall,
};
