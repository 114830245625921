import sortBy from 'lodash/sortBy';
import { useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';

import { PatientTitrationRejectionReason } from '@/components/AsyncTitration/PatientTitrationRejectionReason';
import { useNotTitratingReasons } from '@/components/AsyncTitration/hooks/useNotTitratingReasons';
import { Form } from '@/shared/common/Form';

import type { ClinicalGoalReachedFormFields } from '../../../../../Notes.types';
import { ReasonForNotTitrating, useReasonForNotTitratingI18n } from './types';

// these don't make sense in the context of the clinical goals module, so excluding them
const IRRELEVANT_REASONS = [
  ReasonForNotTitrating.ADJUSTED_ANOTHER_MEDICATION,
  ReasonForNotTitrating.MEDICATION_VERIFICATION,
  ReasonForNotTitrating.NEW_PRESCRIPTION_REQUIRED,
];

type Props = {
  excludedKeys?: (keyof typeof ReasonForNotTitrating)[];
};

export function NonTitrationReason({ excludedKeys = [] }: Props) {
  const intl = useIntl();
  const reasonI18n = useReasonForNotTitratingI18n();
  const form = useFormContext<ClinicalGoalReachedFormFields>();
  const value = form.watch('notTitratingReason');
  const exclusions = [...IRRELEVANT_REASONS, ...excludedKeys];
  const items = sortBy(
    [
      ...useNotTitratingReasons(),
      {
        key: ReasonForNotTitrating.PATIENT_REJECTED,
        label: reasonI18n[ReasonForNotTitrating.PATIENT_REJECTED],
      },
      {
        key: ReasonForNotTitrating.MAX_TOLERATED_DOSE_REACHED,
        label: reasonI18n[ReasonForNotTitrating.MAX_TOLERATED_DOSE_REACHED],
      },
    ],
    'label',
  ).filter(({ key }) => !exclusions.includes(key));

  return (
    <>
      <Form.Select
        size={12}
        name="notTitratingReason"
        label={intl.formatMessage({
          defaultMessage: 'Select a reason why medications were not adjusted',
        })}
        items={items}
        required
      >
        {({ key, label }) => (
          <Form.Select.Option key={key}>{label}</Form.Select.Option>
        )}
      </Form.Select>
      {value === ReasonForNotTitrating.PATIENT_REJECTED && (
        <PatientTitrationRejectionReason required />
      )}
    </>
  );
}
