import { ProviderTeamRole } from '@/shared/types/provider.types';

import { useCareProvider } from './queries';
import { useCurrentUser } from './useCurrentUser';

const CN_ROLES = [
  ProviderTeamRole.CN,
  ProviderTeamRole.TITRATION_CLINICAL_NAVIGATOR,
  ProviderTeamRole.PATIENT_COORDINATOR,
];

export function useIsCnExperience() {
  const { currentUserId: providerId } = useCurrentUser();

  const { data: careProvider, isLoading } = useCareProvider(providerId, {
    staleTime: Infinity,
  });

  return {
    isLoading,
    isCnExperience: CN_ROLES.includes(
      careProvider?.team_role as ProviderTeamRole,
    ),
  };
}
