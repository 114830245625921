import cx from 'classnames';
import { FormattedMessage, useIntl } from 'react-intl';

import { ProgramTag } from '@/shared/common/ProgramTag';
import { Tooltip } from '@/shared/common/Tooltip';
import {
  type PatientDetails,
  ProgramType,
} from '@/shared/generated/grpcGateway/pms.pb';
import {
  getConditionAbbreviationI18nMap,
  isParticipatingInProgramType,
} from '@/shared/patient/conditions.utils';
import { Tag } from '@/shared/tempo/atom/Tag';

import { errorTag, tagsContainer } from './ProgramTags.css';

type Props = {
  patientDetails: PatientDetails;
  className?: string;
};

export function ProgramTags({ patientDetails, className }: Props) {
  const intl = useIntl();
  const { conditions: rpmConditions, isParticipating: isParticipatingRpm } =
    isParticipatingInProgramType(patientDetails, ProgramType.RPM);

  const { conditions: ccmConditions, isParticipating: isParticipatingCcm } =
    isParticipatingInProgramType(patientDetails, ProgramType.CCM);

  const { isParticipating: isParticipatingApcm } = isParticipatingInProgramType(
    patientDetails,
    ProgramType.APCM,
  );

  const conditionAbbreviations = getConditionAbbreviationI18nMap(intl);
  return (
    <div className={cx(tagsContainer, className)}>
      {isParticipatingCcm && (
        <Tooltip tooltipMessage={ccmConditions.join(', ')}>
          <ProgramTag
            variant="info"
            program={intl.formatMessage({
              defaultMessage: 'CCM',
              description: 'Abbreviation for "Chronic Care Management"',
            })}
            badges={[(ccmConditions.length || 0).toString()]}
          />
        </Tooltip>
      )}
      {isParticipatingApcm && (
        <ProgramTag
          variant="info"
          program={intl.formatMessage({
            defaultMessage: 'APCM',
            description: 'Abbreviation for "Advanced Primary Care Management"',
          })}
        />
      )}
      {isParticipatingRpm && (
        <ProgramTag
          program={intl.formatMessage({
            defaultMessage: 'RPM',
            description: 'Abbreviation for "Remote Patient Monitoring"',
          })}
          badges={rpmConditions.map(
            (condition) => conditionAbbreviations[condition],
          )}
        />
      )}
      {![isParticipatingRpm, isParticipatingCcm, isParticipatingApcm].some(
        Boolean,
      ) && (
        <Tag variant="error" className={errorTag}>
          <FormattedMessage defaultMessage="No Program Assigned" />
        </Tag>
      )}
    </div>
  );
}
