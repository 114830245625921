import { type ReactElement, useMemo, useRef, useState } from 'react';
import type { UseFormReturn } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';

import { Form } from '@/shared/common/Form';
import { GridItem } from '@/shared/common/Form/GridItem';
import type { BaseFieldProps } from '@/shared/common/Form/fields/BaseField';
import { useOnMount } from '@/shared/hooks/useOnMount';
import { RequiredIndicator } from '@/shared/tempo/atom/Label';

import { ContextualGoalButton } from '../../Goals/ContextualGoalButton';
import { GoalMeasure } from '../../Goals/goals.types';
import {
  type FormFields,
  type PreventativeCareName,
  getPreventativeCareFieldNames,
} from '../formConfig';
import { notApplicableText } from './PreventativeCare.css';
import {
  extraDetails,
  sectionTitle,
  toggleRowHelp,
  toggleRowLabel,
} from './carePlanSections.css';
import { PREVENTATIVE_CARE_OPTIONS } from './preventativeCareOptions';

type Props = {
  form: UseFormReturn<FormFields>;
  createGoalWithMeasure: (measure: GoalMeasure) => void;
};

export function PreventativeCare({ form, createGoalWithMeasure }: Props) {
  const intl = useIntl();

  return (
    <Form.Section
      title={intl.formatMessage({
        defaultMessage: 'Preventative Care (Enter Dates)',
      })}
      classes={{ title: sectionTitle }}
    >
      {PREVENTATIVE_CARE_OPTIONS.map((option) => (
        <PreventativeCareRow
          key={option.name}
          name={option.name}
          label={option.label}
          help={option.help}
          alwaysApplicable={option.alwaysApplicable}
          createGoalWithMeasure={createGoalWithMeasure}
          form={form}
        />
      ))}
      <p className={extraDetails}>
        <FormattedMessage defaultMessage="*A pack-year is smoking an average of one pack of cigarettes per day for one year. For example, a person could have a 20 pack-year history by smoking one pack a day for 20 years or two packs a day for 10 years." />
      </p>
    </Form.Section>
  );
}

function PreventativeCareRow({
  name,
  label,
  help,
  form,
  alwaysApplicable,
  createGoalWithMeasure,
}: {
  name: PreventativeCareName;
  label: ReactElement;
  help?: ReactElement;
  alwaysApplicable: boolean;
  createGoalWithMeasure: (measure: GoalMeasure) => void;
} & Props) {
  const intl = useIntl();
  const [rowWidth, setRowWidth] = useState(Number.POSITIVE_INFINITY);
  const {
    checked: checkedFieldName,
    date: dateFieldName,
    notApplicable: notApplicableFieldName,
  } = getPreventativeCareFieldNames(name);

  const checked = !!form.watch(checkedFieldName);

  const rowRef = useRef<Nullable<HTMLDivElement>>(null);
  useOnMount(() => {
    if (!rowRef.current) return;
    const resizeObserver = new ResizeObserver(() => {
      setRowWidth(rowRef.current?.offsetWidth || Number.POSITIVE_INFINITY);
    });
    resizeObserver.observe(rowRef.current);
    // eslint-disable-next-line consistent-return
    return () => resizeObserver.disconnect();
  });

  const rowSize = getRowSize(rowWidth);
  const toggleLabelSize = useMemo(() => {
    if (alwaysApplicable) {
      return 7;
    }
    return rowSize === 'small' ? 4.8 : 5.3;
  }, [alwaysApplicable, rowSize]);

  const checkboxSize = useMemo(
    () => (rowSize === 'small' ? 2.2 : 1.7),
    [rowSize],
  );

  return (
    <div ref={rowRef}>
      <Form.Row>
        <GridItem size={toggleLabelSize}>
          <span>
            {label}
            {checked && <RequiredIndicator />}
          </span>
          {help && <span className={toggleRowHelp}>{help}</span>}
        </GridItem>
        {!alwaysApplicable && (
          <Form.Checkbox
            // In general, we don't want to support decimal sizes, but here we need it
            // in order to get the field size to work well.
            // (Need to account for the form when the CCM/APCM Care Plan Encounter is Open/Closed)
            size={checkboxSize as BaseFieldProps['size']}
            labelPlacement="start"
            label={
              <span className={notApplicableText}>
                {intl.formatMessage({ defaultMessage: 'NA' })}
              </span>
            }
            name={notApplicableFieldName}
            onChange={(isChecked) => {
              if (isChecked) {
                form.setValue(checkedFieldName, false);
                form.setValue(dateFieldName, '');
              }
            }}
          />
        )}
        <Form.Toggle
          label=""
          disabled={!!form.getValues(notApplicableFieldName)}
          aria-label={checkedFieldName}
          name={checkedFieldName}
          labelPlacement="start"
          labelClassName={toggleRowLabel}
          size={1}
          onChange={(isChecked) => {
            if (!isChecked) {
              form.setValue(dateFieldName, '');
            }
          }}
        />
        <Form.TextField
          size={3}
          name={dateFieldName}
          placeholder="MM / DD / YYYY"
          isDisabled={
            !form.getValues(checkedFieldName) ||
            !!form.getValues(notApplicableFieldName)
          }
        />
        <ContextualGoalButton
          type="form"
          onPress={() =>
            createGoalWithMeasure(PREVENTATIVE_CARE_GOAL_MEASURE_MAP[name])
          }
        />
      </Form.Row>
    </div>
  );
}

const PREVENTATIVE_CARE_GOAL_MEASURE_MAP: Record<
  PreventativeCareName,
  GoalMeasure
> = {
  breastCancerScreening: GoalMeasure.BreastCancerScreening,
  colonoscopy: GoalMeasure.Colonoscopy,
  diabeticEyeExam: GoalMeasure.DiabeticEyeExam,
  diabeticFootExam: GoalMeasure.DiabeticFootExam,
  influenza: GoalMeasure.Influenza,
  pneumovax: GoalMeasure.Pneumovax,
  tdap: GoalMeasure.Tdap,
  zostavax: GoalMeasure.Zostavax,
};

type RowSize = 'small' | 'medium';
function getRowSize(rowSize: number): RowSize {
  if (rowSize > 850) {
    return 'medium';
  }
  return 'small';
}
