import { useIntl } from 'react-intl';

import { Form } from '@/shared/common/Form';
import { useFlags } from '@/shared/hooks';
import type { PatientVitalAvgAndGoals } from '@/shared/hooks/queries/vitalsContext.queries';

import { goalInfo } from '../ClinicalGoalReachedForm.css';
import {
  BgA1cSummary,
  BpSummary,
  isAtA1cGoal,
  isAtBgGoal,
  isAtBpGoal,
  isAtT2dGoal,
} from '../summary';
import { Goal } from './Goal';
import { GoalUnmetReasons } from './GoalUnmetReason';
import { NonTitrationReason } from './NonTitrationReason';
import { shouldCollectNonTitrationReason } from './shouldCollectNonTitrationReason';
import { STRING_TO_BOOL_MAP } from './stringToBoolMap';
import { useHasMedChange } from './useHasMedChange';

type Props = {
  vitalValuesAndGoals: PatientVitalAvgAndGoals;
};

type LegacyProps = {
  isBgBpGoalMet?: boolean;
} & Props;

export function HtnAndT2dGoals({
  isBgBpGoalMet,
  vitalValuesAndGoals,
}: LegacyProps) {
  const { gatherNonTitrationReasons } = useFlags();

  if (!gatherNonTitrationReasons) {
    return (
      <LegacyHtnAndT2dGoals
        isBgBpGoalMet={isBgBpGoalMet}
        vitalValuesAndGoals={vitalValuesAndGoals}
      />
    );
  }

  return <NewHtnAndT2dGoals vitalValuesAndGoals={vitalValuesAndGoals} />;
}

function NewHtnAndT2dGoals({ vitalValuesAndGoals }: Props) {
  const { isLoading, hasMedChange } = useHasMedChange();

  if (isLoading) {
    return null;
  }

  const bgGoalStatus = isAtBgGoal(vitalValuesAndGoals);
  const a1cGoalStatus = isAtA1cGoal(vitalValuesAndGoals);
  const t2dGoalStatus = isAtT2dGoal(bgGoalStatus, a1cGoalStatus);
  const htnGoalStatus = isAtBpGoal(vitalValuesAndGoals);

  return (
    <>
      <div className={goalInfo}>
        <BpSummary />
        <BgA1cSummary />
      </div>
      <Form.Hidden
        name="isA1cGoalMet"
        value={String(a1cGoalStatus.isGoalMet)}
        valueMap={STRING_TO_BOOL_MAP}
      />
      <Form.Hidden
        name="isBgGoalMet"
        value={String(bgGoalStatus.isGoalMet)}
        valueMap={STRING_TO_BOOL_MAP}
      />
      <Form.Hidden
        name="isT2dGoalMet"
        value={String(t2dGoalStatus.isGoalMet)}
        valueMap={STRING_TO_BOOL_MAP}
      />
      <Form.Hidden
        name="isBpGoalMet"
        value={String(htnGoalStatus.isGoalMet)}
        valueMap={STRING_TO_BOOL_MAP}
      />
      <Form.Hidden
        name="isBgBpGoalMet"
        value={String(htnGoalStatus.isGoalMet && t2dGoalStatus.isGoalMet)}
        valueMap={STRING_TO_BOOL_MAP}
      />
      <Form.Hidden
        name="hasMedChange"
        value={String(hasMedChange)}
        valueMap={STRING_TO_BOOL_MAP}
      />
      {shouldCollectNonTitrationReason({
        isHtn: true,
        isT2d: true,
        hasRelevantMedChange: hasMedChange,
        fields: {
          isBpGoalMet: htnGoalStatus.isGoalMet,
          isT2dGoalMet: t2dGoalStatus.isGoalMet,
        },
      }) && <NonTitrationReason />}
    </>
  );
}

function LegacyHtnAndT2dGoals({
  isBgBpGoalMet,
  vitalValuesAndGoals,
}: LegacyProps) {
  const intl = useIntl();

  return (
    <>
      <Goal
        isGoalMetFieldName="isBgBpGoalMet"
        goalDetailsFieldName="bgBpGoalDetails"
        isGoalMetFieldValue={isBgBpGoalMet}
        description={intl.formatMessage({
          defaultMessage: 'Goal 1 of 1: Is the patient at goal for T2D+HTN?',
        })}
        areGoalsDefined={Boolean(
          vitalValuesAndGoals.bgGoal &&
            vitalValuesAndGoals.a1cGoal &&
            vitalValuesAndGoals.systolicGoal &&
            vitalValuesAndGoals.diastolicGoal,
        )}
        vitalSummary={
          <>
            <BpSummary />
            <BgA1cSummary />
          </>
        }
      />
      {isBgBpGoalMet === false && <GoalUnmetReasons />}
    </>
  );
}
