import { FormattedMessage, useIntl } from 'react-intl';

import { Form } from '@/shared/common/Form';
import type { CheckSameSlotAppointmentAvailabilityResponse } from '@/shared/generated/grpcGateway/scheduling.pb';
import { Button } from '@/shared/tempo/atom/Button';

import type { ConfiguredForm } from '../Form/FormContainer';
import { BoxContainer } from './SmartScheduler/BoxContainer';
import { ErrorDisplay } from './SmartScheduler/ErrorDisplay';
import type { SameSlotReschedulingFormFields } from './types';

type Props = {
  form: ConfiguredForm<SameSlotReschedulingFormFields>;
  sameSlotAvailabilities: Maybe<CheckSameSlotAppointmentAvailabilityResponse>;
  onSelect: (values: SameSlotReschedulingFormFields) => void;
};

export function SameSlotRescheduler({
  form,
  sameSlotAvailabilities,
  onSelect,
}: Props) {
  const intl = useIntl();

  return (
    <>
      {!sameSlotAvailabilities?.availableCareProviders?.length ? (
        <ErrorDisplay>
          <FormattedMessage defaultMessage="No other available clinicians found for this time slot" />
        </ErrorDisplay>
      ) : (
        <BoxContainer>
          <Form form={form} onSubmit={onSelect}>
            <Form.Select
              required
              label={intl.formatMessage({
                defaultMessage: 'Calendar to reschedule with',
              })}
              name="acuityCalendarId"
              size={8}
            >
              {sameSlotAvailabilities.availableCareProviders.map((cal) => (
                <Form.Select.Option key={cal?.acuityCalendarId}>
                  {`${cal?.careProviderFirstName} ${cal?.careProviderLastName}, ${cal?.careProviderRole}`}
                </Form.Select.Option>
              ))}
            </Form.Select>
            <Button type="submit" variant="secondary" size="small">
              <FormattedMessage defaultMessage="Select" />
            </Button>
          </Form>
        </BoxContainer>
      )}
    </>
  );
}
