import type { IntlShape } from 'react-intl';
import { useIntl } from 'react-intl';

import { usePatientTitrationRejectionReasonsI18n } from '@/components/AsyncTitration/hooks';
import { useRejectionReasonI18n } from '@/components/AsyncTitration/hooks/useRejectionReasonI18n';
import { usePatientMedications } from '@/pages/patients/PatientMedications/patientMedications.queries';
import { useReferenceMedications } from '@/pages/patients/PatientMedications/referenceMedications.queries';
import type {
  PatientMedications,
  ReferenceMedication,
} from '@/shared/generated/grpcGateway/medication.pb';
import type { PatientContextVitals } from '@/shared/generated/grpcGateway/telemetry.pb';
import { usePatientDetails } from '@/shared/hooks/queries';
import type { MedManagementDelegationUiMappingValue } from '@/shared/hooks/queries/med-management.queries';
import { useMedManagementDelegation } from '@/shared/hooks/queries/med-management.queries';
import { usePatientVitalsContext } from '@/shared/hooks/queries/vitalsContext.queries';

import { TEMPLATE_HELPERS } from '../../../NoteEditor/templates/engine';
import { TypeOfEncounter } from '../../../Notes.types';
import { getAsyncReviewNoteBody } from './getAsyncReviewNoteBody';
import { getSortedRelevantMedChanges } from './getSortedRelevantMedChanges';
import { getTitrationOutreachNoteBody } from './getTitrationOutreachNoteBody';
import type { AsyncTitrationEncounterType } from './types';

export function shouldShowAsyncTitration(
  encounterType?: TypeOfEncounter,
): encounterType is AsyncTitrationEncounterType {
  if (!encounterType) {
    return false;
  }

  return [
    TypeOfEncounter.ASYNC_REVIEW,
    TypeOfEncounter.TITRATION_OUTREACH,
  ].includes(encounterType);
}

export function useGetAsyncTitrationNoteBody(
  patientId: string,
  noteId: Maybe<number>,
) {
  const intl = useIntl();
  const rejectionReasonsI18n = useRejectionReasonI18n();
  const patientRejectionReasonsI18n = usePatientTitrationRejectionReasonsI18n();
  const { data: patientMeds } = usePatientMedications(patientId);
  const { data: patient } = usePatientDetails(patientId, true);
  const { medDelegation } = useMedManagementDelegation(patient);
  const { data: patientVitals } = usePatientVitalsContext(patientId);
  const { data: referenceMeds } = useReferenceMedications();

  if (
    !patientMeds ||
    !medDelegation ||
    !patientVitals ||
    !referenceMeds ||
    !noteId
  ) {
    return () => null;
  }

  return (
    encounterType: AsyncTitrationEncounterType,
    isNoShow: boolean,
    medReviewBody: Nullable<string>,
  ) =>
    getAsyncTitrationNoteBody(
      intl,
      noteId,
      rejectionReasonsI18n,
      patientRejectionReasonsI18n,
      patientMeds,
      patientVitals,
      referenceMeds.referenceMedications ?? [],
      encounterType,
      isNoShow,
      // isEndEarly isn't relevant here unless it's a CN visit,
      // which will be calling getAsyncTitrationNoteBody directly
      false,
      medReviewBody,
      medDelegation,
    );
}

export function getAsyncTitrationNoteBody(
  intl: IntlShape,
  noteId: number,
  rejectionReasonsI18n: ReturnType<typeof useRejectionReasonI18n>,
  patientRejectionReasonsI18n: ReturnType<
    typeof usePatientTitrationRejectionReasonsI18n
  >,
  patientMeds: PatientMedications,
  patientVitals: PatientContextVitals,
  referenceMeds: ReferenceMedication[],
  encounterType: AsyncTitrationEncounterType,
  isNoShow: boolean,
  isEndEarly: boolean,
  medReviewBody: Nullable<string>,
  medManagementDelegation?: MedManagementDelegationUiMappingValue,
) {
  if (!medReviewBody) {
    return null;
  }

  const changesWithAsyncTitration = getSortedRelevantMedChanges(
    encounterType,
    noteId,
    patientMeds.referencedMedications,
    referenceMeds,
  );

  if (!changesWithAsyncTitration.length) {
    return null;
  }

  const systolicAvg =
    patientVitals.contextVitals?.bloodPressure?.systolic?.avg30d;
  const diastolicAvg =
    patientVitals.contextVitals?.bloodPressure?.diastolic?.avg30d;
  const vitals = { bp: TEMPLATE_HELPERS.bp(systolicAvg, diastolicAvg) };

  if (encounterType === TypeOfEncounter.ASYNC_REVIEW) {
    return getAsyncReviewNoteBody(
      intl,
      changesWithAsyncTitration,
      vitals,
      medReviewBody,
      rejectionReasonsI18n,
      medManagementDelegation,
    ).toString();
  }

  if (
    [
      TypeOfEncounter.TITRATION_OUTREACH,
      TypeOfEncounter.CN_TITRATION_OUTREACH,
    ].includes(encounterType)
  ) {
    return getTitrationOutreachNoteBody(
      intl,
      changesWithAsyncTitration,
      vitals,
      medReviewBody,
      isNoShow,
      isEndEarly,
      patientRejectionReasonsI18n,
    ).toString();
  }

  return null;
}
