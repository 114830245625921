import type { ClinicalGoalReachedFormFields } from '../../../../Notes.types';
import { HfType } from '../../../../Notes.types';

type Params = {
  isChf?: boolean;
  isHtn?: boolean;
  isT2d?: boolean;
  hasRelevantMedChange: boolean;
  fields: Pick<
    ClinicalGoalReachedFormFields,
    | 'isBpGoalMet'
    | 'isT2dGoalMet'
    | 'hfType'
    | 'isGdmtGoalMet'
    | 'historyOfHospitalizationOrSymptoms'
  >;
};

export function shouldCollectNonTitrationReason({
  isChf,
  isHtn,
  isT2d,
  hasRelevantMedChange,
  fields,
}: Params) {
  if (hasRelevantMedChange) {
    return false;
  }

  if (isHtn && isT2d) {
    return shouldCollectForHtnAndT2d(fields.isBpGoalMet, fields.isT2dGoalMet);
  }

  if (isHtn) {
    return shouldCollectForHtn(fields.isBpGoalMet);
  }

  if (isT2d) {
    return shouldCollectForT2d(fields.isT2dGoalMet);
  }

  if (isChf) {
    return shouldCollectForChf(
      fields.hfType,
      fields.isBpGoalMet,
      fields.isGdmtGoalMet,
      fields.historyOfHospitalizationOrSymptoms,
    );
  }

  return false;
}

function shouldCollectForHtnAndT2d(
  isBpGoalMet: Maybe<boolean>,
  isT2dGoalMet: Maybe<boolean>,
) {
  return shouldCollectForHtn(isBpGoalMet) || shouldCollectForT2d(isT2dGoalMet);
}

function shouldCollectForHtn(isBpGoalMet: Maybe<boolean>) {
  return isBpGoalMet === false;
}

function shouldCollectForT2d(isT2dGoalMet: Maybe<boolean>) {
  return isT2dGoalMet === false;
}

function shouldCollectForChf(
  hfType: Maybe<HfType>,
  isBpGoalMet: Maybe<boolean>,
  isGdmtGoalMet: Maybe<boolean>,
  historyOfHospitalizationOrSymptoms: Maybe<boolean>,
) {
  if (!hfType) {
    return false;
  }

  if (
    hfType === HfType.Hfpef &&
    historyOfHospitalizationOrSymptoms === undefined
  ) {
    return false;
  }

  const requireGdmtAnswer =
    hfType === HfType.Hfref || historyOfHospitalizationOrSymptoms === true;
  if (requireGdmtAnswer && isGdmtGoalMet === undefined) {
    return false;
  }

  if (hfType === HfType.Hfpef && !historyOfHospitalizationOrSymptoms) {
    return !isBpGoalMet;
  }

  if (!isGdmtGoalMet) {
    return true;
  }

  return !isBpGoalMet;
}
