import { makeI18nHook } from '@/shared/hooks';
import {
  EndEncounterReason,
  EndEncounterType,
} from '@/shared/types/note.types';

export const useEndEncounterReasonI18nMapping = makeI18nHook(
  (intl) =>
    ({
      [EndEncounterReason.PatientUnableToSpeak]: intl.formatMessage({
        defaultMessage: 'Patient unable to speak at this time',
      }),
      [EndEncounterReason.SchedulingErrorDisenrollment]: intl.formatMessage({
        defaultMessage: 'Scheduling error / disenrollment',
      }),
      [EndEncounterReason.PatientInNonCadenceState]: intl.formatMessage({
        defaultMessage: 'Patient in non-Cadence state',
      }),
      [EndEncounterReason.PatientHospitalized]: intl.formatMessage({
        defaultMessage: 'Patient currently hospitalized',
      }),
      [EndEncounterReason.PatientRefusedAppointment]: intl.formatMessage({
        defaultMessage: 'Patient refused appointment',
      }),
      [EndEncounterReason.PatientDissatisfied]: intl.formatMessage({
        defaultMessage: 'Patient dissatisfied',
      }),
      [EndEncounterReason.TimeConstraints]: intl.formatMessage({
        defaultMessage: 'Time constraints',
      }),
      [EndEncounterReason.DeviceDelayTroubleshooting]: intl.formatMessage({
        defaultMessage: 'Device delay / troubleshooting',
      }),
      [EndEncounterReason.Other]: intl.formatMessage({
        defaultMessage: 'Other',
      }),
    }) as Record<EndEncounterReason, string>,
);

export const useEndEncounterTypeI18nMapping = makeI18nHook(
  (intl) =>
    ({
      [EndEncounterType.EndEarly]: intl.formatMessage({
        defaultMessage: 'Visit Incomplete',
      }),
      [EndEncounterType.NoShow]: intl.formatMessage({
        defaultMessage: 'No Show',
      }),
    }) as Record<EndEncounterType, string>,
);
