/* eslint-disable */
// @ts-nocheck
/*
* This file is a generated Typescript file for GRPC Gateway, DO NOT MODIFY
*/

import * as CadenceModelsV1Models from "./cadence/models/models.pb"
import * as GoogleProtobufTimestamp from "./google/protobuf/timestamp.pb"
import * as GoogleProtobufWrappers from "./google/protobuf/wrappers.pb"
import * as GoogleTypeDate from "./google/type/date.pb"
import * as fm from "./fetch.pb"

export enum PatientGoal {
  PATIENT_GOAL_UNSPECIFIED = "PATIENT_GOAL_UNSPECIFIED",
  BECOME_MORE_PHYSICALLY_ACTIVE = "BECOME_MORE_PHYSICALLY_ACTIVE",
  TAKE_MEDICATIONS_MORE_CONSISTENTLY = "TAKE_MEDICATIONS_MORE_CONSISTENTLY",
  LOWER_MY_WEIGHT = "LOWER_MY_WEIGHT",
  IMPROVE_MY_FOOD_CHOICES = "IMPROVE_MY_FOOD_CHOICES",
  REDUCE_MY_STRESS = "REDUCE_MY_STRESS",
  GET_BETTER_SLEEP = "GET_BETTER_SLEEP",
  QUIT_SMOKING = "QUIT_SMOKING",
  BETTER_UNDERSTAND_MY_DISEASE = "BETTER_UNDERSTAND_MY_DISEASE",
  BETTER_UNDERSTAND_MY_DISEASE_CHF = "BETTER_UNDERSTAND_MY_DISEASE_CHF",
  BETTER_UNDERSTAND_MY_DISEASE_HTN = "BETTER_UNDERSTAND_MY_DISEASE_HTN",
  BETTER_UNDERSTAND_MY_DISEASE_T2D = "BETTER_UNDERSTAND_MY_DISEASE_T2D",
}

export enum NoteVisitType {
  VISIT_TYPE_UNSPECIFIED = "VISIT_TYPE_UNSPECIFIED",
  REGULAR_NP_VISIT = "REGULAR_NP_VISIT",
  INITIAL_NP_VISIT = "INITIAL_NP_VISIT",
  RESULTS_FOLLOW_UP = "RESULTS_FOLLOW_UP",
  CCM_CARE_PLAN = "CCM_CARE_PLAN",
  REGULAR_CCM_VISIT = "REGULAR_CCM_VISIT",
  INITIAL_CN_VISIT = "INITIAL_CN_VISIT",
  REGULAR_CN_VISIT = "REGULAR_CN_VISIT",
  CN_CHF_VISIT = "CN_CHF_VISIT",
  ALERT_DOCUMENTATION = "ALERT_DOCUMENTATION",
  PATIENT_ENROLLMENT = "PATIENT_ENROLLMENT",
  VIRTUAL_ENROLLMENT = "VIRTUAL_ENROLLMENT",
  PATIENT_INBOUND = "PATIENT_INBOUND",
  ENROLLER_VISIT = "ENROLLER_VISIT",
  PS_VISIT = "PS_VISIT",
  WELCOME_CALL = "WELCOME_CALL",
  DISENROLLMENT = "DISENROLLMENT",
  ENROLLMENT_CALL = "ENROLLMENT_CALL",
  INITIAL_CN_CHF_VISIT = "INITIAL_CN_CHF_VISIT",
  APCM_CARE_PLAN = "APCM_CARE_PLAN",
  REGULAR_APCM_VISIT = "REGULAR_APCM_VISIT",
}

export enum NoteStatus {
  STATUS_UNSPECIFIED = "STATUS_UNSPECIFIED",
  DRAFT = "DRAFT",
  PUBLISHED = "PUBLISHED",
  AUTOSAVED = "AUTOSAVED",
}

export enum NoteNoteType {
  NOTE_TYPE_UNSPECIFIED = "NOTE_TYPE_UNSPECIFIED",
  STANDARD = "STANDARD",
  ALERT_DISMISSAL = "ALERT_DISMISSAL",
  ALERT_RESOLUTION = "ALERT_RESOLUTION",
  ALERT_AUTO_RESOLUTION = "ALERT_AUTO_RESOLUTION",
}

export enum NoteNoteSyncStatus {
  NOTE_SYNC_STATUS_UNSPECIFIED = "NOTE_SYNC_STATUS_UNSPECIFIED",
  PENDING = "PENDING",
  SYNCING = "SYNCING",
  COMPLETE = "COMPLETE",
  FAILED = "FAILED",
  SKIPPED = "SKIPPED",
  NEEDS_RESYNC = "NEEDS_RESYNC",
  IN_TRIAGE = "IN_TRIAGE",
  WAITING = "WAITING",
  RETRYING = "RETRYING",
}

export type CreateNoteRequest = {
  note?: Note
  parent?: string
}

export type EncounterModuleInstance = {
  encounterModuleId?: number
  inputs?: string
}

export type CreateAutosavedEncounterResponse = {
  noteId?: number
}

export type GetPatientClinicalGoalRequest = {
  name?: string
}

export type PatientClinicalGoal = {
  name?: string
  isGdmtGoalMet?: boolean
  efLower?: number
  efUpper?: number
}

export type GetCurrentPatientGoalRequest = {
  name?: string
}

export type CurrentPatientGoal = {
  name?: string
  patientGoal?: PatientGoal
}

export type ListPatientNotesRequest = {
  parent?: string
  pageSize?: number
  pageToken?: string
  filter?: string
  orderBy?: string
}

export type ListPatientNotesResponse = {
  patientNotes?: Note[]
  nextPageToken?: string
  totalSize?: number
}

export type ListPatientAlertNotesRequest = {
  parent?: string
  pageSize?: number
  pageToken?: string
  filter?: string
  orderBy?: string
}

export type ListPatientAlertNotesResponse = {
  patientAlertNotes?: AlertNote[]
  nextPageToken?: string
  totalSize?: number
}

export type AlertNote = {
  name?: string
  noteTitle?: string
  noteBody?: string
  noteCreateUser?: string
  noteCreateTime?: GoogleProtobufTimestamp.Timestamp
  alertCreateTime?: GoogleProtobufTimestamp.Timestamp
  alertTags?: string[]
  careProviderGivenName?: string
  careProviderFamilyName?: string
  careProviderRole?: CadenceModelsV1Models.CareProviderRole
  noteEmrSyncStatus?: NoteNoteSyncStatus
}

export type NoteSymptom = {
  hasSymptom?: boolean
  description?: string
}

export type NoteEmergencyVisitEmergencyVisitTypes = {
  urgentCare?: boolean
  emergencyDept?: boolean
  hospitalAdmission?: boolean
}

export type NoteEmergencyVisit = {
  admissionStartDate?: GoogleTypeDate.Date
  location?: GoogleProtobufWrappers.StringValue
  visitTypes?: NoteEmergencyVisitEmergencyVisitTypes
}

export type Note = {
  name?: string
  appointmentId?: GoogleProtobufWrappers.StringValue
  status?: NoteStatus
  createTime?: GoogleProtobufTimestamp.Timestamp
  updateTime?: GoogleProtobufTimestamp.Timestamp
  createUser?: string
  updateUser?: string
  noteType?: NoteNoteType
  emrSyncStatus?: NoteNoteSyncStatus
  title?: string
  body?: GoogleProtobufWrappers.StringValue
  patientNoShow?: boolean
  visitType?: NoteVisitType
  emergencyVisit?: NoteEmergencyVisit
  symptoms?: {[key: string]: NoteSymptom}
  rtfBodyJson?: GoogleProtobufWrappers.StringValue
  encounterModuleInstances?: EncounterModuleInstance[]
  noShowAppointmentId?: GoogleProtobufWrappers.StringValue
  timeElapsed?: string
  originatingTaskId?: GoogleProtobufWrappers.StringValue
}

export type NotePublished = {
  id?: number
}

export class NoteService {
  static ListPatientNotes(req: ListPatientNotesRequest, initReq?: fm.InitReq): Promise<ListPatientNotesResponse> {
    return fm.fetchReq<ListPatientNotesRequest, ListPatientNotesResponse>(`/rpm/v1/${req["parent"]}/notes?${fm.renderURLSearchParams(req, ["parent"])}`, {...initReq, method: "GET"})
  }
  static ListPatientAlertNotes(req: ListPatientAlertNotesRequest, initReq?: fm.InitReq): Promise<ListPatientAlertNotesResponse> {
    return fm.fetchReq<ListPatientAlertNotesRequest, ListPatientAlertNotesResponse>(`/rpm/v1/${req["parent"]}/alertNotes?${fm.renderURLSearchParams(req, ["parent"])}`, {...initReq, method: "GET"})
  }
  static GetPatientClinicalGoal(req: GetPatientClinicalGoalRequest, initReq?: fm.InitReq): Promise<PatientClinicalGoal> {
    return fm.fetchReq<GetPatientClinicalGoalRequest, PatientClinicalGoal>(`/rpm/v1/${req["name"]}?${fm.renderURLSearchParams(req, ["name"])}`, {...initReq, method: "GET"})
  }
  static GetCurrentPatientGoal(req: GetCurrentPatientGoalRequest, initReq?: fm.InitReq): Promise<CurrentPatientGoal> {
    return fm.fetchReq<GetCurrentPatientGoalRequest, CurrentPatientGoal>(`/rpm/v1/${req["name"]}?${fm.renderURLSearchParams(req, ["name"])}`, {...initReq, method: "GET"})
  }
  static CreateNote(req: CreateNoteRequest, initReq?: fm.InitReq): Promise<Note> {
    return fm.fetchReq<CreateNoteRequest, Note>(`/rpm/v1/${req["parent"]}/notes`, {...initReq, method: "POST", body: JSON.stringify(req["note"], fm.replacer)})
  }
}