import type { HelperDeclareSpec } from 'handlebars';
import type { IntlShape } from 'react-intl';

import type { SectionStepsState } from '@/shared/common/Wizard/state';
import type {
  PatientMedications,
  ReferenceMedication,
} from '@/shared/generated/grpcGateway/medication.pb';
import type { PatientContextVitals } from '@/shared/generated/grpcGateway/telemetry.pb';

import { titrationOutreachActionPlan } from './titrationOutreachActionPlan';
import { titrationOutreachEarlyEndCall } from './titrationOutreachEarlyEndCall';
import { titrationOutreachLabwork } from './titrationOutreachLabwork';
import { titrationOutreachPatientNotes } from './titrationOutreachPatientNotes';
import { titrationOutreachPharmacy } from './titrationOutreachPharmacy';
import { titrationOutreachTitrationReview } from './titrationOutreachTitrationReview';

interface TemplateHelpers extends HelperDeclareSpec {
  titrationOutreachActionPlan: (
    data: SectionStepsState,
    intl: IntlShape,
  ) => string;
  titrationOutreachEarlyEndCall: (
    data: SectionStepsState,
    intl: IntlShape,
  ) => string;
  titrationOutreachPatientNotes: (data: SectionStepsState) => string;
  titrationOutreachPharmacy: (data: SectionStepsState) => string;
  titrationOutreachLabwork: (data: SectionStepsState) => string;
  titrationOutreachTitrationReview: (
    intl: IntlShape,
    data: SectionStepsState,
    noteId: number,
    patientMeds: PatientMedications,
    patientVitals: PatientContextVitals,
    referenceMeds: ReferenceMedication[],
  ) => string;
}

export const CN_TITRATION_OUTREACH_HELPERS: TemplateHelpers = {
  titrationOutreachActionPlan,
  titrationOutreachEarlyEndCall,
  titrationOutreachPatientNotes,
  titrationOutreachPharmacy,
  titrationOutreachLabwork,
  titrationOutreachTitrationReview,
};
