import { hasT2dWithScaleOnly } from '@/pages/patients/PatientProfile/CNNotesSidebarPanel/shared/condition.utils';
import { usePatientDetails } from '@/shared/hooks/queries';
import { useAssignedDevices } from '@/shared/hooks/queries/devices.queries';
import { getRpmConditions } from '@/shared/patient/conditions.utils';

import { useCNFormContext } from '../../../CNFormContext';

export function useAdditionalScriptContext() {
  const { patientId } = useCNFormContext();
  const { data: patient, isLoading: isLoadingPatient } = usePatientDetails(
    patientId,
    true,
    Boolean(patientId),
  );
  const { data: devices, isLoading: isLoadingDevices } = useAssignedDevices(
    patientId,
    {},
  );
  const conditions = getRpmConditions(patient);
  const isLoading = isLoadingPatient || isLoadingDevices;

  return {
    hasT2dWithScaleOnly: hasT2dWithScaleOnly(conditions, devices?.data),
    conditions,
    devices: devices?.data,
    isLoading,
  };
}
