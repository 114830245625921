import type { ReactNode } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { Form } from '@/shared/common/Form';
import { tiledRadioLabel } from '@/shared/jsStyle/ui.css';

import {
  goalDetailsInput,
  goalDetailsStyle,
  goalInfo,
  line,
} from '../ClinicalGoalReachedForm.css';
import { useVitalAvgAndGoals } from '../useVitalAvgAndGoals';
import { NoGoalsSetMessage } from './NoGoalsSetMessage';
import { STRING_TO_BOOL_MAP } from './stringToBoolMap';

type Props = {
  isGoalMetFieldName: string;
  goalDetailsFieldName: string;
  isGoalMetFieldValue?: boolean;
  description: string;
  vitalSummary?: ReactNode;
  areGoalsDefined?: boolean;
};

export function Goal({
  isGoalMetFieldName,
  isGoalMetFieldValue,
  goalDetailsFieldName,
  description,
  vitalSummary,
  areGoalsDefined = true,
}: Props) {
  const intl = useIntl();

  const { healthSystemId } = useVitalAvgAndGoals();
  if (!healthSystemId) {
    return null;
  }
  if (!areGoalsDefined) {
    return (
      <div className={goalInfo}>
        <NoGoalsSetMessage healthSystemId={healthSystemId} />
      </div>
    );
  }

  const goalDetails =
    isGoalMetFieldValue !== undefined ? (
      <div className={goalDetailsStyle}>
        <Form.TextArea
          classes={{ input: goalDetailsInput }}
          maxLength={200}
          size={12}
          name={goalDetailsFieldName}
          label={<FormattedMessage defaultMessage="Goal details (optional)" />}
          placeholder={intl.formatMessage({
            defaultMessage: 'Enter optional details',
          })}
        />
      </div>
    ) : null;

  return (
    <div>
      <Form.RadioGroup
        size={12}
        name={isGoalMetFieldName}
        label={`${description} *`}
        orientation="horizontal"
        valueMap={STRING_TO_BOOL_MAP}
      >
        <Form.Radio
          value="true"
          classes={{ label: tiledRadioLabel.horizontal }}
        >
          <FormattedMessage defaultMessage="Yes" />
        </Form.Radio>
        <Form.Radio
          value="false"
          classes={{ label: tiledRadioLabel.horizontal }}
        >
          <FormattedMessage defaultMessage="No" />
        </Form.Radio>
      </Form.RadioGroup>
      {(vitalSummary || goalDetails) && (
        <div className={goalInfo}>
          {vitalSummary}
          {goalDetails && vitalSummary && <hr className={line} />}
          {goalDetails}
        </div>
      )}
    </div>
  );
}
