import type { PatientDetails } from '@/shared/generated/grpcGateway/pms.pb';

import { PatientInfoTags } from '../PatientInfoTags';
import { DynamicPatientInfoFields } from './DynamicPatientInfoFields';
import { container } from './DynamicPatientInfoSummary.css';
import { MedDelegationNoteInfoField } from './MedDelegationNoteInfoField';

type PatientInfoSummaryProps = {
  patientDetails: PatientDetails;
};

export function DynamicPatientInfoSummary({
  patientDetails,
}: PatientInfoSummaryProps) {
  const patientAddresses = patientDetails?.addresses || [];
  return (
    <div className={container}>
      <PatientInfoTags patientDetails={patientDetails} />
      <MedDelegationNoteInfoField patientDetails={patientDetails} />
      <DynamicPatientInfoFields
        patientDetails={patientDetails}
        patientAddresses={patientAddresses}
      />
    </div>
  );
}
