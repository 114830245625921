import { FormattedMessage } from 'react-intl';

import { CommonInfoField } from '@/shared/common/CommonInfoField';
import type { Condition } from '@/shared/types/clinicalprofile.types';

import { formatConditions } from '../utils/formatting.utils';

type Props = {
  conditions?: Condition[];
};

export function ConditionInfoField({ conditions }: Props) {
  return (
    <CommonInfoField
      label={<FormattedMessage defaultMessage="Condition" />}
      text={formatConditions(conditions)}
    />
  );
}
