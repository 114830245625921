import findKey from 'lodash/findKey';
import type { ReactNode } from 'react';
import { useFormContext } from 'react-hook-form';

import { RadioGroup as SharedRadioGroup } from '@/shared/tempo/molecule/RadioGroup';

import type { ValueMappable } from '../../form.types';
import type { BaseFieldProps } from '../BaseField';
import { BaseField } from '../BaseField';

type Props<TVal> = {
  children: ReactNode;
  orientation?: 'horizontal' | 'vertical';
  description?: ReactNode;
  classes?: {
    label?: string;
  };
} & BaseFieldProps<TVal> &
  ValueMappable;

export function RadioGroup<TVal>({
  children,
  label,
  orientation,
  description,
  classes,
  valueMap,
  ...baseProps
}: Props<TVal>) {
  const form = useFormContext();
  const errorMessage = form.formState.errors[baseProps.name]?.message;
  const getSerializedValue = (value: unknown) => {
    if (!valueMap) {
      return value as string;
    }

    return findKey(valueMap, (val) => val === value);
  };

  return (
    <BaseField {...baseProps} label="">
      {({ controller: { field }, isRequired, renderError }) => {
        renderError(); // Removing validation error this way from BaseField to use from SharedRadioGroup instead
        return (
          <SharedRadioGroup
            label={label}
            orientation={orientation}
            value={getSerializedValue(field.value)}
            onChange={(value) => {
              field.onChange(valueMap?.[value] ?? value);
            }}
            isRequired={isRequired}
            description={description}
            classes={classes}
            errorMessage={errorMessage}
          >
            {children}
          </SharedRadioGroup>
        );
      }}
    </BaseField>
  );
}
