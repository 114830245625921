**Cadence Encounter Type:**

Titration outreach

{{ titrationOutreachTitrationReview intl data noteId patientMedications patientVitalsContext referenceMedications }}

{{ titrationOutreachPharmacy data }}

{{ titrationOutreachLabwork data }}

{{ titrationOutreachPatientNotes data }}

{{ titrationOutreachActionPlan data intl }}

{{ titrationOutreachEarlyEndCall data intl }}
