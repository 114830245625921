import { format, isValid } from 'date-fns';

import ScheduledIcon from '@/shared/assets/svgs/alarm.svg?react';

import { isoToLocalDate } from '../../utils';
import { BaseIndicator } from './BaseIndicator';
import type { container, indicator } from './styles.css';

type Props = {
  scheduledTime?: string;
  variant?: keyof typeof container & keyof typeof indicator;
};

export function ScheduledIndicator({ scheduledTime, variant }: Props) {
  const scheduledDate = isoToLocalDate(scheduledTime || '');

  return (
    <BaseIndicator
      variant={variant ?? 'simple'}
      tooltip={
        isValid(scheduledDate) ? format(scheduledDate, 'MMM d, yyyy') : ''
      }
    >
      <ScheduledIcon />
    </BaseIndicator>
  );
}
