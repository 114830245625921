import { MedPermissions } from '@/pages/patients/PatientMedications/PatientMedicationsList';
import { TypeOfEncounter } from '@/pages/patients/patientDetails/ui/Notes/Notes.types';
import { AsyncTitrationAsyncTitrationStatus as TitrationStatus } from '@/shared/generated/grpcGateway/medication.pb';
import { NoteStatus } from '@/shared/generated/grpcGateway/note.pb';
import { useFlags } from '@/shared/hooks';

import { isAsyncTitrationPending } from '../statuses';
import type { TitrationRecommendation } from './useTitrationRecommendation';

type ShouldDisplay = {
  shouldDisplay: boolean;
  readOnly?: boolean;
};

export function useShouldDisplayAsyncTitration(
  asyncTitration: TitrationRecommendation,
  permissions?: MedPermissions,
  noteId?: Maybe<number>,
  typeOfEncounter?: TypeOfEncounter,
): ShouldDisplay {
  const { asyncTitrations: enableAsyncTitrations } = useFlags();
  const {
    status,
    isWritable,
    initialReviewNoteId: initialNoteId,
    initialReviewNoteStatus: initialNoteStatus,
    consentRequestNoteId: consentNoteId,
    consentRequestNoteStatus: consentNoteStatus,
  } = asyncTitration;

  if (!enableAsyncTitrations || !status || !isWritable) {
    return { shouldDisplay: false };
  }

  const hasTitratePermissions = permissions === MedPermissions.Titrate;
  const hasNoteId = typeof noteId !== 'undefined';
  const isCurrentNote =
    hasNoteId && (noteId === initialNoteId || noteId === consentNoteId);
  const isAsyncReview = typeOfEncounter === TypeOfEncounter.ASYNC_REVIEW;

  if (status === TitrationStatus.NEW) {
    return {
      shouldDisplay: hasNoteId && isAsyncReview,
      readOnly: !hasTitratePermissions,
    };
  }

  if (status === TitrationStatus.INITIALLY_REVIEWED) {
    const isReviewPublished = initialNoteStatus === NoteStatus.PUBLISHED;
    const isRnOutreach = typeOfEncounter === TypeOfEncounter.TITRATION_OUTREACH;
    const isCnOutreach =
      typeOfEncounter === TypeOfEncounter.CN_TITRATION_OUTREACH;
    const cnCanObtainConsent =
      isCnOutreach && permissions === MedPermissions.ProactiveTitrationConsent;
    const canObtainConsent =
      isReviewPublished && (isRnOutreach || cnCanObtainConsent);
    const canUndoReview = isCurrentNote && !isReviewPublished;

    return {
      shouldDisplay: isAsyncTitrationPending(asyncTitration, noteId),
      readOnly: !canObtainConsent && !canUndoReview,
    };
  }

  if (status === TitrationStatus.REJECTED_ON_INITIAL_REVIEW) {
    return {
      shouldDisplay: isCurrentNote && isAsyncReview,
      readOnly: false,
    };
  }

  if (
    status === TitrationStatus.PATIENT_REJECTED ||
    status === TitrationStatus.PATIENT_CONSENTED
  ) {
    const isConsentPublished = consentNoteStatus === NoteStatus.PUBLISHED;
    const canUndoConsentDecision = isCurrentNote && !isConsentPublished;

    return {
      shouldDisplay: canUndoConsentDecision,
      readOnly: false,
    };
  }

  return { shouldDisplay: false };
}
