{{!-- For RPM only --}}
{{#ifExactMatch programTypes (array "RPM")}}
    <p>Patient of [provider] disenrolled in the Cadence Remote Patient Monitoring program on [date]. Cadence processed the disenrollment, and this note serves as notification that vitals readings taken on Cadence-issued devices will no longer be monitored by the Cadence Team.</p>
{{/ifExactMatch}}

{{!-- For CCM only --}}
{{#ifExactMatch programTypes (array "CCM")}}
    <p>Patient of [provider] disenrolled in the Cadence Chronic Care Management program on [date]. Cadence has processed the disenrollment.</p>
{{/ifExactMatch}}

{{!-- For APCM only --}}
{{#ifExactMatch programTypes (array "APCM")}}
  <p>Patient of [provider] disenrolled in the Cadence Advanced Primary Care Management program on [date]. Cadence has processed the disenrollment.</p>
{{/ifExactMatch}}

{{!-- For RPM+CCM --}}
{{#ifExactMatch programTypes (array "RPM" "CCM")}}
    <p>Patient of [provider] disenrolled in the Cadence Remote Patient Monitoring and Chronic Care Management program on [date]. Cadence processed the disenrollments, and this note serves as notification that vitals readings taken on Cadence-issued devices from the Cadence Remote Patient Monitoring Program will no longer be monitored by the Cadence Team.</p>
{{/ifExactMatch}}

{{!-- For RPM+APCM --}}
{{#ifExactMatch programTypes (array "RPM" "APCM")}}
    <p>Patient of [provider] disenrolled in the Cadence Remote Patient Monitoring and Advanced Primary Care Management program on [date]. Cadence processed the disenrollments, and this note serves as notification that vitals readings taken on Cadence-issued devices from the Cadence Remote Patient Monitoring Program will no longer be monitored by the Cadence Team.</p>
{{/ifExactMatch}}

<p>Please reach out to the Cadence Team with any questions or concerns. Thank you.</p>