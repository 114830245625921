import sortBy from 'lodash/sortBy';

import { AsyncTitrationPatientRejectedTitrationReason as Reason } from '@/shared/generated/grpcGateway/medication.pb';

import { usePatientTitrationRejectionReasonsI18n } from '../hooks';

export function useOptions() {
  const reasons = usePatientTitrationRejectionReasonsI18n();

  return sortBy(
    Object.entries(reasons)
      .filter(
        ([key]) => key !== Reason.PATIENT_REJECTED_TITRATION_REASON_UNSPECIFIED,
      )
      .map(([key, label]) => ({ key, label })),
    'label',
  );
}
