import cx from 'classnames';
import { type ReactElement, cloneElement } from 'react';

import { iconContainer } from './TagIcon.css';

export type TagIconPosition = 'left' | 'right' | 'middle';

type Props = {
  className?: string;
  children: ReactElement;
  position?: TagIconPosition;
};

const SVG_SIZE = { width: 16, height: 16 };

export function TagIcon({ children, className, position = 'middle' }: Props) {
  return (
    <div className={cx(className, iconContainer[position])}>
      {cloneElement(children, SVG_SIZE)}
    </div>
  );
}
