import AlertIcon from '@/shared/assets/svgs/alertTriangle.svg?react';
import { type PatientDetails } from '@/shared/generated/grpcGateway/pms.pb';
import { usePatientReferringPhysician } from '@/shared/hooks/queries';
import { color as themeColor } from '@/shared/tempo/theme';
import { grpcNameToId } from '@/shared/utils/grpc';

import {
  medDelegationNoteContainer,
  medDelegationNoteText,
} from './MedDelegationNoteInfoField.css';

type Props = {
  patientDetails: PatientDetails;
};

export function MedDelegationNoteInfoField({ patientDetails }: Props) {
  const patientId = patientDetails.name
    ? grpcNameToId(patientDetails.name)
    : '';
  const { referringPhysician } = usePatientReferringPhysician(patientId || '');

  const medManagementDelegationNote =
    referringPhysician?.preferences?.med_management_delegation_note;

  return (
    <>
      {medManagementDelegationNote && (
        <div className={medDelegationNoteContainer}>
          <AlertIcon
            fill={themeColor.Palette.Danger[500]}
            width="16px"
            height="16px"
          />

          <div className={medDelegationNoteText}>
            {medManagementDelegationNote}
          </div>
        </div>
      )}
    </>
  );
}
