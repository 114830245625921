import { FormattedMessage } from 'react-intl';

import { CommonInfoField, PLACEHOLDER } from '@/shared/common/CommonInfoField';

import { formatList } from './utils/formatting.utils';

const MAX_DISPLAYABLE_MRNS = 3;

type Props = {
  mrns?: string[];
};

export function MrnsInfoField({ mrns }: Props) {
  return (
    <CommonInfoField
      label={
        <FormattedMessage
          defaultMessage="{count, plural, =0 {MRN} one {MRN} other {MRNs}}"
          values={{ count: mrns?.length }}
        />
      }
      text={mrns?.length ? formatList(mrns, MAX_DISPLAYABLE_MRNS) : PLACEHOLDER}
    />
  );
}
