import { FormattedMessage } from 'react-intl';

import { useFlags } from '@/shared/hooks';

import { EDITABLE_UNTIL_X_DAYS_AFTER_BILLING_MONTH } from './timeTracking.utils';

export function EditableMessage() {
  const { timeTrackingLateEdits } = useFlags();
  if (timeTrackingLateEdits) {
    return (
      <FormattedMessage defaultMessage="You may edit information for up to 8 months due to billing activity." />
    );
  }
  return (
    <FormattedMessage
      defaultMessage="You may edit information up to {days} days after the start of the following month due to billing activity."
      values={{ days: EDITABLE_UNTIL_X_DAYS_AFTER_BILLING_MONTH }}
    />
  );
}
