import cx from 'classnames';
import type { ReactElement, ReactText } from 'react';

import { FormControlLabel, Checkbox as MUICheckbox } from '@/deprecated/mui';

import type { BaseFieldProps } from '../BaseField';
import { BaseField } from '../BaseField';
import { labelText } from '../BaseField/styles.css';

type Props = {
  disabled?: boolean;
  sectionLabel?: ReactElement | ReactText;
  labelPlacement?: 'end' | 'start' | 'top' | 'bottom';
  label: ReactElement | ReactText;
  useGrid?: boolean;
} & Omit<BaseFieldProps<boolean>, 'required'>;

export function Checkbox({
  disabled,
  label,
  sectionLabel,
  labelPlacement,
  ...baseProps
}: Props) {
  return (
    <BaseField {...baseProps} label={sectionLabel || ''}>
      {({ controller: { field } }) => (
        <FormControlLabel
          label={label}
          labelPlacement={labelPlacement}
          classes={{ label: labelText.option }}
          disabled={disabled}
          className={cx(labelText.option, baseProps.classes?.label)}
          control={
            <MUICheckbox
              name={baseProps.name}
              checked={field.value === true}
              onChange={(_, isChecked) => field.onChange(isChecked)}
            />
          }
        />
      )}
    </BaseField>
  );
}
