import { useIntl } from 'react-intl';

import { logger } from '@/logger';

import type { RequirableEncounterModule } from '../NoteEditor/VisitLayout/EncounterModule';
import { useEncounterModuleIntlMsgs } from '../NoteEditor/VisitLayout/useEncounterModuleIntlMessages';
import {
  EncounterModuleId,
  type EncounterModuleInstance,
  type EncounterTypeInputs,
} from '../Notes.types';
import { isAPCMVisit, isCCMVisit } from '../utils/encounterTypeUtils';
import { FormattedClinicalGoalReachedInputs } from './FormattedClinicalGoalReachedInputs';
import { modulePreviewSection } from './NotePreview.css';
import { formatEncounterTypeInputs } from './formatEncounterTypeInputs.util';
import { formatHospitalizationInputs } from './formatHospitalizationInputs.util';
import { formatMedicationsInputs } from './formatMedicationsInputs.util';
import { formatSymptomsInputs } from './formatSymptomsInputs.util';

type Props<T> = {
  encounterModuleId: EncounterModuleId;
  encounterTypeInstance: Maybe<EncounterModuleInstance<EncounterTypeInputs>>;
  inputs: T;
};
export function InputsPreview<T extends {}>({
  encounterModuleId,
  encounterTypeInstance,
  inputs,
}: Props<T>) {
  const encounterTypeInputs = encounterTypeInstance?.inputs;
  const encounterType = encounterTypeInputs?.type_of_encounter;
  const isPatientNoShow = encounterTypeInputs?.patient_no_show === true;

  const messages = useEncounterModuleIntlMsgs(
    encounterModuleId as RequirableEncounterModule,
    encounterType,
  );

  const intl = useIntl();
  let content;
  switch (encounterModuleId) {
    case EncounterModuleId.Hospitalization:
      content = formatHospitalizationInputs(
        inputs,
        messages.title,
        encounterType,
        isPatientNoShow,
        encounterTypeInputs?.has_emergency_visits_to_report === false,
      );
      break;
    case EncounterModuleId.Symptoms:
      content = formatSymptomsInputs(
        inputs,
        messages.title,
        encounterType,
        isPatientNoShow,
        encounterTypeInputs?.has_symptoms_to_report,
      );
      break;
    case EncounterModuleId.Medications:
      content = formatMedicationsInputs(
        inputs,
        isCCMVisit(encounterType) || isAPCMVisit(encounterType)
          ? messages.title
          : // Special casing this until we get approval that we want to change the RPM note to "Disease-Specific medications"
            intl.formatMessage({
              defaultMessage: 'Medications',
            }),
        encounterType,
        isPatientNoShow,
        encounterTypeInputs?.has_meds_to_report === false,
      );
      break;
    case EncounterModuleId.ClinicalGoalReached:
      content = <FormattedClinicalGoalReachedInputs {...inputs} />;
      break;
    case EncounterModuleId.EncounterType:
      content = formatEncounterTypeInputs(inputs);
      break;
    default:
      logger.info(
        `Module with id ${encounterModuleId} not implemented in NotePreview`,
      );
  }

  return <div className={modulePreviewSection}>{content}</div>;
}
