import cx from 'classnames';
import type { ReactElement, ReactText } from 'react';
import { useCallback } from 'react';
import type { ControllerRenderProps } from 'react-hook-form';

import {
  FormControlLabel,
  FormGroup,
  Checkbox as MUICheckbox,
} from '@/deprecated/mui';

import type { BaseFieldProps } from '../BaseField';
import { BaseField } from '../BaseField';
import { labelText } from '../BaseField/styles.css';
import { addBottomPadding, removeBottomPadding } from './CheckboxGroup.css';

export type CheckboxOption = {
  label: ReactElement | ReactText;
  value: string;
  disabled?: boolean;
  withElement?: ReactElement | ReactText;
};

type CheckboxGroupProps<TVal extends string | string[]> = {
  options: CheckboxOption[];
  compact?: boolean;
} & BaseFieldProps<TVal>;

export function CheckboxGroup<TVal extends string | string[]>({
  compact,
  options,
  isDisabled,
  ...baseProps
}: CheckboxGroupProps<TVal>) {
  const onChange = useCallback(
    (value: string, isChecked: boolean, field: ControllerRenderProps) => {
      const newChecked = [...field.value];
      const idx = newChecked.indexOf(value);

      if (isChecked && idx === -1) {
        newChecked.push(value);
      } else if (!isChecked && idx > -1) {
        newChecked.splice(idx, 1);
      }

      field.onChange(newChecked);
    },
    [],
  );

  return (
    <BaseField
      {...baseProps}
      className={cx(baseProps.className, {
        [addBottomPadding]: !compact,
        [removeBottomPadding]: compact,
      })}
    >
      {({ controller: { field } }) => (
        <FormGroup row={compact ?? false}>
          {options.map((option) => (
            <>
              <FormControlLabel
                key={option.value}
                label={option.label}
                classes={{ label: labelText.option }}
                control={
                  <MUICheckbox
                    name={baseProps.name}
                    value={option.value}
                    disabled={isDisabled || Boolean(option.disabled)}
                    checked={field.value?.indexOf(option.value) >= 0}
                    onChange={(_, isChecked) =>
                      onChange(option.value, isChecked, field)
                    }
                  />
                }
              />
              {option.withElement &&
                field.value?.indexOf(option.value) >= 0 && (
                  <>{option.withElement}</>
                )}
            </>
          ))}
        </FormGroup>
      )}
    </BaseField>
  );
}
