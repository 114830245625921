import { endOfDay, formatISO, startOfDay } from 'date-fns';
import isEqual from 'lodash/isEqual';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import { useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';

import { cnNoteKeys } from '@/pages/patients/PatientProfile/CNNotesSidebarPanel/shared/querykeys';
import { appointmentKeys } from '@/pages/patients/PatientProfile/PatientScheduling/appointments.queries';
import { useFormFromConfig } from '@/shared/common/Form/FormContainer';
import { usePrevious } from '@/shared/hooks';
import { usePatientDetails } from '@/shared/hooks/queries';
import { autosavedNotesQueryKey } from '@/shared/hooks/queries/autosave-notes.queries';
import { CARE_PLAN_QUERY_KEY_BASE } from '@/shared/hooks/queries/carePlan.queries';
import { useCanPublishToEhr } from '@/shared/hooks/useCanPublishToEhr';
import { useCurrentUser } from '@/shared/hooks/useCurrentUser';
import type { TimerState } from '@/shared/notes/Timer';
import { EndEncounterType } from '@/shared/types/note.types';
import type { RouteParam } from '@/shared/types/route.types';

import { useNoteEditorContext } from '../../NoteEditorContext';
import { EditableNoteType } from '../../Notes.types';
import { PATIENT_NOTES_QUERY_KEY_BASE } from '../../note.queries';
import { getEncounterType } from '../../utils/encounterTypeUtils';
import { NoteEditorHeader } from '../NoteEditorHeader';
import {
  CloseConfirmationDialog,
  PublishConfirmationDialog,
  ReplaceDraftNoteConfirmationDialog,
} from '../dialogs';
import { EndEarlyConfirmationDialog } from '../dialogs/EndEarlyConfirmationDialog';
import {
  type EndEncounterFormFields,
  getEndEncounterEarlyFormConfig,
} from '../dialogs/EndEarlyConfirmationDialog/formConfig';
import { useGetPublishDialogConfirmations } from '../dialogs/PublishConfirmationDialog/confirmation.utils';
import { useCloseAndClearNoteEditor } from '../hooks/noteEditorVisibility.hooks';
import { useEncounterModuleInstances } from '../hooks/useEncounterModuleInstances.hook';
import type { NoteFormValues, SetNoteFormValue } from '../noteFormState';
import { requireMedActionsInEncounter } from '../utils/medReviewUtils';
import type { NoteFormValidationResult } from '../validation';
import { NoteFormSubmissionType } from '../validation';
import { setPatientNoShow } from './setPatientNoShow';
import { useSubmitHandlers } from './useSubmitHandlers';

enum ModalType {
  CloseConfirmation = 'CloseConfirmation',
  PublishConfirmation = 'PublishConfirmation',
  ReplaceDraftNoteConfirmation = 'ReplaceDraftNoteConfirmation',
  EndEarly = 'EndEarly',
}

export function NoteEditorHeaderAndDialogs({
  timer,
  noteFormValues,
  draftNote,
  validate,
  onResetAddNote,
  hasRequiredMedActions,
  setNoteFormValue,
}: {
  timer: TimerState;
  noteFormValues: NoteFormValues;
  draftNote: boolean | undefined;
  validate: (
    noteFormSubmissionType: NoteFormSubmissionType,
  ) => NoteFormValidationResult;
  onResetAddNote: () => void;
  hasRequiredMedActions: boolean;
  setNoteFormValue: SetNoteFormValue;
}) {
  const { patientId }: RouteParam = useParams();
  const { editingNote, setIsSubmitting } = useNoteEditorContext();
  const queryClient = useQueryClient();
  const { currentUserId: providerId } = useCurrentUser();
  const [shouldEmrSync, setShouldEmrSync] = useState(false);
  const [openedModal, setOpenedModal] = useState<Nullable<ModalType>>(null);
  const hasRequiredMedActionsErrors =
    hasRequiredMedActions &&
    requireMedActionsInEncounter(
      NoteFormSubmissionType.Publish,
      noteFormValues.encounterModuleInstances,
    );

  function resetAddNote() {
    onResetAddNote();
    invalidateNoteQueries();
  }

  const invalidateNoteQueries = async () => {
    await queryClient.invalidateQueries(cnNoteKeys.autosaved(patientId));
    await queryClient.invalidateQueries(
      autosavedNotesQueryKey.patient(patientId),
    );
    await queryClient.invalidateQueries(
      appointmentKeys.list({
        apptStartTimeFrom: formatISO(startOfDay(new Date())),
        apptStartTimeTo: formatISO(endOfDay(new Date())),
        careProviderId: providerId,
      }),
    );
    await queryClient.invalidateQueries(
      appointmentKeys.nextScheduled(patientId),
    );
    await queryClient.invalidateQueries(PATIENT_NOTES_QUERY_KEY_BASE);
    await queryClient.invalidateQueries(CARE_PLAN_QUERY_KEY_BASE);
  };

  const {
    saveDraftNote,
    saveDraftNoteImmediately,
    publish,
    publishImmediately,
  } = useSubmitHandlers(
    noteFormValues,
    timer,
    editingNote,
    patientId,
    resetAddNote,
    () => setOpenedModal(ModalType.ReplaceDraftNoteConfirmation),
    validate,
    () => setOpenedModal(ModalType.PublishConfirmation),
    setShouldEmrSync,
    hasRequiredMedActionsErrors,
    draftNote,
  );
  const canPublishToEhr = useCanPublishToEhr(patientId);
  const { data: patient } = usePatientDetails(patientId, false);
  const ehr = patient?.ehr_information?.ehr;
  const closeAndClearNoteEditor = useCloseAndClearNoteEditor();
  const publishConfirmations = useGetPublishDialogConfirmations(
    noteFormValues.timeEntry,
    editingNote?.type,
    Boolean(noteFormValues.appointmentId) ||
      Boolean(noteFormValues.noShowAppointmentId),
  );

  const intl = useIntl();
  const endFormConfig = getEndEncounterEarlyFormConfig(
    intl,
    noteFormValues.endEncounter,
  );
  const prevNoteForm = usePrevious(noteFormValues.endEncounter);

  const endForm = useFormFromConfig<EndEncounterFormFields>({
    ...endFormConfig,
    triggerReset: Boolean(
      noteFormValues.endEncounter &&
        !isEqual(prevNoteForm, noteFormValues.endEncounter),
    ),
  });
  const { encounterModuleInstances, onChangeInstance } =
    useEncounterModuleInstances();

  return (
    <>
      <NoteEditorHeader
        timer={timer}
        disablePublishToEhr={!canPublishToEhr}
        onCloseClick={() => {
          // TODO: Check if changes were made before opening confirmation dialog https://cadencerpm.atlassian.net/browse/PLAT-3100
          if (editingNote?.type === EditableNoteType.Draft) {
            setOpenedModal(ModalType.CloseConfirmation);
          } else {
            closeAndClearNoteEditor({ shouldClearNoteContent: false });
          }
        }}
        onSaveDraft={saveDraftNote}
        onPublishToCadenceClick={() =>
          publish(
            false,
            noteFormValues.appointmentId,
            noteFormValues.noShowAppointmentId,
            setIsSubmitting,
          )
        }
        onPublishToEhrClick={() =>
          publish(
            true,
            noteFormValues.appointmentId,
            noteFormValues.noShowAppointmentId,
            setIsSubmitting,
          )
        }
        onEndEarly={() => setOpenedModal(ModalType.EndEarly)}
        onDiscardEndEarly={() => {
          setNoteFormValue('endEncounter', {
            endType: null,
            endReason: null,
            endNote: null,
          });
          setPatientNoShow(false, encounterModuleInstances, onChangeInstance);
        }}
        endEncounterValues={noteFormValues?.endEncounter}
        isSaveAsDraftAvailable={editingNote?.type !== EditableNoteType.Alert}
        ehr={ehr}
      />
      {/* Dialogs */}
      <EndEarlyConfirmationDialog
        form={endForm}
        isOpen={openedModal === ModalType.EndEarly}
        onClose={() => setOpenedModal(null)}
        onSave={(endEncounter) => {
          setNoteFormValue('endEncounter', endEncounter);
          setPatientNoShow(
            endEncounter.endType === EndEncounterType.NoShow,
            encounterModuleInstances,
            onChangeInstance,
          );
        }}
        onResetAddNote={resetAddNote}
        encounterType={getEncounterType(encounterModuleInstances)}
      />
      <ReplaceDraftNoteConfirmationDialog
        isNewDraftNoteOpen={
          openedModal === ModalType.ReplaceDraftNoteConfirmation
        }
        saveNewDraftNote={saveDraftNoteImmediately}
        closeNewDraftNoteDialog={() => setOpenedModal(null)}
      />
      <CloseConfirmationDialog
        isCloseConfirmationOpen={openedModal === ModalType.CloseConfirmation}
        closeAddNote={resetAddNote}
        closeConfirmationDialog={() => setOpenedModal(null)}
      />
      <PublishConfirmationDialog
        patientId={patientId}
        timeEntry={noteFormValues.timeEntry}
        shouldEmrSync={shouldEmrSync}
        ehr={ehr}
        isOpen={openedModal === ModalType.PublishConfirmation}
        publishConfirmations={publishConfirmations}
        onCancel={() => setOpenedModal(null)}
        onConfirm={async (apptId) => {
          setOpenedModal(null);
          publishImmediately(
            shouldEmrSync,
            apptId ?? noteFormValues.appointmentId,
            noteFormValues.noShowAppointmentId,
          );
          await queryClient.invalidateQueries(
            appointmentKeys.list({
              apptStartTimeFrom: formatISO(startOfDay(new Date())),
              apptStartTimeTo: formatISO(endOfDay(new Date())),
              careProviderId: providerId,
            }),
          );
          await queryClient.invalidateQueries(
            appointmentKeys.nextScheduled(patientId),
          );
        }}
      />
    </>
  );
}
