import type { IntlShape } from 'react-intl';

import { validators } from '@/shared/common/Form/validations';
import {
  EndEncounterReason,
  EndEncounterType,
} from '@/shared/types/note.types';

import type { EndFormValues } from '../../noteFormState';

export type EndEncounterFormFields = {
  endEncounterType?: EndEncounterType;
  endEarlyReason?: EndEncounterReason | null;
  noShowReason?: EndEncounterReason | null;
  endCallNotes: string;
};

export function getEndEncounterEarlyFormConfig(
  intl: IntlShape,
  prevEndForm?: EndFormValues,
) {
  const { required, enumType, maxLengthString } = validators(intl);
  const defaultEndType = prevEndForm?.endType;
  const defaultEndReason = prevEndForm?.endReason;
  const defaultEndEarlyNote = prevEndForm?.endNote;

  return {
    fields: {
      endEncounterType: {
        defaultValue: defaultEndType ?? null,
        validation: required(
          enumType({ source: EndEncounterType, pluck: 'values' }),
        ),
      },
      endEarlyReason: {
        defaultValue: defaultEndReason ?? null,
        validation: enumType({
          source: EndEncounterReason,
          pluck: 'values',
        }).when('endEncounterType', {
          is: EndEncounterType.EndEarly,
          then: required,
        }),
      },
      noShowReason: {
        defaultValue: defaultEndReason ?? null,
        validation: enumType({
          source: EndEncounterReason,
          pluck: 'values',
        }).when('endEncounterType', {
          is: EndEncounterType.NoShow,
          then: required,
        }),
      },
      endCallNotes: {
        defaultValue: defaultEndEarlyNote ?? null,
        validation: maxLengthString({ maxLength: 255 }).nullable(),
      },
    },
  };
}
