import type { HelperDeclareSpec } from 'handlebars';
import type { IntlShape } from 'react-intl';

import type { SectionStepsState } from '@/shared/common/Wizard/state';
import type { PatientMedications } from '@/shared/generated/grpcGateway/medication.pb';
import type { VitalsEngagement } from '@/shared/hooks/useVitalsEngagement';
import type { RpmCondition } from '@/shared/types/clinicalprofile.types';
import type { FeatureFlagSet } from '@/shared/types/featureFlags.types';

import { initialVisitActionPlan } from './initialVisitActionPlan';
import { initialVisitAdditionalNotes } from './initialVisitAdditionalNotes';
import { initialVisitDeviceTips } from './initialVisitDeviceTips';
import { initialVisitEarlyEndCall } from './initialVisitEarlyEndCall';
import { initialVisitEmergencyVisits } from './initialVisitEmergencyVisits';
import { initialVisitGeneralAssessment } from './initialVisitGeneralAssessment';
import { initialVisitIntro } from './initialVisitIntro';
import { initialVisitMedicationReview } from './initialVisitMedicationReview';
import { initialVisitPatientNoShow } from './initialVisitPatientNoShow';
import { initialVisitProductSupport } from './initialVisitProductSupport';
import { initialVisitSymptoms } from './initialVisitSymptoms';
import { isInitialVisitPatientNoShow } from './isInitialVisitPatientNoShow';

interface TemplateHelpers extends HelperDeclareSpec {
  initialVisitProductSupport: (
    data: SectionStepsState,
    vitalsEngagement: VitalsEngagement,
    flags: FeatureFlagSet,
  ) => string;
  initialVisitMedicationReview: (
    data: SectionStepsState,
    noteId: number,
    meds: PatientMedications,
  ) => string;
  initialVisitActionPlan: (data: SectionStepsState, intl: IntlShape) => string;
  initialVisitSymptoms: (
    data: SectionStepsState,
    rpmConditions: RpmCondition[],
    intl: IntlShape,
  ) => string;
  initialVisitGeneralAssessment: (data: SectionStepsState) => string;
  initialVisitPatientNoShow: (
    data: SectionStepsState,
    intl: IntlShape,
  ) => string;
  initialVisitEarlyEndCall: (
    data: SectionStepsState,
    intl: IntlShape,
  ) => string;
  isInitialVisitPatientNoShow: (data: SectionStepsState) => boolean;
  initialVisitIntro: (data: SectionStepsState) => string;
  initialVisitDeviceTips: (
    data: SectionStepsState,
    flags: FeatureFlagSet,
  ) => string;
  initialVisitEmergencyVisits: (
    data: SectionStepsState,
    intl: IntlShape,
  ) => string;
  initialVisitAdditionalNotes: (data: SectionStepsState) => string;
}

/**
 * These helpers can be used from within handlebars templates to
 * format/transform data
 */
export const INIT_CN_VISIT_HELPERS: TemplateHelpers = {
  initialVisitProductSupport,
  initialVisitMedicationReview,
  initialVisitActionPlan,
  initialVisitSymptoms,
  initialVisitGeneralAssessment,
  initialVisitPatientNoShow,
  isInitialVisitPatientNoShow,
  initialVisitEarlyEndCall,
  initialVisitIntro,
  initialVisitDeviceTips,
  initialVisitEmergencyVisits,
  initialVisitAdditionalNotes,
};
