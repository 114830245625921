import useResizeObserver from '@react-hook/resize-observer';
import { useCallback, useEffect, useRef } from 'react';

import { usePatientInfoContext } from './PatientInfoContext';

export function useSetNumberOfPatientInfoColumnsFromRef() {
  const { setNumberOfPatientInfoColumns } = usePatientInfoContext();
  const divElement = useRef<Nullable<HTMLDivElement>>(null);

  const updateNumberOfColumns = useCallback(() => {
    if (divElement.current) {
      const directChildSpans = Array.from(divElement.current.children).filter(
        (child) => child.tagName === 'SPAN',
      );
      // This grid is used to display BasicPatientInfoFieldsV2
      // which can have different number of rows depending on patient condition
      // Per design, each column has three rows so dividing by three here
      const totalNumberOfColumns = Math.ceil(directChildSpans?.length / 3);
      setNumberOfPatientInfoColumns(totalNumberOfColumns);
    }
  }, [setNumberOfPatientInfoColumns]);

  useEffect(() => {
    updateNumberOfColumns();
  }, [updateNumberOfColumns]);

  useResizeObserver(divElement.current, () => {
    updateNumberOfColumns();
  });

  return divElement;
}
